.news-list{
  padding-top: 80px;
  padding-bottom: 120px;
  @include media-breakpoint-down(lg){
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .col-item{
    margin-bottom: $grid-gutter-width;
  }
  .pagination{
    margin-top: 64px;
    @include media-breakpoint-down(md){
      margin-top:10px;
    }
  }
}