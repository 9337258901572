.properties-list{
  margin-bottom: 120px;
  @include media-breakpoint-down(lg){
    margin-bottom: 30px;
  }
  .col-item{
    margin-bottom:40px;
  }
  .pagination{
    margin-top: 34px;
  }
}