.property-details{
  margin-top: 40px;
  margin-bottom: 120px;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
    margin-top: 30px;
  }
  .row{
    align-items: center;
  }
  .col-left{
    @include media-breakpoint-down(lg){
      margin-bottom: 30px;
    }
  }
  &__img{
    position: relative;
    cursor: pointer;
    img{
      width: 100%;
      height: auto;
    }
    &__cta{
      position: absolute;
      left:16px;
      bottom:16px;
      color:$blue-dark;
      text-transform: uppercase;
      background-color: $white;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 23px;
      padding: 9px;
      svg{
        margin-right: 8px;
      }
    }
  }
  &__right{
    &__misc{
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $blue;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 17px;
      .sep{
        width: 1px;
        height: 21px;
        margin: 0 28px;
      }
      &__ref{
        opacity: 0.5;
      }
    }
    &__title{
      @extend .title-2;
      font-size: 31px;
      color:$blue;
      margin-bottom: 8px;
    }
    &__price{
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      &__number{
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: $blue;
      }
      &__details{
        color: $blue;
        opacity: 0.5;
        font-size: 14px;
        line-height: 14px;
        margin-left: 9px;
      }
    }
    &__surface{
      display: flex;
      width: 100%;
      border-top: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;
      padding-top: 22px;
      padding-bottom: 28px;
      margin-bottom: 22px;
      @include media-breakpoint-down(xs){
        justify-content: space-around;
      }
      &__left{
        margin-right: 120px;
        @include media-breakpoint-down(xs){
          margin-right: 0px;
        }
      }
      &__right{
      }
      &__title{
        text-transform: uppercase;
        color:$blue;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 16px;
        opacity: 0.5;
      }
      &__num{
        color: $blue;
      }
    }
    &__desc{
      margin-bottom: 21px;
      &__title{
        text-transform: uppercase;
        color:$blue;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 16px;
        opacity: 0.5;
      }
      &__text{
        color:$blue-dark;
        font-size: 16px;
        line-height: 24px;
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    &__tools{
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm){
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      a{
        margin-right: 73px;
        @include media-breakpoint-down(sm){
          margin-right: 0;
          margin-bottom: 15px;
        }
        &:last-child{
          margin-right: 0;
          @include media-breakpoint-down(sm){
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.property-details-exception{
  margin-bottom: 120px;
  @include media-breakpoint-down(lg){
    margin-bottom: 60px;
  }
  &__banner{
    background-size: cover;
    background-position: center;
    height: 558px;
    position: relative;
    @include media-breakpoint-down(sm){
      height: 450px;
    }
    .container{
      position: absolute;
      top:0;
      left:0;
      bottom: 0;
      right: 0;

    }
    &__tools{
      position: absolute;
      top:20px;
      left:$grid-gutter-width / 2;
      .breadcrumb{
        margin-bottom: 30px;
        @include media-breakpoint-down(sm){
          margin-bottom: 10px;
        }
      }
      &__back{
        color: $white;
        display: inline-flex;
        margin-top: 20px;
        margin-bottom: 50px;
        align-items: center;
        svg{
          margin-right: 25px;
        }
        &:hover{
          color: $white;
          span{
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__inner{ 
    background-color: $white;
    margin-top: -180px;
    position: relative;
    padding-top: 38px;
    min-height: 190px;
    &__cta{
      cursor: pointer;
      position: absolute;
      right:0px;
      top:-12px;
      transform: translateY(-100%);
      color:$blue-dark;
      text-transform: uppercase;
      background-color: $white;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 23px;
      padding: 9px;
      svg{
        margin-right: 8px;
      }
    }
    &__top{
      text-align: center;
      @include media-breakpoint-down(md){
        padding: 0 12px;
      }
      &__title{
        color: $blue;
        @extend .title-2;
        margin-bottom: 8px;
      }
      &__number{
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color:$blue;
        margin-bottom: 13px;
      }
      &__introduction{
        color: $blue-dark;
        opacity: 0.8;
        font-size: 16px;
        line-height: 24px;
        p{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    &__bottom{
      display: flex;
      align-items: flex-start;
      margin-top: 86px;
      flex-wrap: wrap;
      &__left{
        width: 75%;
        margin-right: 15px;
        @include media-breakpoint-down(md){
          width: 100%;
          margin-right: 0;
          order: 1;
        }
        &__title{
          text-transform: uppercase;
          color:$blue;
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 12px;
          opacity: 0.5;
        }
        &__content{
          color: $blue-dark;
          opacity: 0.8;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 49px;
          max-width: 893px;
        }
        &__misc{
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          a{
            margin-bottom: 32px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      &__right{
        padding-left: 56px;
        border-left: 1px solid $light-grey;
        @include media-breakpoint-down(lg){
          padding-left: 15px;
        }
        @include media-breakpoint-down(md){
          width: 100%;
          margin-right: 0;
          padding-left: 0;
          border-left: 0;
          order: 0;
          margin-bottom: $grid-gutter-width;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }
        &__item{
          margin-bottom: 31px;
          @include media-breakpoint-down(sm){
            width: 100%;
          }
          &__title{
            text-transform: uppercase;
            color:$blue;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 16px;
            opacity: 0.5;
          }
          &__text{
            margin-bottom: 0;
            color: $blue;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.property-details-gallery{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: $white;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(-15px);
  transition: all 1000ms $easeSmooth;
  &.is-shown{
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0);
  }
  &__close{
    position: absolute;
    width: 30px;
    height: 30px;
    color: $blue;
    top:15px;
    right: 15px;
    cursor: pointer;
  }
  &__inner{
    flex-flow: column;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 800px;
    height: calc(100vh - 20px);
    &__slide{
      text-align: center;
      img{
        max-width: 100%;
        height: auto;
        margin-bottom: 15px;
      }
      &__legend{
        color: $blue;
        font-size: 16px;
      }
    }
    &__tools{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      .swiper-pagination-bullets{
        margin: 0 20px;
        &.color-blue-light{
          .swiper-pagination-bullet{
            background-color: $blue-light;
          }
        }
        .swiper-pagination-bullet{
          width: 16px;
          height: 16px;
          margin: 0 8px;
          background-color: $red;
        }
      }
    }
  }
}