.bloc-contact{
  background-size: cover;
  background-position: center;
  padding-top: 153px;
  &__inner{
    background-color: $white;
    padding: 47px 58px 37px 58px;
    width: 100%;
    max-width: 686px;
  }
  &__title{
    @extend .title-2;
    color:$blue;
    text-transform: uppercase;
    margin-bottom: 31px;
  }
  &__text{
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $blue-dark;
    margin-bottom: 35px;
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }

}