@charset "UTF-8";
/* Bootstrap lightweight */
/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  text-align: left; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

p {
  margin-top: 0; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

th {
  text-align: inherit; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1140px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1320px) {
    .container {
      max-width: 1320px; } }

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1140px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1320px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

/* Function */
/*
|---------------------------------------------------------------
| Functions
|---------------------------------------------------------------
| Functions used to add classes to generation
|
|
*/
/*
|
| Add Buttons
|-----------------------
|
*/
/*
|
| Add Blocks
|-----------------------
|
*/
/*
|
| Add Links
|-----------------------
|
*/
/* variables */
/*
|---------------------------------------------------------------
| Variables
|---------------------------------------------------------------
| Import variables
|
|
*/
/*
|---------------------------------------------------------------
| SPACES
|---------------------------------------------------------------
| Margins and paddings
|
|
*/
/*
|--------------
| Space sizes
|--------------
| Variables used to generate magins & paddings classes (including responsive)
| - Normal classes: .{property}-{size} / .mb-md / margin-bottom: $md  
| - Responsive classes: .{screen}-{up/down/only}-{property}-{size} / .md-down-mb-md /  (max-width: 992px) { margin-bottom: $md }
|
*/
/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/
/*
|
| Colors declaration
|----------------------
|
*/
/*
|
| Assign colors
|---------------
|
*/
/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
/*
|---------------------------------------------------------------
| TEXTS
|---------------------------------------------------------------
| Set all text properties
|
|
*/
/*
|
|
| FONT-SIZES
|
|
|----------------------------------------------
| Font sizes settings (for each screen sizes)
|----------------------------------------------
| Variables used to generate font classes (including responsive)
| - Generated classes: .font-{size}
| - Exemple: .font-md
|
*/
/*
|
|
| TITLE-SIZES
|
|
|-----------------------------------------------
| Title sizes settings (for each screen sizes)
|-----------------------------------------------
| Variables used to generate title classes (including responsive)
| - Generated classes: .title-{size}
| - Exemple: .title-md
|
*/
/*
|
| FONT-FAMILIES
|----------------
|
*/
/*
|-------------------------------
| Font family classes creation
|-------------------------------
| How to use: add lines to create more "font family classes" 
| - Generated classes: .font-{family}
| - Exemple: .font-custom
|
*/
/*
|-----------------
| LETTER-SPACING
|-----------------
| Variables used to generate letter-spacing classes
| - Generated classes: .ls-{size}
| - Exemple: .ls-md
|
*/
/*
|
| LINE-HEIGHT
|--------------
|
*/
/*
|
| Default font settings
|------------------------
|
*/
/*
|
| Default titles settings
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| LINKS
|---------------------------------------------------------------
| Generate and assign links colors
|
|
*/
/*
|
| Links default
|----------------
|
*/
/*
|------------------------
| Links colors creation
|------------------------
| - How to use: Add --> $links-colors: map-set-links($map, $index, $color, $color-hover)
| - Generated classes: .link-{color} & .links-{color} (on parent)
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Buttons base default
|----------------------
|
*/
/*
|
| Buttons size setting
|-----------------------
|
*/
/*
|-------------------
| Buttons creation
|-------------------
| - How to use: $button-colors: map-set-buttons($map, $index, $background-color, $border-color, $text-color)
| - Generated classes: .btn-{size}, .btn-{color}, .btn-icon-{direction}.
|
*/
/*
|---------------------------------------------------------------
| SECTIONS
|---------------------------------------------------------------
| Generate responsive sections
|
|
*/
/*
|-------------------
| Sections setting
|-------------------
| - Generated class: .section
|
*/
/*
|---------------------------------------------------------------
| BUTTONS
|---------------------------------------------------------------
| Generate and set buttons
|
|
*/
/*
|
| Bg img default
|----------------------
|
*/
/*
|-------------------
| Bg img creation
|-------------------
| - Generated classes: .bg-img-{size}
|
*/
/* Components */
/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/
/*
|
| Text Transform
|----------------------------
|
*/
.tt-u {
  text-transform: uppercase !important; }

.tt-l {
  text-transform: lowercase !important; }

.tt-c {
  text-transform: capitalize !important; }

/*
|
| Underline
|------------
|
*/
.td-u {
  text-decoration: underline !important; }

.td-n {
  text-decoration: none !important; }

/*
|
| Font styles
|--------------
|
*/
.fs-i {
  font-style: italic !important; }

.fs-o {
  font-style: oblique !important; }

.fs-n {
  font-style: normal !important; }

/*
|
| Background cover
|-------------------
|
*/
.bg-cover, .bg-cover-top, .bg-cover-bottom {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important; }

.bg-cover-top {
  background-position: center top !important; }

.bg-cover-bottom {
  background-position: center bottom !important; }

/*
|
| Overlays
|-------------
|
*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6); }
  .overlay + * {
    position: relative;
    z-index: 2; }

/*
|
| Overflows
|-------------
|
*/
.o-v {
  overflow: visible !important; }

.ox-v {
  overflow-x: visible !important; }

.oy-v {
  overflow-y: visible !important; }

.o-h {
  overflow: hidden !important; }

.ox-h {
  overflow-x: hidden !important; }

.oy-h {
  overflow-y: hidden !important; }

/*
|
| Positions
|-------------
|
*/
.p-r {
  position: relative !important; }

.p-a {
  position: absolute !important; }

.p-f {
  position: fixed !important; }

.p-s {
  position: static !important; }

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start {
  align-items: flex-start; }

.ai-end {
  align-items: flex-end; }

.ai-center {
  align-items: center; }

.ai-baseline {
  align-items: baseline; }

.ai-stretch {
  align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.jc-center {
  justify-content: center; }

.jc-between {
  justify-content: space-between; }

.jc-around {
  justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit {
  white-space: inherit; }

.ws-normal {
  white-space: normal; }

.ws-nowrap {
  white-space: nowrap; }

.ws-pre {
  white-space: pre; }

.ws-pre-wrap {
  white-space: pre-wrap; }

.ws-pre-line {
  white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit {
  pointer-events: inherit; }

.pe-none {
  pointer-events: none; }

.pe-auto {
  pointer-events: auto; }

/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
  transform: none !important; }

/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*
|
| Clear floats
|---------------
|
*/
.clear {
  clear: both; }

/*
|
| img full
|-----------
|
*/
.img-full {
  display: inline-block;
  max-width: inherit;
  width: 100%; }

/*
|
| W-100 H-100
|-----------
|
*/
.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

/* Core */
/*
|---------------------------------------------------------------
| MAPS
|---------------------------------------------------------------
| Sass maps used in classes generation
|
|
*/
/*
|
| Font Sizes Map
|---------------------
|
*/
/*
|
| Title Sizes Map
|---------------------
|
*/
/*
|
| Buttons Sizes Map
|---------------------
|
*/
/*
|
| Margins & Paddings Maps
|--------------------------
|
*/
/*
|-------------------------------
| Font weight classes creation
|-------------------------------
| How to use: add lines to create more "font weight classes"
| - Generated classes: .font-{weight}
| - Exemple: .font-regular
|
*/
/*
|
| Font-weights
|---------------
|
*/
/*
|
| Text Align Map
|--------------------------
|
*/
/*
|-------------------------------
| Line-height classes creation
|-------------------------------
| How to use: add lines to create more "line-height classes"
| - Generated classes: .lh-{weight}
| - Exemple: .lh-sm
|
*/
/*
|
| Letter Spacing Map
|--------------------------
|
*/
/*
|
| Display Map
|--------------------------
|
*/
/*
|
| Section Map
|--------------------------
|
*/
/*
|---------------------------------------------------------------
| MIXINS
|---------------------------------------------------------------
| Mixins called from generator to generate classes
|
|
*/
/*
|
| Commonly used mixins
|-----------------------
|
*/
/*
|
| Margins & Paddings
|-----------------------
|
*/
/*
|
| Responsive Margins & Paddings
|--------------------------------
|
*/
/*
|
| Font-sizes
|-----------------------
|
*/
/*
|
| Titles
|-----------------------
|
*/
/*
|
| Links
|-----------------------
|
*/
/*
|
| Buttons
|-----------------------
|
*/
/*
|
| Hidden classes
|-----------------------
|
*/
/*
|
| Display
|-----------------------
|
*/
/*
|
| Text Align
|-----------------------
|
*/
/*
|
| Sections
|-----------------------
|
*/
/*
|
| Bg imgs
|-------------
|
*/
/*
|---------------------------------------------------------------
| CLASS GENERATOR
|---------------------------------------------------------------
| Mixins calls to generate classes
|
|
*/
/*------- DISPLAY -------*/
@media (max-width: 1319.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 1139.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 1140px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1320px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1319.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 1139.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 1140px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1320px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1319.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 1139.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 1140px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1320px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1319.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 1139.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 1140px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1320px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1319.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 1139.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 1140px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1320px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1319.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 1139.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 1140px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1320px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- FONT-SIZES -------*/
.fs-xs {
  font-size: 12px !important; }
  @media (max-width: 1319.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 1139.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 767.98px) {
    .fs-xs {
      font-size: 12px !important; } }
  @media (max-width: 575.98px) {
    .fs-xs {
      font-size: 12px !important; } }

.fs-sm {
  font-size: 16px !important; }
  @media (max-width: 1319.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 1139.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 767.98px) {
    .fs-sm {
      font-size: 16px !important; } }
  @media (max-width: 575.98px) {
    .fs-sm {
      font-size: 16px !important; } }

.fs-md {
  font-size: 18px !important; }
  @media (max-width: 1319.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 1139.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 767.98px) {
    .fs-md {
      font-size: 18px !important; } }
  @media (max-width: 575.98px) {
    .fs-md {
      font-size: 18px !important; } }

.fs-lg {
  font-size: 28px !important; }
  @media (max-width: 1319.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 1139.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 767.98px) {
    .fs-lg {
      font-size: 28px !important; } }
  @media (max-width: 575.98px) {
    .fs-lg {
      font-size: 28px !important; } }

.fs-xl {
  font-size: 36px !important; }
  @media (max-width: 1319.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 1139.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xl {
      font-size: 36px !important; } }

.fs-xxl {
  font-size: 36px !important; }
  @media (max-width: 1319.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 1139.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .fs-xxl {
      font-size: 36px !important; } }
  @media (max-width: 575.98px) {
    .fs-xxl {
      font-size: 36px !important; } }

/*------- TITLES -------*/
.title-xs, .cms h6 {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 16px; }
  @media (max-width: 1319.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 1139.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 767.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }
  @media (max-width: 575.98px) {
    .title-xs, .cms h6 {
      font-size: 16px; } }

.title-sm, .cms h5 {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 18px; }
  @media (max-width: 1319.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 1139.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 767.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }
  @media (max-width: 575.98px) {
    .title-sm, .cms h5 {
      font-size: 18px; } }

.title-md, .cms h4 {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 24px; }
  @media (max-width: 1319.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 1139.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 767.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }
  @media (max-width: 575.98px) {
    .title-md, .cms h4 {
      font-size: 24px; } }

.title-lg, .cms h3 {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 30px; }
  @media (max-width: 1319.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 1139.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 767.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }
  @media (max-width: 575.98px) {
    .title-lg, .cms h3 {
      font-size: 30px; } }

.title-xl, .cms h2 {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 50px; }
  @media (max-width: 1319.98px) {
    .title-xl, .cms h2 {
      font-size: 50px; } }
  @media (max-width: 1139.98px) {
    .title-xl, .cms h2 {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xl, .cms h2 {
      font-size: 42px; } }
  @media (max-width: 575.98px) {
    .title-xl, .cms h2 {
      font-size: 32px; } }

.title-xxl, .cms h1, #page-404 .section-content .item-title {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  font-size: 62px; }
  @media (max-width: 1319.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 50px; } }
  @media (max-width: 1139.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 42px; } }
  @media (max-width: 767.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 36px; } }
  @media (max-width: 575.98px) {
    .title-xxl, .cms h1, #page-404 .section-content .item-title {
      font-size: 36px; } }

/*------- FONT-FAMILY -------*/
.ff-default {
  font-family: "Poppins", sans-serif ; }

.ff-custom {
  font-family: "Poppins", sans-serif ; }

.ff-extra {
  font-family: "Poppins", sans-serif ; }

/*------- FONT-WEIGHTS -------*/
.fw-100 {
  font-weight: 100 ; }

.fw-200 {
  font-weight: 200 ; }

.fw-300 {
  font-weight: 300 ; }

.fw-400 {
  font-weight: 400 ; }

.fw-500 {
  font-weight: 500 ; }

.fw-600 {
  font-weight: 600 ; }

.fw-700 {
  font-weight: 700 ; }

.fw-800 {
  font-weight: 800 ; }

.fw-900 {
  font-weight: 900 ; }

/*------- LINE-HEIGHTS -------*/
.lh-xs {
  line-height: 1 ; }

.lh-sm {
  line-height: 1.2 ; }

.lh-md {
  line-height: 1.5 ; }

.lh-lg {
  line-height: 1.6 ; }

.lh-xl {
  line-height: 1.8 ; }

.lh-xxl {
  line-height: 2 ; }

/*------- COLORS -------*/
.c-black {
  color: #000000 ; }

.c-very-dark-grey {
  color: #141414 ; }

.c-dark-grey {
  color: #424242 ; }

.c-grey {
  color: #979797 ; }

.c-light-grey {
  color: #ECE9E9 ; }

.c-very-light-grey {
  color: #fafafa ; }

.c-white {
  color: #FFFFFF ; }

.c-red {
  color: #FF3330 ; }

.c-blue {
  color: #053F93 ; }

.c-blue-dark {
  color: #002252 ; }

.c-blue-light {
  color: #41B5EF ; }

/*------- LINKS -------*/
.link-white {
  color: #FFFFFF; }
  .link-white:hover {
    color: #ECE9E9; }

.links-white a {
  color: #FFFFFF; }
  .links-white a:hover {
    color: #ECE9E9; }

.link-black {
  color: #000000; }
  .link-black:hover {
    color: #979797; }

.links-black a {
  color: #000000; }
  .links-black a:hover {
    color: #979797; }

/*------- BUTTONS -------*/
.btn, .btn-black, .btn-white, .btn-xs, .btn-sm, .btn-md, .btn-lg {
  position: relative;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  background: #FFFFFF;
  color: #141414;
  border: 1px solid #ECE9E9;
  border-radius: 0;
  transition: all 0.3s;
  box-shadow: none !important;
  white-space: inherit;
  cursor: pointer; }
  .btn:hover, .btn-black:hover, .btn-white:hover, .btn-xs:hover, .btn-sm:hover, .btn-md:hover, .btn-lg:hover {
    color: #141414; }
  .btn .icon, .btn-black .icon, .btn-white .icon, .btn-xs .icon, .btn-sm .icon, .btn-md .icon, .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn .icon svg, .btn-black .icon svg, .btn-white .icon svg, .btn-xs .icon svg, .btn-sm .icon svg, .btn-md .icon svg, .btn-lg .icon svg, .btn .icon img, .btn-black .icon img, .btn-white .icon img, .btn-xs .icon img, .btn-sm .icon img, .btn-md .icon img, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn.btn-icon-right, .btn-icon-right.btn-black, .btn-icon-right.btn-white, .btn-icon-right.btn-xs, .btn-icon-right.btn-sm, .btn-icon-right.btn-md, .btn-icon-right.btn-lg {
    text-align: right;
    padding-right: 60px; }
    .btn.btn-icon-right .icon, .btn-icon-right.btn-black .icon, .btn-icon-right.btn-white .icon, .btn-icon-right.btn-xs .icon, .btn-icon-right.btn-sm .icon, .btn-icon-right.btn-md .icon, .btn-icon-right.btn-lg .icon {
      right: 20px; }
  .btn.btn-icon-left, .btn-icon-left.btn-black, .btn-icon-left.btn-white, .btn-icon-left.btn-xs, .btn-icon-left.btn-sm, .btn-icon-left.btn-md, .btn-icon-left.btn-lg {
    text-align: left;
    padding-left: 60px; }
    .btn.btn-icon-left .icon, .btn-icon-left.btn-black .icon, .btn-icon-left.btn-white .icon, .btn-icon-left.btn-xs .icon, .btn-icon-left.btn-sm .icon, .btn-icon-left.btn-md .icon, .btn-icon-left.btn-lg .icon {
      left: 20px; }

.btn-black {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF; }
  .btn-black:hover {
    color: #FFFFFF; }
  .btn-black .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-black .icon svg, .btn-black .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-black.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-black.btn-icon-right .icon {
      right: 20px; }
  .btn-black.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-black.btn-icon-left .icon {
      left: 20px; }

.btn-white {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: #000000; }
  .btn-white:hover {
    color: #000000; }
  .btn-white .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-white .icon svg, .btn-white .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-white.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-white.btn-icon-right .icon {
      right: 20px; }
  .btn-white.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-white.btn-icon-left .icon {
      left: 20px; }

.btn-xs {
  padding: 2px 5px;
  font-size: 10px; }
  .btn-xs .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 10px; }
    .btn-xs .icon svg, .btn-xs .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-xs.btn-icon-right {
    text-align: right;
    padding-right: 25px; }
    .btn-xs.btn-icon-right .icon {
      right: 5px; }
  .btn-xs.btn-icon-left {
    text-align: left;
    padding-left: 25px; }
    .btn-xs.btn-icon-left .icon {
      left: 5px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 14px; }
  .btn-sm .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 20px; }
    .btn-sm .icon svg, .btn-sm .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-sm.btn-icon-right {
    text-align: right;
    padding-right: 40px; }
    .btn-sm.btn-icon-right .icon {
      right: 10px; }
  .btn-sm.btn-icon-left {
    text-align: left;
    padding-left: 40px; }
    .btn-sm.btn-icon-left .icon {
      left: 10px; }

.btn-md {
  padding: 10px 20px;
  font-size: 16px; }
  .btn-md .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 30px; }
    .btn-md .icon svg, .btn-md .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-md.btn-icon-right {
    text-align: right;
    padding-right: 60px; }
    .btn-md.btn-icon-right .icon {
      right: 20px; }
  .btn-md.btn-icon-left {
    text-align: left;
    padding-left: 60px; }
    .btn-md.btn-icon-left .icon {
      left: 20px; }

.btn-lg {
  padding: 20px 30px;
  font-size: 20px; }
  .btn-lg .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.4s;
    width: 50px; }
    .btn-lg .icon svg, .btn-lg .icon img {
      width: 100%;
      max-height: 100%; }
  .btn-lg.btn-icon-right {
    text-align: right;
    padding-right: 90px; }
    .btn-lg.btn-icon-right .icon {
      right: 30px; }
  .btn-lg.btn-icon-left {
    text-align: left;
    padding-left: 90px; }
    .btn-lg.btn-icon-left .icon {
      left: 30px; }

/*------- BACKGROUND-COLORS -------*/
.bg-black {
  background-color: #000000 ; }

.bg-very-dark-grey {
  background-color: #141414 ; }

.bg-dark-grey {
  background-color: #424242 ; }

.bg-grey {
  background-color: #979797 ; }

.bg-light-grey {
  background-color: #ECE9E9 ; }

.bg-very-light-grey {
  background-color: #fafafa ; }

.bg-white {
  background-color: #FFFFFF ; }

.bg-red {
  background-color: #FF3330 ; }

.bg-blue {
  background-color: #053F93 ; }

.bg-blue-dark {
  background-color: #002252 ; }

.bg-blue-light {
  background-color: #41B5EF ; }

/*------- TEXT-ALIGN -------*/
.ta-r {
  text-align: right ; }

.ta-l {
  text-align: left ; }

.ta-c {
  text-align: center ; }

.ta-j {
  text-align: justify ; }

@media (max-width: 1319.98px) {
  .lg-down-ta-r {
    text-align: right; } }

@media (max-width: 1139.98px) {
  .md-down-ta-r {
    text-align: right; } }

@media (max-width: 767.98px) {
  .sm-down-ta-r {
    text-align: right; } }

@media (max-width: 575.98px) {
  .xs-down-ta-r {
    text-align: right; } }

.xs-up-ta-r {
  text-align: right; }

@media (min-width: 576px) {
  .sm-up-ta-r {
    text-align: right; } }

@media (min-width: 768px) {
  .md-up-ta-r {
    text-align: right; } }

@media (min-width: 1140px) {
  .lg-up-ta-r {
    text-align: right; } }

@media (min-width: 1320px) {
  .xl-up-ta-r {
    text-align: right; } }

@media (max-width: 1319.98px) {
  .lg-down-ta-l {
    text-align: left; } }

@media (max-width: 1139.98px) {
  .md-down-ta-l {
    text-align: left; } }

@media (max-width: 767.98px) {
  .sm-down-ta-l {
    text-align: left; } }

@media (max-width: 575.98px) {
  .xs-down-ta-l {
    text-align: left; } }

.xs-up-ta-l {
  text-align: left; }

@media (min-width: 576px) {
  .sm-up-ta-l {
    text-align: left; } }

@media (min-width: 768px) {
  .md-up-ta-l {
    text-align: left; } }

@media (min-width: 1140px) {
  .lg-up-ta-l {
    text-align: left; } }

@media (min-width: 1320px) {
  .xl-up-ta-l {
    text-align: left; } }

@media (max-width: 1319.98px) {
  .lg-down-ta-c {
    text-align: center; } }

@media (max-width: 1139.98px) {
  .md-down-ta-c {
    text-align: center; } }

@media (max-width: 767.98px) {
  .sm-down-ta-c {
    text-align: center; } }

@media (max-width: 575.98px) {
  .xs-down-ta-c {
    text-align: center; } }

.xs-up-ta-c {
  text-align: center; }

@media (min-width: 576px) {
  .sm-up-ta-c {
    text-align: center; } }

@media (min-width: 768px) {
  .md-up-ta-c {
    text-align: center; } }

@media (min-width: 1140px) {
  .lg-up-ta-c {
    text-align: center; } }

@media (min-width: 1320px) {
  .xl-up-ta-c {
    text-align: center; } }

@media (max-width: 1319.98px) {
  .lg-down-ta-j {
    text-align: justify; } }

@media (max-width: 1139.98px) {
  .md-down-ta-j {
    text-align: justify; } }

@media (max-width: 767.98px) {
  .sm-down-ta-j {
    text-align: justify; } }

@media (max-width: 575.98px) {
  .xs-down-ta-j {
    text-align: justify; } }

.xs-up-ta-j {
  text-align: justify; }

@media (min-width: 576px) {
  .sm-up-ta-j {
    text-align: justify; } }

@media (min-width: 768px) {
  .md-up-ta-j {
    text-align: justify; } }

@media (min-width: 1140px) {
  .lg-up-ta-j {
    text-align: justify; } }

@media (min-width: 1320px) {
  .xl-up-ta-j {
    text-align: justify; } }

/*------- LETTER SPACING -------*/
.ls-xs {
  letter-spacing: 1px ; }

.ls-sm {
  letter-spacing: 2px ; }

.ls-md {
  letter-spacing: 3px ; }

.ls-lg {
  letter-spacing: 4px ; }

.ls-xl {
  letter-spacing: 5px ; }

.ls-xxl {
  letter-spacing: 6px ; }

/*------- DISPLAYS -------*/
.d-none {
  display: none ; }

.d-i {
  display: inline ; }

.d-b {
  display: block ; }

.d-ib {
  display: inline-block ; }

.d-f {
  display: flex ; }

.d-if {
  display: inline-flex ; }

@media (max-width: 1319.98px) {
  .lg-down-d-none {
    display: none; } }

@media (max-width: 1139.98px) {
  .md-down-d-none {
    display: none; } }

@media (max-width: 767.98px) {
  .sm-down-d-none {
    display: none; } }

@media (max-width: 575.98px) {
  .xs-down-d-none {
    display: none; } }

.xs-up-d-none {
  display: none; }

@media (min-width: 576px) {
  .sm-up-d-none {
    display: none; } }

@media (min-width: 768px) {
  .md-up-d-none {
    display: none; } }

@media (min-width: 1140px) {
  .lg-up-d-none {
    display: none; } }

@media (min-width: 1320px) {
  .xl-up-d-none {
    display: none; } }

@media (max-width: 1319.98px) {
  .lg-down-d-i {
    display: inline; } }

@media (max-width: 1139.98px) {
  .md-down-d-i {
    display: inline; } }

@media (max-width: 767.98px) {
  .sm-down-d-i {
    display: inline; } }

@media (max-width: 575.98px) {
  .xs-down-d-i {
    display: inline; } }

.xs-up-d-i {
  display: inline; }

@media (min-width: 576px) {
  .sm-up-d-i {
    display: inline; } }

@media (min-width: 768px) {
  .md-up-d-i {
    display: inline; } }

@media (min-width: 1140px) {
  .lg-up-d-i {
    display: inline; } }

@media (min-width: 1320px) {
  .xl-up-d-i {
    display: inline; } }

@media (max-width: 1319.98px) {
  .lg-down-d-b {
    display: block; } }

@media (max-width: 1139.98px) {
  .md-down-d-b {
    display: block; } }

@media (max-width: 767.98px) {
  .sm-down-d-b {
    display: block; } }

@media (max-width: 575.98px) {
  .xs-down-d-b {
    display: block; } }

.xs-up-d-b {
  display: block; }

@media (min-width: 576px) {
  .sm-up-d-b {
    display: block; } }

@media (min-width: 768px) {
  .md-up-d-b {
    display: block; } }

@media (min-width: 1140px) {
  .lg-up-d-b {
    display: block; } }

@media (min-width: 1320px) {
  .xl-up-d-b {
    display: block; } }

@media (max-width: 1319.98px) {
  .lg-down-d-ib {
    display: inline-block; } }

@media (max-width: 1139.98px) {
  .md-down-d-ib {
    display: inline-block; } }

@media (max-width: 767.98px) {
  .sm-down-d-ib {
    display: inline-block; } }

@media (max-width: 575.98px) {
  .xs-down-d-ib {
    display: inline-block; } }

.xs-up-d-ib {
  display: inline-block; }

@media (min-width: 576px) {
  .sm-up-d-ib {
    display: inline-block; } }

@media (min-width: 768px) {
  .md-up-d-ib {
    display: inline-block; } }

@media (min-width: 1140px) {
  .lg-up-d-ib {
    display: inline-block; } }

@media (min-width: 1320px) {
  .xl-up-d-ib {
    display: inline-block; } }

@media (max-width: 1319.98px) {
  .lg-down-d-f {
    display: flex; } }

@media (max-width: 1139.98px) {
  .md-down-d-f {
    display: flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-f {
    display: flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-f {
    display: flex; } }

.xs-up-d-f {
  display: flex; }

@media (min-width: 576px) {
  .sm-up-d-f {
    display: flex; } }

@media (min-width: 768px) {
  .md-up-d-f {
    display: flex; } }

@media (min-width: 1140px) {
  .lg-up-d-f {
    display: flex; } }

@media (min-width: 1320px) {
  .xl-up-d-f {
    display: flex; } }

@media (max-width: 1319.98px) {
  .lg-down-d-if {
    display: inline-flex; } }

@media (max-width: 1139.98px) {
  .md-down-d-if {
    display: inline-flex; } }

@media (max-width: 767.98px) {
  .sm-down-d-if {
    display: inline-flex; } }

@media (max-width: 575.98px) {
  .xs-down-d-if {
    display: inline-flex; } }

.xs-up-d-if {
  display: inline-flex; }

@media (min-width: 576px) {
  .sm-up-d-if {
    display: inline-flex; } }

@media (min-width: 768px) {
  .md-up-d-if {
    display: inline-flex; } }

@media (min-width: 1140px) {
  .lg-up-d-if {
    display: inline-flex; } }

@media (min-width: 1320px) {
  .xl-up-d-if {
    display: inline-flex; } }

/*------- SECTIONS -------*/
.section {
  position: relative;
  padding-top: 100px !important;
  padding-bottom: 100px !important; }
  @media (max-width: 1319.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 1139.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 767.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }
  @media (max-width: 575.98px) {
    .section {
      padding-top: 100px !important;
      padding-bottom: 100px !important; } }

/*------- BG IMG -------*/
.bg-img, .bg-img-square, .bg-img-almost-square, .bg-img-landscape, .bg-img-landscape-sm, .bg-img-portrait {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000; }
  .bg-img:after, .bg-img-square:after, .bg-img-almost-square:after, .bg-img-landscape:after, .bg-img-landscape-sm:after, .bg-img-portrait:after {
    content: '';
    display: block; }

.bg-img-square:after {
  padding-bottom: 100%; }

.bg-img-almost-square:after {
  padding-bottom: 85%; }

.bg-img-landscape:after {
  padding-bottom: 70%; }

.bg-img-landscape-sm:after {
  padding-bottom: 60%; }

.bg-img-portrait:after {
  padding-bottom: 130%; }

/*------- MARGINS & PADDINGS -------*/
.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs {
  margin: 5px !important; }

.mt-xs {
  margin-top: 5px !important; }

.mb-xs {
  margin-bottom: 5px !important; }

.my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs {
  margin-left: 5px !important; }

.mr-xs {
  margin-right: 5px !important; }

.mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs {
  padding: 5px !important; }

.pt-xs {
  padding-top: 5px !important; }

.pb-xs {
  padding-bottom: 5px !important; }

.py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs {
  padding-left: 5px !important; }

.pr-xs {
  padding-right: 5px !important; }

.px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm {
  margin: 15px !important; }

.mt-sm {
  margin-top: 15px !important; }

.mb-sm {
  margin-bottom: 15px !important; }

.my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm {
  margin-left: 15px !important; }

.mr-sm {
  margin-right: 15px !important; }

.mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm {
  padding: 15px !important; }

.pt-sm {
  padding-top: 15px !important; }

.pb-sm {
  padding-bottom: 15px !important; }

.py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm {
  padding-left: 15px !important; }

.pr-sm {
  padding-right: 15px !important; }

.px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md {
  margin: 30px !important; }

.mt-md {
  margin-top: 30px !important; }

.mb-md {
  margin-bottom: 30px !important; }

.my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md {
  margin-left: 30px !important; }

.mr-md {
  margin-right: 30px !important; }

.mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md {
  padding: 30px !important; }

.pt-md {
  padding-top: 30px !important; }

.pb-md {
  padding-bottom: 30px !important; }

.py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md {
  padding-left: 30px !important; }

.pr-md {
  padding-right: 30px !important; }

.px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg {
  margin: 50px !important; }

.mt-lg {
  margin-top: 50px !important; }

.mb-lg {
  margin-bottom: 50px !important; }

.my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg {
  margin-left: 50px !important; }

.mr-lg {
  margin-right: 50px !important; }

.mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg {
  padding: 50px !important; }

.pt-lg {
  padding-top: 50px !important; }

.pb-lg {
  padding-bottom: 50px !important; }

.py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg {
  padding-left: 50px !important; }

.pr-lg {
  padding-right: 50px !important; }

.px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl {
  margin: 70px !important; }

.mt-xl {
  margin-top: 70px !important; }

.mb-xl {
  margin-bottom: 70px !important; }

.my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl {
  margin-left: 70px !important; }

.mr-xl {
  margin-right: 70px !important; }

.mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl {
  padding: 70px !important; }

.pt-xl {
  padding-top: 70px !important; }

.pb-xl {
  padding-bottom: 70px !important; }

.py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl {
  padding-left: 70px !important; }

.pr-xl {
  padding-right: 70px !important; }

.px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl {
  margin: 140px !important; }

.mt-xxl {
  margin-top: 140px !important; }

.mb-xxl {
  margin-bottom: 140px !important; }

.my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl {
  margin-left: 140px !important; }

.mr-xxl {
  margin-right: 140px !important; }

.mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl {
  padding: 140px !important; }

.pt-xxl {
  padding-top: 140px !important; }

.pb-xxl {
  padding-bottom: 140px !important; }

.py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl {
  padding-left: 140px !important; }

.pr-xxl {
  padding-right: 140px !important; }

.px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.m-0-child > * {
  margin: 0 !important; }

.m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.mt-0-child > * {
  margin-top: 0 !important; }

.mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.mb-0-child > * {
  margin-bottom: 0 !important; }

.mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.ml-0-child > * {
  margin-left: 0 !important; }

.ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.mr-0-child > * {
  margin-right: 0 !important; }

.mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.p-0-child > * {
  padding: 0 !important; }

.p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.pt-0-child > * {
  padding-top: 0 !important; }

.pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.pb-0-child > * {
  padding-bottom: 0 !important; }

.pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.pl-0-child > * {
  padding-left: 0 !important; }

.pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.pr-0-child > * {
  padding-right: 0 !important; }

.pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.m-xs-child > * {
  margin: 5px !important; }

.m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.mt-xs-child > * {
  margin-top: 5px !important; }

.mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.mb-xs-child > * {
  margin-bottom: 5px !important; }

.mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.ml-xs-child > * {
  margin-left: 5px !important; }

.ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.mr-xs-child > * {
  margin-right: 5px !important; }

.mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.p-xs-child > * {
  padding: 5px !important; }

.p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.pt-xs-child > * {
  padding-top: 5px !important; }

.pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.pb-xs-child > * {
  padding-bottom: 5px !important; }

.pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.pl-xs-child > * {
  padding-left: 5px !important; }

.pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.pr-xs-child > * {
  padding-right: 5px !important; }

.pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.m-sm-child > * {
  margin: 15px !important; }

.m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.mt-sm-child > * {
  margin-top: 15px !important; }

.mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.mb-sm-child > * {
  margin-bottom: 15px !important; }

.mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.ml-sm-child > * {
  margin-left: 15px !important; }

.ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.mr-sm-child > * {
  margin-right: 15px !important; }

.mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.p-sm-child > * {
  padding: 15px !important; }

.p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.pt-sm-child > * {
  padding-top: 15px !important; }

.pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.pb-sm-child > * {
  padding-bottom: 15px !important; }

.pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.pl-sm-child > * {
  padding-left: 15px !important; }

.pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.pr-sm-child > * {
  padding-right: 15px !important; }

.pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.m-md-child > * {
  margin: 30px !important; }

.m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.mt-md-child > * {
  margin-top: 30px !important; }

.mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.mb-md-child > * {
  margin-bottom: 30px !important; }

.mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.ml-md-child > * {
  margin-left: 30px !important; }

.ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.mr-md-child > * {
  margin-right: 30px !important; }

.mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-md-child > * {
  padding: 30px !important; }

.p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.pt-md-child > * {
  padding-top: 30px !important; }

.pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.pb-md-child > * {
  padding-bottom: 30px !important; }

.pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.pl-md-child > * {
  padding-left: 30px !important; }

.pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.pr-md-child > * {
  padding-right: 30px !important; }

.pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.m-lg-child > * {
  margin: 50px !important; }

.m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.mt-lg-child > * {
  margin-top: 50px !important; }

.mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.mb-lg-child > * {
  margin-bottom: 50px !important; }

.mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.ml-lg-child > * {
  margin-left: 50px !important; }

.ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.mr-lg-child > * {
  margin-right: 50px !important; }

.mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.p-lg-child > * {
  padding: 50px !important; }

.p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.pt-lg-child > * {
  padding-top: 50px !important; }

.pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.pb-lg-child > * {
  padding-bottom: 50px !important; }

.pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.pl-lg-child > * {
  padding-left: 50px !important; }

.pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.pr-lg-child > * {
  padding-right: 50px !important; }

.pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.m-xl-child > * {
  margin: 70px !important; }

.m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.mt-xl-child > * {
  margin-top: 70px !important; }

.mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.mb-xl-child > * {
  margin-bottom: 70px !important; }

.mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.ml-xl-child > * {
  margin-left: 70px !important; }

.ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.mr-xl-child > * {
  margin-right: 70px !important; }

.mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.p-xl-child > * {
  padding: 70px !important; }

.p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.pt-xl-child > * {
  padding-top: 70px !important; }

.pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.pb-xl-child > * {
  padding-bottom: 70px !important; }

.pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.pl-xl-child > * {
  padding-left: 70px !important; }

.pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.pr-xl-child > * {
  padding-right: 70px !important; }

.pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.m-xxl-child > * {
  margin: 140px !important; }

.m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.mt-xxl-child > * {
  margin-top: 140px !important; }

.mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.mb-xxl-child > * {
  margin-bottom: 140px !important; }

.mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.ml-xxl-child > * {
  margin-left: 140px !important; }

.ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.mr-xxl-child > * {
  margin-right: 140px !important; }

.mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.p-xxl-child > * {
  padding: 140px !important; }

.p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.pt-xxl-child > * {
  padding-top: 140px !important; }

.pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.pb-xxl-child > * {
  padding-bottom: 140px !important; }

.pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.pl-xxl-child > * {
  padding-left: 140px !important; }

.pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.pr-xxl-child > * {
  padding-right: 140px !important; }

.pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (max-width: 1319.98px) {
  .lg-down-m-0 {
    margin: 0 !important; }
  .lg-down-m-0-child > * {
    margin: 0 !important; }
  .lg-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-0 {
    margin-top: 0 !important; }
  .lg-down-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-0 {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-0 {
    margin-left: 0 !important; }
  .lg-down-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-0 {
    margin-right: 0 !important; }
  .lg-down-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-0 {
    padding: 0 !important; }
  .lg-down-p-0-child > * {
    padding: 0 !important; }
  .lg-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-0 {
    padding-top: 0 !important; }
  .lg-down-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-0 {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-0 {
    padding-left: 0 !important; }
  .lg-down-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-0 {
    padding-right: 0 !important; }
  .lg-down-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1319.98px) {
  .lg-down-m-xs {
    margin: 5px !important; }
  .lg-down-m-xs-child > * {
    margin: 5px !important; }
  .lg-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-xs {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-xs {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-xs {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-xs {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-xs {
    padding: 5px !important; }
  .lg-down-p-xs-child > * {
    padding: 5px !important; }
  .lg-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-xs {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-xs {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-xs {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-xs {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-m-sm {
    margin: 15px !important; }
  .lg-down-m-sm-child > * {
    margin: 15px !important; }
  .lg-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-sm {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-sm {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-sm {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-sm {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-sm {
    padding: 15px !important; }
  .lg-down-p-sm-child > * {
    padding: 15px !important; }
  .lg-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-sm {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-sm {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-sm {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-sm {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-m-md {
    margin: 30px !important; }
  .lg-down-m-md-child > * {
    margin: 30px !important; }
  .lg-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-md {
    margin-top: 30px !important; }
  .lg-down-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-md {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-md {
    margin-left: 30px !important; }
  .lg-down-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-md {
    margin-right: 30px !important; }
  .lg-down-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-md {
    padding: 30px !important; }
  .lg-down-p-md-child > * {
    padding: 30px !important; }
  .lg-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-md {
    padding-top: 30px !important; }
  .lg-down-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-md {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-md {
    padding-left: 30px !important; }
  .lg-down-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-md {
    padding-right: 30px !important; }
  .lg-down-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-m-lg {
    margin: 50px !important; }
  .lg-down-m-lg-child > * {
    margin: 50px !important; }
  .lg-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-lg {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-lg {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-lg {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-lg {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-lg {
    padding: 50px !important; }
  .lg-down-p-lg-child > * {
    padding: 50px !important; }
  .lg-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-lg {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-lg {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-lg {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-lg {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-m-xl {
    margin: 70px !important; }
  .lg-down-m-xl-child > * {
    margin: 70px !important; }
  .lg-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-xl {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-xl {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-xl {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-xl {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-xl {
    padding: 70px !important; }
  .lg-down-p-xl-child > * {
    padding: 70px !important; }
  .lg-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-xl {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-xl {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-xl {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-xl {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-m-xxl {
    margin: 140px !important; }
  .lg-down-m-xxl-child > * {
    margin: 140px !important; }
  .lg-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mt-xxl {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-ml-xxl {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mr-xxl {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-p-xxl {
    padding: 140px !important; }
  .lg-down-p-xxl-child > * {
    padding: 140px !important; }
  .lg-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pt-xxl {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pl-xxl {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-pr-xxl {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1319.98px) {
  .lg-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-0 {
    margin: 0 !important; }
  .md-down-m-0-child > * {
    margin: 0 !important; }
  .md-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-0 {
    margin-top: 0 !important; }
  .md-down-mt-0-child > * {
    margin-top: 0 !important; }
  .md-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-0 {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-0 {
    margin-left: 0 !important; }
  .md-down-ml-0-child > * {
    margin-left: 0 !important; }
  .md-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-0 {
    margin-right: 0 !important; }
  .md-down-mr-0-child > * {
    margin-right: 0 !important; }
  .md-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-0 {
    padding: 0 !important; }
  .md-down-p-0-child > * {
    padding: 0 !important; }
  .md-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-0 {
    padding-top: 0 !important; }
  .md-down-pt-0-child > * {
    padding-top: 0 !important; }
  .md-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-0 {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-0 {
    padding-left: 0 !important; }
  .md-down-pl-0-child > * {
    padding-left: 0 !important; }
  .md-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-0 {
    padding-right: 0 !important; }
  .md-down-pr-0-child > * {
    padding-right: 0 !important; }
  .md-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-xs {
    margin: 5px !important; }
  .md-down-m-xs-child > * {
    margin: 5px !important; }
  .md-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-xs {
    margin-top: 5px !important; }
  .md-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-xs {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-xs {
    margin-left: 5px !important; }
  .md-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-xs {
    margin-right: 5px !important; }
  .md-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-xs {
    padding: 5px !important; }
  .md-down-p-xs-child > * {
    padding: 5px !important; }
  .md-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-xs {
    padding-top: 5px !important; }
  .md-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-xs {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-xs {
    padding-left: 5px !important; }
  .md-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-xs {
    padding-right: 5px !important; }
  .md-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-sm {
    margin: 15px !important; }
  .md-down-m-sm-child > * {
    margin: 15px !important; }
  .md-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-sm {
    margin-top: 15px !important; }
  .md-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-sm {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-sm {
    margin-left: 15px !important; }
  .md-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-sm {
    margin-right: 15px !important; }
  .md-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-sm {
    padding: 15px !important; }
  .md-down-p-sm-child > * {
    padding: 15px !important; }
  .md-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-sm {
    padding-top: 15px !important; }
  .md-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-sm {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-sm {
    padding-left: 15px !important; }
  .md-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-sm {
    padding-right: 15px !important; }
  .md-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-md {
    margin: 30px !important; }
  .md-down-m-md-child > * {
    margin: 30px !important; }
  .md-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-md {
    margin-top: 30px !important; }
  .md-down-mt-md-child > * {
    margin-top: 30px !important; }
  .md-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-md {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-md {
    margin-left: 30px !important; }
  .md-down-ml-md-child > * {
    margin-left: 30px !important; }
  .md-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-md {
    margin-right: 30px !important; }
  .md-down-mr-md-child > * {
    margin-right: 30px !important; }
  .md-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-md {
    padding: 30px !important; }
  .md-down-p-md-child > * {
    padding: 30px !important; }
  .md-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-md {
    padding-top: 30px !important; }
  .md-down-pt-md-child > * {
    padding-top: 30px !important; }
  .md-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-md {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-md {
    padding-left: 30px !important; }
  .md-down-pl-md-child > * {
    padding-left: 30px !important; }
  .md-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-md {
    padding-right: 30px !important; }
  .md-down-pr-md-child > * {
    padding-right: 30px !important; }
  .md-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-lg {
    margin: 50px !important; }
  .md-down-m-lg-child > * {
    margin: 50px !important; }
  .md-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-lg {
    margin-top: 50px !important; }
  .md-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-lg {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-lg {
    margin-left: 50px !important; }
  .md-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-lg {
    margin-right: 50px !important; }
  .md-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-lg {
    padding: 50px !important; }
  .md-down-p-lg-child > * {
    padding: 50px !important; }
  .md-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-lg {
    padding-top: 50px !important; }
  .md-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-lg {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-lg {
    padding-left: 50px !important; }
  .md-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-lg {
    padding-right: 50px !important; }
  .md-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-xl {
    margin: 70px !important; }
  .md-down-m-xl-child > * {
    margin: 70px !important; }
  .md-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-xl {
    margin-top: 70px !important; }
  .md-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-xl {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-xl {
    margin-left: 70px !important; }
  .md-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-xl {
    margin-right: 70px !important; }
  .md-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-xl {
    padding: 70px !important; }
  .md-down-p-xl-child > * {
    padding: 70px !important; }
  .md-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-xl {
    padding-top: 70px !important; }
  .md-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-xl {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-xl {
    padding-left: 70px !important; }
  .md-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-xl {
    padding-right: 70px !important; }
  .md-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 1139.98px) {
  .md-down-m-xxl {
    margin: 140px !important; }
  .md-down-m-xxl-child > * {
    margin: 140px !important; }
  .md-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mt-xxl {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mb-xxl {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-ml-xxl {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mr-xxl {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-p-xxl {
    padding: 140px !important; }
  .md-down-p-xxl-child > * {
    padding: 140px !important; }
  .md-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pt-xxl {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pb-xxl {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pl-xxl {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-pr-xxl {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 1139.98px) {
  .md-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-0 {
    margin: 0 !important; }
  .sm-down-m-0-child > * {
    margin: 0 !important; }
  .sm-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-0 {
    margin-top: 0 !important; }
  .sm-down-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-0 {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-0 {
    margin-left: 0 !important; }
  .sm-down-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-0 {
    margin-right: 0 !important; }
  .sm-down-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-0 {
    padding: 0 !important; }
  .sm-down-p-0-child > * {
    padding: 0 !important; }
  .sm-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-0 {
    padding-top: 0 !important; }
  .sm-down-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-0 {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-0 {
    padding-left: 0 !important; }
  .sm-down-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-0 {
    padding-right: 0 !important; }
  .sm-down-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xs {
    margin: 5px !important; }
  .sm-down-m-xs-child > * {
    margin: 5px !important; }
  .sm-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xs {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xs {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xs {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xs {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xs {
    padding: 5px !important; }
  .sm-down-p-xs-child > * {
    padding: 5px !important; }
  .sm-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xs {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xs {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xs {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xs {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-sm {
    margin: 15px !important; }
  .sm-down-m-sm-child > * {
    margin: 15px !important; }
  .sm-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-sm {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-sm {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-sm {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-sm {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-sm {
    padding: 15px !important; }
  .sm-down-p-sm-child > * {
    padding: 15px !important; }
  .sm-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-sm {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-sm {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-sm {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-sm {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-md {
    margin: 30px !important; }
  .sm-down-m-md-child > * {
    margin: 30px !important; }
  .sm-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-md {
    margin-top: 30px !important; }
  .sm-down-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-md {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-md {
    margin-left: 30px !important; }
  .sm-down-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-md {
    margin-right: 30px !important; }
  .sm-down-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-md {
    padding: 30px !important; }
  .sm-down-p-md-child > * {
    padding: 30px !important; }
  .sm-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-md {
    padding-top: 30px !important; }
  .sm-down-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-md {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-md {
    padding-left: 30px !important; }
  .sm-down-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-md {
    padding-right: 30px !important; }
  .sm-down-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-lg {
    margin: 50px !important; }
  .sm-down-m-lg-child > * {
    margin: 50px !important; }
  .sm-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-lg {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-lg {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-lg {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-lg {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-lg {
    padding: 50px !important; }
  .sm-down-p-lg-child > * {
    padding: 50px !important; }
  .sm-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-lg {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-lg {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-lg {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-lg {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xl {
    margin: 70px !important; }
  .sm-down-m-xl-child > * {
    margin: 70px !important; }
  .sm-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xl {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xl {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xl {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xl {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xl {
    padding: 70px !important; }
  .sm-down-p-xl-child > * {
    padding: 70px !important; }
  .sm-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xl {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xl {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xl {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xl {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 767.98px) {
  .sm-down-m-xxl {
    margin: 140px !important; }
  .sm-down-m-xxl-child > * {
    margin: 140px !important; }
  .sm-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mt-xxl {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-ml-xxl {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mr-xxl {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-p-xxl {
    padding: 140px !important; }
  .sm-down-p-xxl-child > * {
    padding: 140px !important; }
  .sm-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pt-xxl {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pl-xxl {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-pr-xxl {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 767.98px) {
  .sm-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-0 {
    margin: 0 !important; }
  .xs-down-m-0-child > * {
    margin: 0 !important; }
  .xs-down-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-0 {
    margin-top: 0 !important; }
  .xs-down-mt-0-child > * {
    margin-top: 0 !important; }
  .xs-down-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-0 {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xs-down-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xs-down-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-0 {
    margin-left: 0 !important; }
  .xs-down-ml-0-child > * {
    margin-left: 0 !important; }
  .xs-down-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-0 {
    margin-right: 0 !important; }
  .xs-down-mr-0-child > * {
    margin-right: 0 !important; }
  .xs-down-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xs-down-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-0 {
    padding: 0 !important; }
  .xs-down-p-0-child > * {
    padding: 0 !important; }
  .xs-down-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-0 {
    padding-top: 0 !important; }
  .xs-down-pt-0-child > * {
    padding-top: 0 !important; }
  .xs-down-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-0 {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xs-down-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xs-down-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-0 {
    padding-left: 0 !important; }
  .xs-down-pl-0-child > * {
    padding-left: 0 !important; }
  .xs-down-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-0 {
    padding-right: 0 !important; }
  .xs-down-pr-0-child > * {
    padding-right: 0 !important; }
  .xs-down-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xs-down-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xs {
    margin: 5px !important; }
  .xs-down-m-xs-child > * {
    margin: 5px !important; }
  .xs-down-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xs {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child > * {
    margin-top: 5px !important; }
  .xs-down-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xs {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xs-down-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xs-down-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xs {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child > * {
    margin-left: 5px !important; }
  .xs-down-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xs {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child > * {
    margin-right: 5px !important; }
  .xs-down-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xs-down-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xs {
    padding: 5px !important; }
  .xs-down-p-xs-child > * {
    padding: 5px !important; }
  .xs-down-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xs {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child > * {
    padding-top: 5px !important; }
  .xs-down-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xs {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xs-down-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xs-down-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xs {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child > * {
    padding-left: 5px !important; }
  .xs-down-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xs {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child > * {
    padding-right: 5px !important; }
  .xs-down-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xs-down-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-sm {
    margin: 15px !important; }
  .xs-down-m-sm-child > * {
    margin: 15px !important; }
  .xs-down-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-sm {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child > * {
    margin-top: 15px !important; }
  .xs-down-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-sm {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xs-down-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xs-down-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-sm {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child > * {
    margin-left: 15px !important; }
  .xs-down-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-sm {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child > * {
    margin-right: 15px !important; }
  .xs-down-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xs-down-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-sm {
    padding: 15px !important; }
  .xs-down-p-sm-child > * {
    padding: 15px !important; }
  .xs-down-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-sm {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child > * {
    padding-top: 15px !important; }
  .xs-down-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-sm {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xs-down-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xs-down-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-sm {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child > * {
    padding-left: 15px !important; }
  .xs-down-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-sm {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child > * {
    padding-right: 15px !important; }
  .xs-down-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xs-down-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-md {
    margin: 30px !important; }
  .xs-down-m-md-child > * {
    margin: 30px !important; }
  .xs-down-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-md {
    margin-top: 30px !important; }
  .xs-down-mt-md-child > * {
    margin-top: 30px !important; }
  .xs-down-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-md {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xs-down-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xs-down-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-md {
    margin-left: 30px !important; }
  .xs-down-ml-md-child > * {
    margin-left: 30px !important; }
  .xs-down-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-md {
    margin-right: 30px !important; }
  .xs-down-mr-md-child > * {
    margin-right: 30px !important; }
  .xs-down-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xs-down-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-md {
    padding: 30px !important; }
  .xs-down-p-md-child > * {
    padding: 30px !important; }
  .xs-down-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-md {
    padding-top: 30px !important; }
  .xs-down-pt-md-child > * {
    padding-top: 30px !important; }
  .xs-down-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-md {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xs-down-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xs-down-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-md {
    padding-left: 30px !important; }
  .xs-down-pl-md-child > * {
    padding-left: 30px !important; }
  .xs-down-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-md {
    padding-right: 30px !important; }
  .xs-down-pr-md-child > * {
    padding-right: 30px !important; }
  .xs-down-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xs-down-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-lg {
    margin: 50px !important; }
  .xs-down-m-lg-child > * {
    margin: 50px !important; }
  .xs-down-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-lg {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child > * {
    margin-top: 50px !important; }
  .xs-down-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-lg {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xs-down-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xs-down-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-lg {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child > * {
    margin-left: 50px !important; }
  .xs-down-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-lg {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child > * {
    margin-right: 50px !important; }
  .xs-down-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xs-down-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-lg {
    padding: 50px !important; }
  .xs-down-p-lg-child > * {
    padding: 50px !important; }
  .xs-down-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-lg {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child > * {
    padding-top: 50px !important; }
  .xs-down-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-lg {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xs-down-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xs-down-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-lg {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child > * {
    padding-left: 50px !important; }
  .xs-down-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-lg {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child > * {
    padding-right: 50px !important; }
  .xs-down-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xs-down-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xl {
    margin: 70px !important; }
  .xs-down-m-xl-child > * {
    margin: 70px !important; }
  .xs-down-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xl {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child > * {
    margin-top: 70px !important; }
  .xs-down-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xl {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xs-down-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xs-down-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xl {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child > * {
    margin-left: 70px !important; }
  .xs-down-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xl {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child > * {
    margin-right: 70px !important; }
  .xs-down-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xs-down-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xl {
    padding: 70px !important; }
  .xs-down-p-xl-child > * {
    padding: 70px !important; }
  .xs-down-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xl {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child > * {
    padding-top: 70px !important; }
  .xs-down-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xl {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xs-down-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xs-down-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xl {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child > * {
    padding-left: 70px !important; }
  .xs-down-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xl {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child > * {
    padding-right: 70px !important; }
  .xs-down-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xs-down-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (max-width: 575.98px) {
  .xs-down-m-xxl {
    margin: 140px !important; }
  .xs-down-m-xxl-child > * {
    margin: 140px !important; }
  .xs-down-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mt-xxl {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xs-down-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mb-xxl {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xs-down-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xs-down-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-ml-xxl {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xs-down-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mr-xxl {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xs-down-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xs-down-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-p-xxl {
    padding: 140px !important; }
  .xs-down-p-xxl-child > * {
    padding: 140px !important; }
  .xs-down-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pt-xxl {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xs-down-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pb-xxl {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xs-down-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xs-down-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pl-xxl {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xs-down-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-pr-xxl {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xs-down-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (max-width: 575.98px) {
  .xs-down-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xs-down-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

.xs-up-m-0 {
  margin: 0 !important; }

.xs-up-m-0-child > * {
  margin: 0 !important; }

.xs-up-m-0-child-not-last > *:not(:last-child) {
  margin: 0 !important; }

.xs-up-mt-0 {
  margin-top: 0 !important; }

.xs-up-mt-0-child > * {
  margin-top: 0 !important; }

.xs-up-mt-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important; }

.xs-up-mb-0 {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child > * {
  margin-bottom: 0 !important; }

.xs-up-mb-0-child-not-last > *:not(:last-child) {
  margin-bottom: 0 !important; }

.xs-up-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-my-0-child-not-last > *:not(:last-child) {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.xs-up-ml-0 {
  margin-left: 0 !important; }

.xs-up-ml-0-child > * {
  margin-left: 0 !important; }

.xs-up-ml-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important; }

.xs-up-mr-0 {
  margin-right: 0 !important; }

.xs-up-mr-0-child > * {
  margin-right: 0 !important; }

.xs-up-mr-0-child-not-last > *:not(:last-child) {
  margin-right: 0 !important; }

.xs-up-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child > * {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-mx-0-child-not-last > *:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.xs-up-p-0 {
  padding: 0 !important; }

.xs-up-p-0-child > * {
  padding: 0 !important; }

.xs-up-p-0-child-not-last > *:not(:last-child) {
  padding: 0 !important; }

.xs-up-pt-0 {
  padding-top: 0 !important; }

.xs-up-pt-0-child > * {
  padding-top: 0 !important; }

.xs-up-pt-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important; }

.xs-up-pb-0 {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child > * {
  padding-bottom: 0 !important; }

.xs-up-pb-0-child-not-last > *:not(:last-child) {
  padding-bottom: 0 !important; }

.xs-up-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child > * {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-py-0-child-not-last > *:not(:last-child) {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.xs-up-pl-0 {
  padding-left: 0 !important; }

.xs-up-pl-0-child > * {
  padding-left: 0 !important; }

.xs-up-pl-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important; }

.xs-up-pr-0 {
  padding-right: 0 !important; }

.xs-up-pr-0-child > * {
  padding-right: 0 !important; }

.xs-up-pr-0-child-not-last > *:not(:last-child) {
  padding-right: 0 !important; }

.xs-up-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child > * {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-px-0-child-not-last > *:not(:last-child) {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.xs-up-m-xs {
  margin: 5px !important; }

.xs-up-m-xs-child > * {
  margin: 5px !important; }

.xs-up-m-xs-child-not-last > *:not(:last-child) {
  margin: 5px !important; }

.xs-up-mt-xs {
  margin-top: 5px !important; }

.xs-up-mt-xs-child > * {
  margin-top: 5px !important; }

.xs-up-mt-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important; }

.xs-up-mb-xs {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child > * {
  margin-bottom: 5px !important; }

.xs-up-mb-xs-child-not-last > *:not(:last-child) {
  margin-bottom: 5px !important; }

.xs-up-my-xs {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-my-xs-child-not-last > *:not(:last-child) {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.xs-up-ml-xs {
  margin-left: 5px !important; }

.xs-up-ml-xs-child > * {
  margin-left: 5px !important; }

.xs-up-ml-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important; }

.xs-up-mr-xs {
  margin-right: 5px !important; }

.xs-up-mr-xs-child > * {
  margin-right: 5px !important; }

.xs-up-mr-xs-child-not-last > *:not(:last-child) {
  margin-right: 5px !important; }

.xs-up-mx-xs {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child > * {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-mx-xs-child-not-last > *:not(:last-child) {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.xs-up-p-xs {
  padding: 5px !important; }

.xs-up-p-xs-child > * {
  padding: 5px !important; }

.xs-up-p-xs-child-not-last > *:not(:last-child) {
  padding: 5px !important; }

.xs-up-pt-xs {
  padding-top: 5px !important; }

.xs-up-pt-xs-child > * {
  padding-top: 5px !important; }

.xs-up-pt-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important; }

.xs-up-pb-xs {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child > * {
  padding-bottom: 5px !important; }

.xs-up-pb-xs-child-not-last > *:not(:last-child) {
  padding-bottom: 5px !important; }

.xs-up-py-xs {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-py-xs-child-not-last > *:not(:last-child) {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.xs-up-pl-xs {
  padding-left: 5px !important; }

.xs-up-pl-xs-child > * {
  padding-left: 5px !important; }

.xs-up-pl-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important; }

.xs-up-pr-xs {
  padding-right: 5px !important; }

.xs-up-pr-xs-child > * {
  padding-right: 5px !important; }

.xs-up-pr-xs-child-not-last > *:not(:last-child) {
  padding-right: 5px !important; }

.xs-up-px-xs {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child > * {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-px-xs-child-not-last > *:not(:last-child) {
  padding-left: 5px !important;
  padding-right: 5px !important; }

.xs-up-m-sm {
  margin: 15px !important; }

.xs-up-m-sm-child > * {
  margin: 15px !important; }

.xs-up-m-sm-child-not-last > *:not(:last-child) {
  margin: 15px !important; }

.xs-up-mt-sm {
  margin-top: 15px !important; }

.xs-up-mt-sm-child > * {
  margin-top: 15px !important; }

.xs-up-mt-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important; }

.xs-up-mb-sm {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child > * {
  margin-bottom: 15px !important; }

.xs-up-mb-sm-child-not-last > *:not(:last-child) {
  margin-bottom: 15px !important; }

.xs-up-my-sm {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child > * {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-my-sm-child-not-last > *:not(:last-child) {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.xs-up-ml-sm {
  margin-left: 15px !important; }

.xs-up-ml-sm-child > * {
  margin-left: 15px !important; }

.xs-up-ml-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important; }

.xs-up-mr-sm {
  margin-right: 15px !important; }

.xs-up-mr-sm-child > * {
  margin-right: 15px !important; }

.xs-up-mr-sm-child-not-last > *:not(:last-child) {
  margin-right: 15px !important; }

.xs-up-mx-sm {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child > * {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-mx-sm-child-not-last > *:not(:last-child) {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.xs-up-p-sm {
  padding: 15px !important; }

.xs-up-p-sm-child > * {
  padding: 15px !important; }

.xs-up-p-sm-child-not-last > *:not(:last-child) {
  padding: 15px !important; }

.xs-up-pt-sm {
  padding-top: 15px !important; }

.xs-up-pt-sm-child > * {
  padding-top: 15px !important; }

.xs-up-pt-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important; }

.xs-up-pb-sm {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child > * {
  padding-bottom: 15px !important; }

.xs-up-pb-sm-child-not-last > *:not(:last-child) {
  padding-bottom: 15px !important; }

.xs-up-py-sm {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child > * {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-py-sm-child-not-last > *:not(:last-child) {
  padding-top: 15px !important;
  padding-bottom: 15px !important; }

.xs-up-pl-sm {
  padding-left: 15px !important; }

.xs-up-pl-sm-child > * {
  padding-left: 15px !important; }

.xs-up-pl-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important; }

.xs-up-pr-sm {
  padding-right: 15px !important; }

.xs-up-pr-sm-child > * {
  padding-right: 15px !important; }

.xs-up-pr-sm-child-not-last > *:not(:last-child) {
  padding-right: 15px !important; }

.xs-up-px-sm {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child > * {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-px-sm-child-not-last > *:not(:last-child) {
  padding-left: 15px !important;
  padding-right: 15px !important; }

.xs-up-m-md {
  margin: 30px !important; }

.xs-up-m-md-child > * {
  margin: 30px !important; }

.xs-up-m-md-child-not-last > *:not(:last-child) {
  margin: 30px !important; }

.xs-up-mt-md {
  margin-top: 30px !important; }

.xs-up-mt-md-child > * {
  margin-top: 30px !important; }

.xs-up-mt-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important; }

.xs-up-mb-md {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child > * {
  margin-bottom: 30px !important; }

.xs-up-mb-md-child-not-last > *:not(:last-child) {
  margin-bottom: 30px !important; }

.xs-up-my-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child > * {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-my-md-child-not-last > *:not(:last-child) {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.xs-up-ml-md {
  margin-left: 30px !important; }

.xs-up-ml-md-child > * {
  margin-left: 30px !important; }

.xs-up-ml-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important; }

.xs-up-mr-md {
  margin-right: 30px !important; }

.xs-up-mr-md-child > * {
  margin-right: 30px !important; }

.xs-up-mr-md-child-not-last > *:not(:last-child) {
  margin-right: 30px !important; }

.xs-up-mx-md {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child > * {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-mx-md-child-not-last > *:not(:last-child) {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.xs-up-p-md {
  padding: 30px !important; }

.xs-up-p-md-child > * {
  padding: 30px !important; }

.xs-up-p-md-child-not-last > *:not(:last-child) {
  padding: 30px !important; }

.xs-up-pt-md {
  padding-top: 30px !important; }

.xs-up-pt-md-child > * {
  padding-top: 30px !important; }

.xs-up-pt-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important; }

.xs-up-pb-md {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child > * {
  padding-bottom: 30px !important; }

.xs-up-pb-md-child-not-last > *:not(:last-child) {
  padding-bottom: 30px !important; }

.xs-up-py-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child > * {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-py-md-child-not-last > *:not(:last-child) {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.xs-up-pl-md {
  padding-left: 30px !important; }

.xs-up-pl-md-child > * {
  padding-left: 30px !important; }

.xs-up-pl-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important; }

.xs-up-pr-md {
  padding-right: 30px !important; }

.xs-up-pr-md-child > * {
  padding-right: 30px !important; }

.xs-up-pr-md-child-not-last > *:not(:last-child) {
  padding-right: 30px !important; }

.xs-up-px-md {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child > * {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-px-md-child-not-last > *:not(:last-child) {
  padding-left: 30px !important;
  padding-right: 30px !important; }

.xs-up-m-lg {
  margin: 50px !important; }

.xs-up-m-lg-child > * {
  margin: 50px !important; }

.xs-up-m-lg-child-not-last > *:not(:last-child) {
  margin: 50px !important; }

.xs-up-mt-lg {
  margin-top: 50px !important; }

.xs-up-mt-lg-child > * {
  margin-top: 50px !important; }

.xs-up-mt-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important; }

.xs-up-mb-lg {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child > * {
  margin-bottom: 50px !important; }

.xs-up-mb-lg-child-not-last > *:not(:last-child) {
  margin-bottom: 50px !important; }

.xs-up-my-lg {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child > * {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-my-lg-child-not-last > *:not(:last-child) {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.xs-up-ml-lg {
  margin-left: 50px !important; }

.xs-up-ml-lg-child > * {
  margin-left: 50px !important; }

.xs-up-ml-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important; }

.xs-up-mr-lg {
  margin-right: 50px !important; }

.xs-up-mr-lg-child > * {
  margin-right: 50px !important; }

.xs-up-mr-lg-child-not-last > *:not(:last-child) {
  margin-right: 50px !important; }

.xs-up-mx-lg {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child > * {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-mx-lg-child-not-last > *:not(:last-child) {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.xs-up-p-lg {
  padding: 50px !important; }

.xs-up-p-lg-child > * {
  padding: 50px !important; }

.xs-up-p-lg-child-not-last > *:not(:last-child) {
  padding: 50px !important; }

.xs-up-pt-lg {
  padding-top: 50px !important; }

.xs-up-pt-lg-child > * {
  padding-top: 50px !important; }

.xs-up-pt-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important; }

.xs-up-pb-lg {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child > * {
  padding-bottom: 50px !important; }

.xs-up-pb-lg-child-not-last > *:not(:last-child) {
  padding-bottom: 50px !important; }

.xs-up-py-lg {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child > * {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-py-lg-child-not-last > *:not(:last-child) {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.xs-up-pl-lg {
  padding-left: 50px !important; }

.xs-up-pl-lg-child > * {
  padding-left: 50px !important; }

.xs-up-pl-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important; }

.xs-up-pr-lg {
  padding-right: 50px !important; }

.xs-up-pr-lg-child > * {
  padding-right: 50px !important; }

.xs-up-pr-lg-child-not-last > *:not(:last-child) {
  padding-right: 50px !important; }

.xs-up-px-lg {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child > * {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-px-lg-child-not-last > *:not(:last-child) {
  padding-left: 50px !important;
  padding-right: 50px !important; }

.xs-up-m-xl {
  margin: 70px !important; }

.xs-up-m-xl-child > * {
  margin: 70px !important; }

.xs-up-m-xl-child-not-last > *:not(:last-child) {
  margin: 70px !important; }

.xs-up-mt-xl {
  margin-top: 70px !important; }

.xs-up-mt-xl-child > * {
  margin-top: 70px !important; }

.xs-up-mt-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important; }

.xs-up-mb-xl {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child > * {
  margin-bottom: 70px !important; }

.xs-up-mb-xl-child-not-last > *:not(:last-child) {
  margin-bottom: 70px !important; }

.xs-up-my-xl {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child > * {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-my-xl-child-not-last > *:not(:last-child) {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.xs-up-ml-xl {
  margin-left: 70px !important; }

.xs-up-ml-xl-child > * {
  margin-left: 70px !important; }

.xs-up-ml-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important; }

.xs-up-mr-xl {
  margin-right: 70px !important; }

.xs-up-mr-xl-child > * {
  margin-right: 70px !important; }

.xs-up-mr-xl-child-not-last > *:not(:last-child) {
  margin-right: 70px !important; }

.xs-up-mx-xl {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child > * {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-mx-xl-child-not-last > *:not(:last-child) {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.xs-up-p-xl {
  padding: 70px !important; }

.xs-up-p-xl-child > * {
  padding: 70px !important; }

.xs-up-p-xl-child-not-last > *:not(:last-child) {
  padding: 70px !important; }

.xs-up-pt-xl {
  padding-top: 70px !important; }

.xs-up-pt-xl-child > * {
  padding-top: 70px !important; }

.xs-up-pt-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important; }

.xs-up-pb-xl {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child > * {
  padding-bottom: 70px !important; }

.xs-up-pb-xl-child-not-last > *:not(:last-child) {
  padding-bottom: 70px !important; }

.xs-up-py-xl {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child > * {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-py-xl-child-not-last > *:not(:last-child) {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.xs-up-pl-xl {
  padding-left: 70px !important; }

.xs-up-pl-xl-child > * {
  padding-left: 70px !important; }

.xs-up-pl-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important; }

.xs-up-pr-xl {
  padding-right: 70px !important; }

.xs-up-pr-xl-child > * {
  padding-right: 70px !important; }

.xs-up-pr-xl-child-not-last > *:not(:last-child) {
  padding-right: 70px !important; }

.xs-up-px-xl {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child > * {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-px-xl-child-not-last > *:not(:last-child) {
  padding-left: 70px !important;
  padding-right: 70px !important; }

.xs-up-m-xxl {
  margin: 140px !important; }

.xs-up-m-xxl-child > * {
  margin: 140px !important; }

.xs-up-m-xxl-child-not-last > *:not(:last-child) {
  margin: 140px !important; }

.xs-up-mt-xxl {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child > * {
  margin-top: 140px !important; }

.xs-up-mt-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important; }

.xs-up-mb-xxl {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child > * {
  margin-bottom: 140px !important; }

.xs-up-mb-xxl-child-not-last > *:not(:last-child) {
  margin-bottom: 140px !important; }

.xs-up-my-xxl {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child > * {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-my-xxl-child-not-last > *:not(:last-child) {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.xs-up-ml-xxl {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child > * {
  margin-left: 140px !important; }

.xs-up-ml-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important; }

.xs-up-mr-xxl {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child > * {
  margin-right: 140px !important; }

.xs-up-mr-xxl-child-not-last > *:not(:last-child) {
  margin-right: 140px !important; }

.xs-up-mx-xxl {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child > * {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-mx-xxl-child-not-last > *:not(:last-child) {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.xs-up-p-xxl {
  padding: 140px !important; }

.xs-up-p-xxl-child > * {
  padding: 140px !important; }

.xs-up-p-xxl-child-not-last > *:not(:last-child) {
  padding: 140px !important; }

.xs-up-pt-xxl {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child > * {
  padding-top: 140px !important; }

.xs-up-pt-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important; }

.xs-up-pb-xxl {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child > * {
  padding-bottom: 140px !important; }

.xs-up-pb-xxl-child-not-last > *:not(:last-child) {
  padding-bottom: 140px !important; }

.xs-up-py-xxl {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child > * {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-py-xxl-child-not-last > *:not(:last-child) {
  padding-top: 140px !important;
  padding-bottom: 140px !important; }

.xs-up-pl-xxl {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child > * {
  padding-left: 140px !important; }

.xs-up-pl-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important; }

.xs-up-pr-xxl {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child > * {
  padding-right: 140px !important; }

.xs-up-pr-xxl-child-not-last > *:not(:last-child) {
  padding-right: 140px !important; }

.xs-up-px-xxl {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child > * {
  padding-left: 140px !important;
  padding-right: 140px !important; }

.xs-up-px-xxl-child-not-last > *:not(:last-child) {
  padding-left: 140px !important;
  padding-right: 140px !important; }

@media (min-width: 576px) {
  .sm-up-m-0 {
    margin: 0 !important; }
  .sm-up-m-0-child > * {
    margin: 0 !important; }
  .sm-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mt-0 {
    margin-top: 0 !important; }
  .sm-up-mt-0-child > * {
    margin-top: 0 !important; }
  .sm-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mb-0 {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .sm-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .sm-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-ml-0 {
    margin-left: 0 !important; }
  .sm-up-ml-0-child > * {
    margin-left: 0 !important; }
  .sm-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mr-0 {
    margin-right: 0 !important; }
  .sm-up-mr-0-child > * {
    margin-right: 0 !important; }
  .sm-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .sm-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-p-0 {
    padding: 0 !important; }
  .sm-up-p-0-child > * {
    padding: 0 !important; }
  .sm-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pt-0 {
    padding-top: 0 !important; }
  .sm-up-pt-0-child > * {
    padding-top: 0 !important; }
  .sm-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pb-0 {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .sm-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .sm-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pl-0 {
    padding-left: 0 !important; }
  .sm-up-pl-0-child > * {
    padding-left: 0 !important; }
  .sm-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-pr-0 {
    padding-right: 0 !important; }
  .sm-up-pr-0-child > * {
    padding-right: 0 !important; }
  .sm-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .sm-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 576px) {
  .sm-up-m-xs {
    margin: 5px !important; }
  .sm-up-m-xs-child > * {
    margin: 5px !important; }
  .sm-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xs {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .sm-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xs {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .sm-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .sm-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xs {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .sm-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xs {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .sm-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .sm-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xs {
    padding: 5px !important; }
  .sm-up-p-xs-child > * {
    padding: 5px !important; }
  .sm-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xs {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .sm-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xs {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .sm-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .sm-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xs {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .sm-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xs {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .sm-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .sm-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 576px) {
  .sm-up-m-sm {
    margin: 15px !important; }
  .sm-up-m-sm-child > * {
    margin: 15px !important; }
  .sm-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-sm {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .sm-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-sm {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .sm-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .sm-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-sm {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .sm-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-sm {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .sm-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .sm-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-p-sm {
    padding: 15px !important; }
  .sm-up-p-sm-child > * {
    padding: 15px !important; }
  .sm-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-sm {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .sm-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-sm {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .sm-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .sm-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-sm {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .sm-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-sm {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .sm-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .sm-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 576px) {
  .sm-up-m-md {
    margin: 30px !important; }
  .sm-up-m-md-child > * {
    margin: 30px !important; }
  .sm-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-md {
    margin-top: 30px !important; }
  .sm-up-mt-md-child > * {
    margin-top: 30px !important; }
  .sm-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-md {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .sm-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .sm-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-md {
    margin-left: 30px !important; }
  .sm-up-ml-md-child > * {
    margin-left: 30px !important; }
  .sm-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-md {
    margin-right: 30px !important; }
  .sm-up-mr-md-child > * {
    margin-right: 30px !important; }
  .sm-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .sm-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-p-md {
    padding: 30px !important; }
  .sm-up-p-md-child > * {
    padding: 30px !important; }
  .sm-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-md {
    padding-top: 30px !important; }
  .sm-up-pt-md-child > * {
    padding-top: 30px !important; }
  .sm-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-md {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .sm-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .sm-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-md {
    padding-left: 30px !important; }
  .sm-up-pl-md-child > * {
    padding-left: 30px !important; }
  .sm-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-md {
    padding-right: 30px !important; }
  .sm-up-pr-md-child > * {
    padding-right: 30px !important; }
  .sm-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .sm-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 576px) {
  .sm-up-m-lg {
    margin: 50px !important; }
  .sm-up-m-lg-child > * {
    margin: 50px !important; }
  .sm-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-lg {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .sm-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-lg {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .sm-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .sm-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-lg {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .sm-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-lg {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .sm-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .sm-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-p-lg {
    padding: 50px !important; }
  .sm-up-p-lg-child > * {
    padding: 50px !important; }
  .sm-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-lg {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .sm-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-lg {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .sm-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .sm-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-lg {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .sm-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-lg {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .sm-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .sm-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xl {
    margin: 70px !important; }
  .sm-up-m-xl-child > * {
    margin: 70px !important; }
  .sm-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xl {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .sm-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xl {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .sm-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .sm-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xl {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .sm-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xl {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .sm-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .sm-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xl {
    padding: 70px !important; }
  .sm-up-p-xl-child > * {
    padding: 70px !important; }
  .sm-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xl {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .sm-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xl {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .sm-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .sm-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xl {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .sm-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xl {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .sm-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .sm-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 576px) {
  .sm-up-m-xxl {
    margin: 140px !important; }
  .sm-up-m-xxl-child > * {
    margin: 140px !important; }
  .sm-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mt-xxl {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .sm-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mb-xxl {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .sm-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .sm-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-ml-xxl {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .sm-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mr-xxl {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .sm-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .sm-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-p-xxl {
    padding: 140px !important; }
  .sm-up-p-xxl-child > * {
    padding: 140px !important; }
  .sm-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pt-xxl {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .sm-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pb-xxl {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .sm-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .sm-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pl-xxl {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .sm-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-pr-xxl {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .sm-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 576px) {
  .sm-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .sm-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-m-0 {
    margin: 0 !important; }
  .md-up-m-0-child > * {
    margin: 0 !important; }
  .md-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mt-0 {
    margin-top: 0 !important; }
  .md-up-mt-0-child > * {
    margin-top: 0 !important; }
  .md-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mb-0 {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .md-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .md-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-ml-0 {
    margin-left: 0 !important; }
  .md-up-ml-0-child > * {
    margin-left: 0 !important; }
  .md-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mr-0 {
    margin-right: 0 !important; }
  .md-up-mr-0-child > * {
    margin-right: 0 !important; }
  .md-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .md-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-p-0 {
    padding: 0 !important; }
  .md-up-p-0-child > * {
    padding: 0 !important; }
  .md-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pt-0 {
    padding-top: 0 !important; }
  .md-up-pt-0-child > * {
    padding-top: 0 !important; }
  .md-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pb-0 {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .md-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .md-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pl-0 {
    padding-left: 0 !important; }
  .md-up-pl-0-child > * {
    padding-left: 0 !important; }
  .md-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 768px) {
  .md-up-pr-0 {
    padding-right: 0 !important; }
  .md-up-pr-0-child > * {
    padding-right: 0 !important; }
  .md-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .md-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 768px) {
  .md-up-m-xs {
    margin: 5px !important; }
  .md-up-m-xs-child > * {
    margin: 5px !important; }
  .md-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xs {
    margin-top: 5px !important; }
  .md-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .md-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xs {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .md-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .md-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xs {
    margin-left: 5px !important; }
  .md-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .md-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xs {
    margin-right: 5px !important; }
  .md-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .md-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .md-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-p-xs {
    padding: 5px !important; }
  .md-up-p-xs-child > * {
    padding: 5px !important; }
  .md-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xs {
    padding-top: 5px !important; }
  .md-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .md-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xs {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .md-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .md-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xs {
    padding-left: 5px !important; }
  .md-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .md-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xs {
    padding-right: 5px !important; }
  .md-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .md-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .md-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 768px) {
  .md-up-m-sm {
    margin: 15px !important; }
  .md-up-m-sm-child > * {
    margin: 15px !important; }
  .md-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mt-sm {
    margin-top: 15px !important; }
  .md-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .md-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mb-sm {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .md-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .md-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-ml-sm {
    margin-left: 15px !important; }
  .md-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .md-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mr-sm {
    margin-right: 15px !important; }
  .md-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .md-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .md-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-p-sm {
    padding: 15px !important; }
  .md-up-p-sm-child > * {
    padding: 15px !important; }
  .md-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pt-sm {
    padding-top: 15px !important; }
  .md-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .md-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pb-sm {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .md-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .md-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pl-sm {
    padding-left: 15px !important; }
  .md-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .md-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 768px) {
  .md-up-pr-sm {
    padding-right: 15px !important; }
  .md-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .md-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .md-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 768px) {
  .md-up-m-md {
    margin: 30px !important; }
  .md-up-m-md-child > * {
    margin: 30px !important; }
  .md-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mt-md {
    margin-top: 30px !important; }
  .md-up-mt-md-child > * {
    margin-top: 30px !important; }
  .md-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mb-md {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .md-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .md-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-ml-md {
    margin-left: 30px !important; }
  .md-up-ml-md-child > * {
    margin-left: 30px !important; }
  .md-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mr-md {
    margin-right: 30px !important; }
  .md-up-mr-md-child > * {
    margin-right: 30px !important; }
  .md-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .md-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-p-md {
    padding: 30px !important; }
  .md-up-p-md-child > * {
    padding: 30px !important; }
  .md-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pt-md {
    padding-top: 30px !important; }
  .md-up-pt-md-child > * {
    padding-top: 30px !important; }
  .md-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pb-md {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .md-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .md-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pl-md {
    padding-left: 30px !important; }
  .md-up-pl-md-child > * {
    padding-left: 30px !important; }
  .md-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 768px) {
  .md-up-pr-md {
    padding-right: 30px !important; }
  .md-up-pr-md-child > * {
    padding-right: 30px !important; }
  .md-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .md-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 768px) {
  .md-up-m-lg {
    margin: 50px !important; }
  .md-up-m-lg-child > * {
    margin: 50px !important; }
  .md-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mt-lg {
    margin-top: 50px !important; }
  .md-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .md-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mb-lg {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .md-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .md-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-ml-lg {
    margin-left: 50px !important; }
  .md-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .md-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mr-lg {
    margin-right: 50px !important; }
  .md-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .md-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .md-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-p-lg {
    padding: 50px !important; }
  .md-up-p-lg-child > * {
    padding: 50px !important; }
  .md-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pt-lg {
    padding-top: 50px !important; }
  .md-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .md-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pb-lg {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .md-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .md-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pl-lg {
    padding-left: 50px !important; }
  .md-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .md-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 768px) {
  .md-up-pr-lg {
    padding-right: 50px !important; }
  .md-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .md-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .md-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 768px) {
  .md-up-m-xl {
    margin: 70px !important; }
  .md-up-m-xl-child > * {
    margin: 70px !important; }
  .md-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xl {
    margin-top: 70px !important; }
  .md-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .md-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xl {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .md-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .md-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xl {
    margin-left: 70px !important; }
  .md-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .md-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xl {
    margin-right: 70px !important; }
  .md-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .md-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .md-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-p-xl {
    padding: 70px !important; }
  .md-up-p-xl-child > * {
    padding: 70px !important; }
  .md-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xl {
    padding-top: 70px !important; }
  .md-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .md-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xl {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .md-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .md-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xl {
    padding-left: 70px !important; }
  .md-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .md-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xl {
    padding-right: 70px !important; }
  .md-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .md-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .md-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 768px) {
  .md-up-m-xxl {
    margin: 140px !important; }
  .md-up-m-xxl-child > * {
    margin: 140px !important; }
  .md-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mt-xxl {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .md-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mb-xxl {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .md-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .md-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-ml-xxl {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .md-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mr-xxl {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .md-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .md-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-p-xxl {
    padding: 140px !important; }
  .md-up-p-xxl-child > * {
    padding: 140px !important; }
  .md-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pt-xxl {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .md-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pb-xxl {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .md-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .md-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pl-xxl {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .md-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 768px) {
  .md-up-pr-xxl {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .md-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 768px) {
  .md-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .md-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-m-0 {
    margin: 0 !important; }
  .lg-up-m-0-child > * {
    margin: 0 !important; }
  .lg-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-0 {
    margin-top: 0 !important; }
  .lg-up-mt-0-child > * {
    margin-top: 0 !important; }
  .lg-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-0 {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .lg-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .lg-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-0 {
    margin-left: 0 !important; }
  .lg-up-ml-0-child > * {
    margin-left: 0 !important; }
  .lg-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-0 {
    margin-right: 0 !important; }
  .lg-up-mr-0-child > * {
    margin-right: 0 !important; }
  .lg-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .lg-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-p-0 {
    padding: 0 !important; }
  .lg-up-p-0-child > * {
    padding: 0 !important; }
  .lg-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-0 {
    padding-top: 0 !important; }
  .lg-up-pt-0-child > * {
    padding-top: 0 !important; }
  .lg-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-0 {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .lg-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .lg-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-0 {
    padding-left: 0 !important; }
  .lg-up-pl-0-child > * {
    padding-left: 0 !important; }
  .lg-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-0 {
    padding-right: 0 !important; }
  .lg-up-pr-0-child > * {
    padding-right: 0 !important; }
  .lg-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .lg-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1140px) {
  .lg-up-m-xs {
    margin: 5px !important; }
  .lg-up-m-xs-child > * {
    margin: 5px !important; }
  .lg-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-xs {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .lg-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-xs {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .lg-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .lg-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-xs {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .lg-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-xs {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .lg-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .lg-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-p-xs {
    padding: 5px !important; }
  .lg-up-p-xs-child > * {
    padding: 5px !important; }
  .lg-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-xs {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .lg-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-xs {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .lg-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .lg-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-xs {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .lg-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-xs {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .lg-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .lg-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1140px) {
  .lg-up-m-sm {
    margin: 15px !important; }
  .lg-up-m-sm-child > * {
    margin: 15px !important; }
  .lg-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-sm {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .lg-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-sm {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .lg-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .lg-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-sm {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .lg-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-sm {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .lg-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .lg-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-p-sm {
    padding: 15px !important; }
  .lg-up-p-sm-child > * {
    padding: 15px !important; }
  .lg-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-sm {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .lg-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-sm {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .lg-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .lg-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-sm {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .lg-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-sm {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .lg-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .lg-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1140px) {
  .lg-up-m-md {
    margin: 30px !important; }
  .lg-up-m-md-child > * {
    margin: 30px !important; }
  .lg-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-md {
    margin-top: 30px !important; }
  .lg-up-mt-md-child > * {
    margin-top: 30px !important; }
  .lg-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-md {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .lg-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .lg-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-md {
    margin-left: 30px !important; }
  .lg-up-ml-md-child > * {
    margin-left: 30px !important; }
  .lg-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-md {
    margin-right: 30px !important; }
  .lg-up-mr-md-child > * {
    margin-right: 30px !important; }
  .lg-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .lg-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-p-md {
    padding: 30px !important; }
  .lg-up-p-md-child > * {
    padding: 30px !important; }
  .lg-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-md {
    padding-top: 30px !important; }
  .lg-up-pt-md-child > * {
    padding-top: 30px !important; }
  .lg-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-md {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .lg-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .lg-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-md {
    padding-left: 30px !important; }
  .lg-up-pl-md-child > * {
    padding-left: 30px !important; }
  .lg-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-md {
    padding-right: 30px !important; }
  .lg-up-pr-md-child > * {
    padding-right: 30px !important; }
  .lg-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .lg-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1140px) {
  .lg-up-m-lg {
    margin: 50px !important; }
  .lg-up-m-lg-child > * {
    margin: 50px !important; }
  .lg-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-lg {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .lg-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-lg {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .lg-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .lg-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-lg {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .lg-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-lg {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .lg-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .lg-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-p-lg {
    padding: 50px !important; }
  .lg-up-p-lg-child > * {
    padding: 50px !important; }
  .lg-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-lg {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .lg-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-lg {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .lg-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .lg-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-lg {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .lg-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-lg {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .lg-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .lg-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1140px) {
  .lg-up-m-xl {
    margin: 70px !important; }
  .lg-up-m-xl-child > * {
    margin: 70px !important; }
  .lg-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-xl {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .lg-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-xl {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .lg-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .lg-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-xl {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .lg-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-xl {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .lg-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .lg-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-p-xl {
    padding: 70px !important; }
  .lg-up-p-xl-child > * {
    padding: 70px !important; }
  .lg-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-xl {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .lg-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-xl {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .lg-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .lg-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-xl {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .lg-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-xl {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .lg-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .lg-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1140px) {
  .lg-up-m-xxl {
    margin: 140px !important; }
  .lg-up-m-xxl-child > * {
    margin: 140px !important; }
  .lg-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-mt-xxl {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .lg-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-mb-xxl {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .lg-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .lg-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-ml-xxl {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .lg-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-mr-xxl {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .lg-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .lg-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-p-xxl {
    padding: 140px !important; }
  .lg-up-p-xxl-child > * {
    padding: 140px !important; }
  .lg-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-pt-xxl {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .lg-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-pb-xxl {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .lg-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .lg-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-pl-xxl {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .lg-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-pr-xxl {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .lg-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1140px) {
  .lg-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .lg-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-m-0 {
    margin: 0 !important; }
  .xl-up-m-0-child > * {
    margin: 0 !important; }
  .xl-up-m-0-child-not-last > *:not(:last-child) {
    margin: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-0 {
    margin-top: 0 !important; }
  .xl-up-mt-0-child > * {
    margin-top: 0 !important; }
  .xl-up-mt-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-0 {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child > * {
    margin-bottom: 0 !important; }
  .xl-up-mb-0-child-not-last > *:not(:last-child) {
    margin-bottom: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .xl-up-my-0-child-not-last > *:not(:last-child) {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-0 {
    margin-left: 0 !important; }
  .xl-up-ml-0-child > * {
    margin-left: 0 !important; }
  .xl-up-ml-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-0 {
    margin-right: 0 !important; }
  .xl-up-mr-0-child > * {
    margin-right: 0 !important; }
  .xl-up-mr-0-child-not-last > *:not(:last-child) {
    margin-right: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child > * {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .xl-up-mx-0-child-not-last > *:not(:last-child) {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-p-0 {
    padding: 0 !important; }
  .xl-up-p-0-child > * {
    padding: 0 !important; }
  .xl-up-p-0-child-not-last > *:not(:last-child) {
    padding: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-0 {
    padding-top: 0 !important; }
  .xl-up-pt-0-child > * {
    padding-top: 0 !important; }
  .xl-up-pt-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-0 {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child > * {
    padding-bottom: 0 !important; }
  .xl-up-pb-0-child-not-last > *:not(:last-child) {
    padding-bottom: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child > * {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .xl-up-py-0-child-not-last > *:not(:last-child) {
    padding-top: 0 !important;
    padding-bottom: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-0 {
    padding-left: 0 !important; }
  .xl-up-pl-0-child > * {
    padding-left: 0 !important; }
  .xl-up-pl-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-0 {
    padding-right: 0 !important; }
  .xl-up-pr-0-child > * {
    padding-right: 0 !important; }
  .xl-up-pr-0-child-not-last > *:not(:last-child) {
    padding-right: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child > * {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .xl-up-px-0-child-not-last > *:not(:last-child) {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

@media (min-width: 1320px) {
  .xl-up-m-xs {
    margin: 5px !important; }
  .xl-up-m-xs-child > * {
    margin: 5px !important; }
  .xl-up-m-xs-child-not-last > *:not(:last-child) {
    margin: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-xs {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child > * {
    margin-top: 5px !important; }
  .xl-up-mt-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-xs {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child > * {
    margin-bottom: 5px !important; }
  .xl-up-mb-xs-child-not-last > *:not(:last-child) {
    margin-bottom: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-my-xs {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child > * {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .xl-up-my-xs-child-not-last > *:not(:last-child) {
    margin-top: 5px !important;
    margin-bottom: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-xs {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child > * {
    margin-left: 5px !important; }
  .xl-up-ml-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-xs {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child > * {
    margin-right: 5px !important; }
  .xl-up-mr-xs-child-not-last > *:not(:last-child) {
    margin-right: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-xs {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child > * {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .xl-up-mx-xs-child-not-last > *:not(:last-child) {
    margin-left: 5px !important;
    margin-right: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-p-xs {
    padding: 5px !important; }
  .xl-up-p-xs-child > * {
    padding: 5px !important; }
  .xl-up-p-xs-child-not-last > *:not(:last-child) {
    padding: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-xs {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child > * {
    padding-top: 5px !important; }
  .xl-up-pt-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-xs {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child > * {
    padding-bottom: 5px !important; }
  .xl-up-pb-xs-child-not-last > *:not(:last-child) {
    padding-bottom: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-py-xs {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child > * {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .xl-up-py-xs-child-not-last > *:not(:last-child) {
    padding-top: 5px !important;
    padding-bottom: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-xs {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child > * {
    padding-left: 5px !important; }
  .xl-up-pl-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-xs {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child > * {
    padding-right: 5px !important; }
  .xl-up-pr-xs-child-not-last > *:not(:last-child) {
    padding-right: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-px-xs {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child > * {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .xl-up-px-xs-child-not-last > *:not(:last-child) {
    padding-left: 5px !important;
    padding-right: 5px !important; } }

@media (min-width: 1320px) {
  .xl-up-m-sm {
    margin: 15px !important; }
  .xl-up-m-sm-child > * {
    margin: 15px !important; }
  .xl-up-m-sm-child-not-last > *:not(:last-child) {
    margin: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-sm {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child > * {
    margin-top: 15px !important; }
  .xl-up-mt-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-sm {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child > * {
    margin-bottom: 15px !important; }
  .xl-up-mb-sm-child-not-last > *:not(:last-child) {
    margin-bottom: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-my-sm {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child > * {
    margin-top: 15px !important;
    margin-bottom: 15px !important; }
  .xl-up-my-sm-child-not-last > *:not(:last-child) {
    margin-top: 15px !important;
    margin-bottom: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-sm {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child > * {
    margin-left: 15px !important; }
  .xl-up-ml-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-sm {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child > * {
    margin-right: 15px !important; }
  .xl-up-mr-sm-child-not-last > *:not(:last-child) {
    margin-right: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-sm {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child > * {
    margin-left: 15px !important;
    margin-right: 15px !important; }
  .xl-up-mx-sm-child-not-last > *:not(:last-child) {
    margin-left: 15px !important;
    margin-right: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-p-sm {
    padding: 15px !important; }
  .xl-up-p-sm-child > * {
    padding: 15px !important; }
  .xl-up-p-sm-child-not-last > *:not(:last-child) {
    padding: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-sm {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child > * {
    padding-top: 15px !important; }
  .xl-up-pt-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-sm {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child > * {
    padding-bottom: 15px !important; }
  .xl-up-pb-sm-child-not-last > *:not(:last-child) {
    padding-bottom: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-py-sm {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child > * {
    padding-top: 15px !important;
    padding-bottom: 15px !important; }
  .xl-up-py-sm-child-not-last > *:not(:last-child) {
    padding-top: 15px !important;
    padding-bottom: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-sm {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child > * {
    padding-left: 15px !important; }
  .xl-up-pl-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-sm {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child > * {
    padding-right: 15px !important; }
  .xl-up-pr-sm-child-not-last > *:not(:last-child) {
    padding-right: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-px-sm {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child > * {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .xl-up-px-sm-child-not-last > *:not(:last-child) {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

@media (min-width: 1320px) {
  .xl-up-m-md {
    margin: 30px !important; }
  .xl-up-m-md-child > * {
    margin: 30px !important; }
  .xl-up-m-md-child-not-last > *:not(:last-child) {
    margin: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-md {
    margin-top: 30px !important; }
  .xl-up-mt-md-child > * {
    margin-top: 30px !important; }
  .xl-up-mt-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-md {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child > * {
    margin-bottom: 30px !important; }
  .xl-up-mb-md-child-not-last > *:not(:last-child) {
    margin-bottom: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-my-md {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child > * {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .xl-up-my-md-child-not-last > *:not(:last-child) {
    margin-top: 30px !important;
    margin-bottom: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-md {
    margin-left: 30px !important; }
  .xl-up-ml-md-child > * {
    margin-left: 30px !important; }
  .xl-up-ml-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-md {
    margin-right: 30px !important; }
  .xl-up-mr-md-child > * {
    margin-right: 30px !important; }
  .xl-up-mr-md-child-not-last > *:not(:last-child) {
    margin-right: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-md {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child > * {
    margin-left: 30px !important;
    margin-right: 30px !important; }
  .xl-up-mx-md-child-not-last > *:not(:last-child) {
    margin-left: 30px !important;
    margin-right: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-p-md {
    padding: 30px !important; }
  .xl-up-p-md-child > * {
    padding: 30px !important; }
  .xl-up-p-md-child-not-last > *:not(:last-child) {
    padding: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-md {
    padding-top: 30px !important; }
  .xl-up-pt-md-child > * {
    padding-top: 30px !important; }
  .xl-up-pt-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-md {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child > * {
    padding-bottom: 30px !important; }
  .xl-up-pb-md-child-not-last > *:not(:last-child) {
    padding-bottom: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-py-md {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child > * {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .xl-up-py-md-child-not-last > *:not(:last-child) {
    padding-top: 30px !important;
    padding-bottom: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-md {
    padding-left: 30px !important; }
  .xl-up-pl-md-child > * {
    padding-left: 30px !important; }
  .xl-up-pl-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-md {
    padding-right: 30px !important; }
  .xl-up-pr-md-child > * {
    padding-right: 30px !important; }
  .xl-up-pr-md-child-not-last > *:not(:last-child) {
    padding-right: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-px-md {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child > * {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .xl-up-px-md-child-not-last > *:not(:last-child) {
    padding-left: 30px !important;
    padding-right: 30px !important; } }

@media (min-width: 1320px) {
  .xl-up-m-lg {
    margin: 50px !important; }
  .xl-up-m-lg-child > * {
    margin: 50px !important; }
  .xl-up-m-lg-child-not-last > *:not(:last-child) {
    margin: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-lg {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child > * {
    margin-top: 50px !important; }
  .xl-up-mt-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-lg {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child > * {
    margin-bottom: 50px !important; }
  .xl-up-mb-lg-child-not-last > *:not(:last-child) {
    margin-bottom: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-my-lg {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child > * {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .xl-up-my-lg-child-not-last > *:not(:last-child) {
    margin-top: 50px !important;
    margin-bottom: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-lg {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child > * {
    margin-left: 50px !important; }
  .xl-up-ml-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-lg {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child > * {
    margin-right: 50px !important; }
  .xl-up-mr-lg-child-not-last > *:not(:last-child) {
    margin-right: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-lg {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child > * {
    margin-left: 50px !important;
    margin-right: 50px !important; }
  .xl-up-mx-lg-child-not-last > *:not(:last-child) {
    margin-left: 50px !important;
    margin-right: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-p-lg {
    padding: 50px !important; }
  .xl-up-p-lg-child > * {
    padding: 50px !important; }
  .xl-up-p-lg-child-not-last > *:not(:last-child) {
    padding: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-lg {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child > * {
    padding-top: 50px !important; }
  .xl-up-pt-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-lg {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child > * {
    padding-bottom: 50px !important; }
  .xl-up-pb-lg-child-not-last > *:not(:last-child) {
    padding-bottom: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-py-lg {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child > * {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .xl-up-py-lg-child-not-last > *:not(:last-child) {
    padding-top: 50px !important;
    padding-bottom: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-lg {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child > * {
    padding-left: 50px !important; }
  .xl-up-pl-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-lg {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child > * {
    padding-right: 50px !important; }
  .xl-up-pr-lg-child-not-last > *:not(:last-child) {
    padding-right: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-px-lg {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child > * {
    padding-left: 50px !important;
    padding-right: 50px !important; }
  .xl-up-px-lg-child-not-last > *:not(:last-child) {
    padding-left: 50px !important;
    padding-right: 50px !important; } }

@media (min-width: 1320px) {
  .xl-up-m-xl {
    margin: 70px !important; }
  .xl-up-m-xl-child > * {
    margin: 70px !important; }
  .xl-up-m-xl-child-not-last > *:not(:last-child) {
    margin: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-xl {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child > * {
    margin-top: 70px !important; }
  .xl-up-mt-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-xl {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child > * {
    margin-bottom: 70px !important; }
  .xl-up-mb-xl-child-not-last > *:not(:last-child) {
    margin-bottom: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-my-xl {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child > * {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .xl-up-my-xl-child-not-last > *:not(:last-child) {
    margin-top: 70px !important;
    margin-bottom: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-xl {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child > * {
    margin-left: 70px !important; }
  .xl-up-ml-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-xl {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child > * {
    margin-right: 70px !important; }
  .xl-up-mr-xl-child-not-last > *:not(:last-child) {
    margin-right: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-xl {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child > * {
    margin-left: 70px !important;
    margin-right: 70px !important; }
  .xl-up-mx-xl-child-not-last > *:not(:last-child) {
    margin-left: 70px !important;
    margin-right: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-p-xl {
    padding: 70px !important; }
  .xl-up-p-xl-child > * {
    padding: 70px !important; }
  .xl-up-p-xl-child-not-last > *:not(:last-child) {
    padding: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-xl {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child > * {
    padding-top: 70px !important; }
  .xl-up-pt-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-xl {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child > * {
    padding-bottom: 70px !important; }
  .xl-up-pb-xl-child-not-last > *:not(:last-child) {
    padding-bottom: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-py-xl {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child > * {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .xl-up-py-xl-child-not-last > *:not(:last-child) {
    padding-top: 70px !important;
    padding-bottom: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-xl {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child > * {
    padding-left: 70px !important; }
  .xl-up-pl-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-xl {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child > * {
    padding-right: 70px !important; }
  .xl-up-pr-xl-child-not-last > *:not(:last-child) {
    padding-right: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-px-xl {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child > * {
    padding-left: 70px !important;
    padding-right: 70px !important; }
  .xl-up-px-xl-child-not-last > *:not(:last-child) {
    padding-left: 70px !important;
    padding-right: 70px !important; } }

@media (min-width: 1320px) {
  .xl-up-m-xxl {
    margin: 140px !important; }
  .xl-up-m-xxl-child > * {
    margin: 140px !important; }
  .xl-up-m-xxl-child-not-last > *:not(:last-child) {
    margin: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-mt-xxl {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child > * {
    margin-top: 140px !important; }
  .xl-up-mt-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-mb-xxl {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child > * {
    margin-bottom: 140px !important; }
  .xl-up-mb-xxl-child-not-last > *:not(:last-child) {
    margin-bottom: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-my-xxl {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child > * {
    margin-top: 140px !important;
    margin-bottom: 140px !important; }
  .xl-up-my-xxl-child-not-last > *:not(:last-child) {
    margin-top: 140px !important;
    margin-bottom: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-ml-xxl {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child > * {
    margin-left: 140px !important; }
  .xl-up-ml-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-mr-xxl {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child > * {
    margin-right: 140px !important; }
  .xl-up-mr-xxl-child-not-last > *:not(:last-child) {
    margin-right: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-mx-xxl {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child > * {
    margin-left: 140px !important;
    margin-right: 140px !important; }
  .xl-up-mx-xxl-child-not-last > *:not(:last-child) {
    margin-left: 140px !important;
    margin-right: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-p-xxl {
    padding: 140px !important; }
  .xl-up-p-xxl-child > * {
    padding: 140px !important; }
  .xl-up-p-xxl-child-not-last > *:not(:last-child) {
    padding: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-pt-xxl {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child > * {
    padding-top: 140px !important; }
  .xl-up-pt-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-pb-xxl {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child > * {
    padding-bottom: 140px !important; }
  .xl-up-pb-xxl-child-not-last > *:not(:last-child) {
    padding-bottom: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-py-xxl {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child > * {
    padding-top: 140px !important;
    padding-bottom: 140px !important; }
  .xl-up-py-xxl-child-not-last > *:not(:last-child) {
    padding-top: 140px !important;
    padding-bottom: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-pl-xxl {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child > * {
    padding-left: 140px !important; }
  .xl-up-pl-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-pr-xxl {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child > * {
    padding-right: 140px !important; }
  .xl-up-pr-xxl-child-not-last > *:not(:last-child) {
    padding-right: 140px !important; } }

@media (min-width: 1320px) {
  .xl-up-px-xxl {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child > * {
    padding-left: 140px !important;
    padding-right: 140px !important; }
  .xl-up-px-xxl-child-not-last > *:not(:last-child) {
    padding-left: 140px !important;
    padding-right: 140px !important; } }

/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/
/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: "Poppins", sans-serif;
  color: #141414;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 200;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 1.2; }

button:focus, input:focus, textarea:focus {
  outline: none !important;
  box-shadow: none !important; }

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none; }

img {
  max-width: inherit; }

a, img, span, button {
  display: inline-block; }

a {
  color: #000000;
  text-decoration: none; }
  a:hover {
    color: #000000; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.title-1, h1, .page-cms__title, .title-2, h2, .title-subhead__main, .property-details__right__title, .property-details-exception__inner__top__title, .bloc-contact__title, .page-article__title, .page-search__title {
  font-family: 'Poppins', sans-serif; }

.title-1, h1, .page-cms__title {
  font-size: 48px;
  line-height: 55px;
  font-weight: 600; }
  @media (max-width: 1139.98px) {
    .title-1, h1, .page-cms__title {
      font-size: 38px;
      line-height: 45px; } }

.title-2, h2, .title-subhead__main, .property-details__right__title, .property-details-exception__inner__top__title, .bloc-contact__title, .page-article__title, .page-search__title {
  font-size: 32px;
  line-height: 45px;
  font-weight: 600; }
  @media (max-width: 1139.98px) {
    .title-2, h2, .title-subhead__main, .property-details__right__title, .property-details-exception__inner__top__title, .bloc-contact__title, .page-article__title, .page-search__title {
      font-size: 22px;
      line-height: 35px; } }

.title-3, h3, .bloc-values__item__title {
  font-size: 24px;
  line-height: 33px; }
  @media (max-width: 1139.98px) {
    .title-3, h3, .bloc-values__item__title {
      font-size: 18px;
      line-height: 27px; } }

.text-light {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300; }
  .text-light p:last-child {
    margin-bottom: 0; }

.bar-after {
  position: relative;
  padding-bottom: 18px; }
  .bar-after:after {
    content: "";
    background-color: #FF3330;
    width: 88px;
    height: 11px;
    position: absolute;
    bottom: 0;
    left: 0; }
  .bar-after--large:after {
    width: 165px; }
  .bar-after--center:after {
    left: 50%;
    transform: translateX(-50%); }
  .bar-after--space-large {
    padding-bottom: 27px; }
  .bar-after--blue-light:after {
    background-color: #41B5EF; }

.link-btn {
  background-color: #FF3330;
  color: #FFFFFF;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  padding: 16px 20px;
  line-height: 23px;
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  border: 1px solid #FF3330; }
  .link-btn svg {
    margin-left: 27px;
    max-width: 15px;
    height: auto; }
  .link-btn:hover {
    color: #FF3330;
    background-color: #FFFFFF; }

.link-styled {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #053F93;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
  .link-styled:hover {
    color: red;
    text-decoration: none; }
  .link-styled:before {
    content: "";
    width: 22px;
    height: 1px;
    background-color: #FF3330;
    margin-right: 12px; }

.title-subhead {
  color: #053F93;
  text-transform: uppercase;
  line-height: 0;
  display: inline-flex;
  flex-flow: column; }
  .title-subhead__main {
    line-height: 40px;
    margin-bottom: 10px; }
  .title-subhead__sub {
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-flex;
    align-items: center; }
    .title-subhead__sub:before {
      content: "";
      background-color: #FF3330;
      width: 85px;
      height: 10px;
      margin-right: 4px; }
  .title-subhead--blue-light .title-subhead__sub:before {
    background-color: #41B5EF; }

/*
|--------------------
|      HEADER
|--------------------
*/
/*
|
| Header
|---------
*/
.header {
  border-bottom: 1px solid #ECE9E9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99; }
  @media (max-width: 1139.98px) {
    .header .container {
      max-width: 100%; } }
  .header__wrap {
    background-color: #FFFFFF;
    position: relative;
    z-index: 1; }
  .header__search {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 0;
    background-color: #FFFFFF;
    border-bottom: 1px solid #ECE9E9;
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
    .header__search.is-open {
      opacity: 1;
      transform: translateY(0%);
      pointer-events: all; }
    .header__search .searchform {
      max-width: 970px;
      margin: 0 auto;
      position: relative;
      padding: 20px 0; }
      .header__search .searchform input[type="text"] {
        font-size: 20px;
        line-height: 55px;
        color: #053F93;
        font-weight: 300;
        appearance: none;
        border: 0;
        border-bottom: 1px solid #053F93;
        width: 100%; }
        .header__search .searchform input[type="text"]::-webkit-input-placeholder {
          font-style: italic;
          font-weight: 300;
          font-size: 20px;
          line-height: 55px;
          color: #053F93; }
        .header__search .searchform input[type="text"]:-moz-placeholder {
          font-style: italic;
          font-weight: 300;
          font-size: 20px;
          line-height: 55px;
          color: #053F93; }
        .header__search .searchform input[type="text"]::-moz-placeholder {
          font-style: italic;
          font-weight: 300;
          font-size: 20px;
          line-height: 55px;
          color: #053F93; }
        .header__search .searchform input[type="text"]:-ms-input-placeholder {
          font-style: italic;
          font-weight: 300;
          font-size: 20px;
          line-height: 55px;
          color: #053F93; }
      .header__search .searchform .arrow-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 250ms; }
        .header__search .searchform .arrow-button:hover {
          background-color: #FF3330;
          color: #FFFFFF; }
  .header__inner {
    height: 113px;
    display: flex;
    align-items: center; }
    @media (max-width: 1139.98px) {
      .header__inner {
        height: 70px; } }
  .header__logo {
    width: 220px;
    margin-right: 60px; }
    .header__logo img {
      width: 100%;
      height: auto; }
    @media (max-width: 1319.98px) {
      .header__logo {
        width: 120px;
        margin-right: 30px; } }
  .header__nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center; }
    .header__nav__item {
      position: relative;
      margin-right: 40px;
      color: #053F93; }
      @media (max-width: 1319.98px) {
        .header__nav__item {
          margin-right: 20px; } }
      @media (max-width: 1139.98px) {
        .header__nav__item {
          display: none; } }
      .header__nav__item:last-child {
        margin-right: 0; }
      .header__nav__item:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 50px;
        bottom: -45px;
        left: 0; }
      .header__nav__item__sub {
        position: absolute;
        top: 53px;
        left: 50%;
        background-color: #FFFFFF;
        padding: 25px;
        white-space: nowrap;
        transform: translateX(-50%);
        min-width: 257px;
        display: none; }
        .header__nav__item__sub__item {
          margin-bottom: 15px; }
          .header__nav__item__sub__item:last-child {
            margin-bottom: 0; }
      .header__nav__item:hover .header__nav__item__sub {
        display: block; }
      .header__nav__item:hover svg {
        transform: rotate(-180deg); }
      .header__nav__item.current-menu-item a:after, .header__nav__item.current-menu-item span:after {
        opacity: 1; }
      .header__nav__item a, .header__nav__item span {
        font-size: 16px;
        color: #053F93;
        line-height: 23px;
        position: relative;
        display: inline-flex;
        cursor: pointer;
        align-items: center; }
        .header__nav__item a svg, .header__nav__item span svg {
          margin-left: 10px;
          transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
        .header__nav__item a:after, .header__nav__item span:after {
          content: "";
          width: calc(100% + 10px);
          height: 1px;
          position: absolute;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
          background-color: #FF3330;
          opacity: 0;
          transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
        .header__nav__item a:hover:after, .header__nav__item span:hover:after {
          opacity: 1; }
      .header__nav__item.hover-blue a:after, .header__nav__item.hover-blue span:after {
        background-color: #41B5EF; }
  .header__tools {
    margin-left: auto;
    display: inline-flex;
    align-items: center; }
    .header__tools__burger {
      display: none;
      position: relative;
      width: 24px;
      height: 24px; }
      .header__tools__burger svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }
      .header__tools__burger .close {
        display: none; }
      .header__tools__burger.is-open .close {
        display: block; }
      .header__tools__burger.is-open .open {
        display: none; }
      @media (max-width: 1139.98px) {
        .header__tools__burger {
          display: block; } }
    @media (max-width: 1139.98px) {
      .header__tools__social {
        display: none; } }
    .header__tools a, .header__tools span {
      color: #053F93;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
      margin-right: 40px;
      cursor: pointer; }
      .header__tools a:last-child, .header__tools span:last-child {
        margin-right: 0; }
      .header__tools a:hover, .header__tools span:hover {
        color: #FF3330; }

/*
|
| Menu
|-------
*/
.mobile-menu {
  position: fixed;
  top: 70px;
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
  background-color: #FFFFFF;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-15px);
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
  @media (max-width: 1139.98px) {
    .mobile-menu.is-shown {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateX(0px); } }
  @media (max-width: 1139.98px) {
    .mobile-menu {
      justify-content: flex-start;
      align-items: flex-start; } }
  .mobile-menu__menu {
    padding: 0 12px;
    margin: auto; }
    @media (max-width: 767.98px) {
      .mobile-menu__menu {
        margin: 0;
        margin-top: 50px; } }
    .mobile-menu__menu__item {
      margin-bottom: 30px;
      text-align: center;
      font-size: 24px;
      line-height: 24px; }
      .mobile-menu__menu__item svg {
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
      @media (max-width: 767.98px) {
        .mobile-menu__menu__item {
          margin-bottom: 25px;
          text-align: left; } }
      .mobile-menu__menu__item:last-child {
        margin-bottom: 0; }
      .mobile-menu__menu__item.is-open svg {
        transform: rotate(-180deg); }
      .mobile-menu__menu__item.is-open .mobile-menu__menu__item__wrap {
        max-height: 200px; }
      .mobile-menu__menu__item__wrap {
        max-height: 0;
        overflow: hidden;
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
        .mobile-menu__menu__item__wrap__sub {
          margin-top: 15px; }
          .mobile-menu__menu__item__wrap__sub__item {
            margin-bottom: 15px; }
    .mobile-menu__menu a, .mobile-menu__menu span {
      cursor: pointer;
      color: #053F93; }
  .mobile-menu__social {
    align-self: center;
    margin-bottom: 40px;
    margin-top: auto;
    color: #053F93;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
    .mobile-menu__social svg {
      width: 36px;
      height: auto; }
    @media (max-width: 767.98px) {
      .mobile-menu__social svg {
        width: 19px; } }
    .mobile-menu__social:hover {
      color: #FF3330; }

/*
|--------------------
|      FOOTER
|--------------------
*/
.footer__top {
  padding-top: 72px;
  padding-bottom: 37px; }
  @media (max-width: 1139.98px) {
    .footer__top {
      padding-top: 32px;
      padding-bottom: 15px;
      border-top: 1px solid #FF3330; } }

.footer__bottom {
  border-top: 1px solid #FF3330;
  padding-top: 18px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  color: #053F93; }
  .footer__bottom a {
    color: #053F93; }
  .footer__bottom__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 767.98px) {
      .footer__bottom__left {
        text-align: center;
        justify-content: center;
        width: 100%; } }
    .footer__bottom__left .sep {
      width: 1px;
      height: 20px;
      background-color: #FF3330;
      margin: 0 15px; }
      @media (max-width: 767.98px) {
        .footer__bottom__left .sep {
          display: none; } }
    .footer__bottom__left a, .footer__bottom__left #ot-sdk-btn.ot-sdk-show-settings, .footer__bottom__left #ot-sdk-btn.optanon-show-settings {
      color: #053F93;
      font-size: 14px;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      position: relative;
      border: 0;
      transition: none;
      padding: 0;
      font-weight: 300; }
      @media (max-width: 767.98px) {
        .footer__bottom__left a, .footer__bottom__left #ot-sdk-btn.ot-sdk-show-settings, .footer__bottom__left #ot-sdk-btn.optanon-show-settings {
          width: 100%;
          margin-top: 15px;
          justify-content: center; } }
      .footer__bottom__left a:after, .footer__bottom__left #ot-sdk-btn.ot-sdk-show-settings:after, .footer__bottom__left #ot-sdk-btn.optanon-show-settings:after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        height: 1px;
        background-color: #FF3330;
        transform: translateX(-50%);
        width: 100%;
        opacity: 0;
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
      .footer__bottom__left a:hover, .footer__bottom__left #ot-sdk-btn.ot-sdk-show-settings:hover, .footer__bottom__left #ot-sdk-btn.optanon-show-settings:hover {
        background-color: transparent;
        color: #053F93; }
        .footer__bottom__left a:hover:after, .footer__bottom__left #ot-sdk-btn.ot-sdk-show-settings:hover:after, .footer__bottom__left #ot-sdk-btn.optanon-show-settings:hover:after {
          opacity: 1; }
  @media (max-width: 1139.98px) {
    .footer__bottom__right {
      width: 100%;
      margin-top: 10px; } }
  @media (max-width: 767.98px) {
    .footer__bottom__right {
      text-align: center; } }
  .footer__bottom__right a {
    position: relative;
    display: inline-flex; }
    .footer__bottom__right a:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      height: 1px;
      background-color: #FF3330;
      transform: translateX(-50%);
      width: 100%; }

.footer__logo img {
  max-width: 100%;
  min-width: 250px;
  height: auto; }

@media (max-width: 767.98px) {
  .footer .col-logo {
    margin-bottom: 30px; } }

.footer__menu {
  display: flex;
  justify-content: space-between;
  padding: 0 22px; }
  @media (max-width: 575.98px) {
    .footer__menu {
      flex-wrap: wrap; } }
  .footer__menu__left, .footer__menu__center, .footer__menu__right {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media (max-width: 575.98px) {
      .footer__menu__left, .footer__menu__center, .footer__menu__right {
        width: 100%; } }
  .footer__menu a {
    margin-bottom: 28px;
    color: #053F93;
    position: relative; }
    .footer__menu a:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 50%;
      height: 1px;
      background-color: #FF3330;
      transform: translateX(-50%);
      width: 100%;
      opacity: 0;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
    .footer__menu a:hover:after {
      opacity: 1; }

.footer__social {
  display: inline-flex;
  align-items: center; }
  .footer__social svg {
    margin-right: 13px; }

/*
|--------------------
|      CONTENT
|--------------------
*/
/*
|
| Banner
|---------
*/
/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {
  /*
    * Card img
    */
  /*
    * Card content
    */
  /*
    * Hover state
    */ }
  .custom-card .custom-card-link {
    display: block; }
  .custom-card .card-img-container {
    position: relative;
    overflow: hidden; }
    .custom-card .card-img-container:after {
      content: '';
      display: block;
      padding-bottom: 100%; }
    .custom-card .card-img-container .card-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      will-change: transform; }
  .custom-card .card-content .date {
    color: rgba(20, 20, 20, 0.6); }
  .custom-card:hover .card-img-container .card-img {
    transform: scale(1.05); }

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0; }
  .cookie-banner .item-close {
    font-size: 24px;
    cursor: pointer; }
  @media (max-width: 767.98px) {
    .cookie-banner {
      padding: 20px 0;
      font-size: 12px; } }
  @media (max-width: 575.98px) {
    .cookie-banner {
      padding: 10px 0 20px 0; }
      .cookie-banner .item-close {
        font-size: 18px; } }

/*
|
| Pagination
|---------------
*/
.pagination-container li {
  display: inline-block; }
  .pagination-container li a {
    transition: all 0.3s ease-out;
    padding: 0 8px; }
    .pagination-container li a:hover {
      color: #979797; }
  .pagination-container li .current {
    padding: 0 8px;
    font-weight: 800;
    color: #979797; }

/*
|
| CMS
|------
*/
.cms td {
  border: 1px solid #053F93; }

.cms span {
  display: inline; }

.cms h1, .cms h2, .cms h3, .cms h4, .cms h5, .cms h6 {
  margin-bottom: 30px; }

.cms img {
  display: block;
  width: 100%;
  height: auto; }

.cms strong {
  font-weight: 800; }

.cms p {
  margin-bottom: 20px; }

.cms.cms-no-break p {
  margin-bottom: 0; }

.cms blockquote {
  position: relative;
  width: 80%;
  margin-right: 0;
  margin-left: auto;
  padding: 130px 0;
  font-size: 30px;
  line-height: 1.5em;
  font-weight: 300; }
  @media (max-width: 1139.98px) {
    .cms blockquote {
      margin: auto; } }
  .cms blockquote:before {
    content: "";
    position: absolute;
    background-image: url("../img/icon/quote-left.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(-60px) translateY(-30px); }
  .cms blockquote:after {
    content: "";
    position: absolute;
    right: 0;
    background-image: url("../img/icon/quote-right.svg");
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translateX(0) translateY(-50px); }
    @media (max-width: 1139.98px) {
      .cms blockquote:after {
        transform: translateX(60px) translateY(-50px); } }

.rte {
  color: rgba(5, 63, 147, 0.8);
  font-size: 16px;
  line-height: 26px; }
  .rte .section {
    padding: 0 !important; }
  .rte td {
    border: 1px solid #053F93;
    padding: 5px; }
  .rte h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase; }
  .rte h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    text-transform: uppercase; }
  .rte h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase; }
  .rte h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase; }
  .rte p, .rte ul {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .rte p:first-child, .rte ul:first-child {
      margin-top: 0; }
  .rte > p:last-child, .rte > table:last-child {
    margin-bottom: 0; }
  .rte table {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .rte ul {
    padding-left: 1rem; }
    .rte ul li {
      list-style-type: disc; }

.section-flexible {
  margin: 40px 0; }

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: 113px; }
  @media (max-width: 1139.98px) {
    .page-offset {
      padding-top: 70px; } }

/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block; }
  .custom-loader.cl-center {
    margin-left: auto;
    margin-right: auto; }
  .custom-loader svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: #000000; }
    .custom-loader svg circle {
      stroke: #000000; }
  .custom-loader.ajax-loader {
    display: none; }

.pagination__inner {
  display: flex;
  align-items: center;
  justify-content: center; }

.pagination li {
  margin: 0 5px; }
  .pagination li a, .pagination li span {
    color: #053F93;
    font-size: 16px;
    line-height: 16px;
    position: relative; }
    .pagination li a:after, .pagination li span:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -5px;
      min-width: 18px;
      width: calc(100% + 10px);
      transform: translateX(-50%);
      background-color: #FF3330;
      height: 1px;
      display: none; }
    .pagination li a:hover:after, .pagination li span:hover:after {
      display: block; }
  .pagination li span:after {
    display: block; }

.banner-home {
  height: calc(100vh - 113px);
  background-size: cover;
  background-position: center;
  position: relative; }
  @media (max-width: 1139.98px) {
    .banner-home {
      height: calc(100vh - 70px); } }
  .banner-home:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(134, 134, 134, 0) 36%, rgba(0, 0, 0, 0.5) 90%); }
  .banner-home__inner {
    position: absolute;
    padding: 0 12px;
    left: 0;
    bottom: 27px;
    width: 100%;
    text-align: center;
    color: #FFFFFF; }
    @media (max-width: 767.98px) {
      .banner-home__inner {
        bottom: 127px; } }
  .banner-home__title {
    text-transform: uppercase;
    margin-bottom: 42px; }

.intro-home {
  margin-top: 120px;
  margin-bottom: 120px; }
  @media (max-width: 1319.98px) {
    .intro-home {
      margin-top: 60px;
      margin-bottom: 60px; } }
  .intro-home__title {
    text-transform: uppercase; }
  .intro-home__text {
    margin-bottom: 42px; }
  @media (max-width: 1319.98px) {
    .intro-home .col-left {
      margin-bottom: 24px; } }

.cards-list-home {
  margin: 130px 0; }
  @media (max-width: 1319.98px) {
    .cards-list-home {
      margin: 60px 0; } }
  .cards-list-home__card {
    display: flex;
    flex-flow: column;
    width: 100%;
    border: 1px solid rgba(5, 63, 147, 0.2);
    padding: 66px 20px 40px 20px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start; }
    .cards-list-home__card__picto {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      height: 70px; }
      .cards-list-home__card__picto img {
        max-height: 100%; }
    .cards-list-home__card__title {
      text-transform: uppercase;
      color: #FF3330;
      margin-bottom: 15px; }
    .cards-list-home__card__text {
      margin-bottom: 48px; }
    .cards-list-home__card .link-styled {
      margin-top: auto; }
  .cards-list-home .col-item {
    margin-bottom: 24px; }

.property-details {
  margin-top: 40px;
  margin-bottom: 120px; }
  @media (max-width: 1319.98px) {
    .property-details {
      margin: 60px 0;
      margin-top: 30px; } }
  .property-details .row {
    align-items: center; }
  @media (max-width: 1319.98px) {
    .property-details .col-left {
      margin-bottom: 30px; } }
  .property-details__img {
    position: relative;
    cursor: pointer; }
    .property-details__img img {
      width: 100%;
      height: auto; }
    .property-details__img__cta {
      position: absolute;
      left: 16px;
      bottom: 16px;
      color: #002252;
      text-transform: uppercase;
      background-color: #FFFFFF;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 23px;
      padding: 9px; }
      .property-details__img__cta svg {
        margin-right: 8px; }
  .property-details__right__misc {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #053F93;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 17px; }
    .property-details__right__misc .sep {
      width: 1px;
      height: 21px;
      margin: 0 28px; }
    .property-details__right__misc__ref {
      opacity: 0.5; }
  .property-details__right__title {
    font-size: 31px;
    color: #053F93;
    margin-bottom: 8px; }
  .property-details__right__price {
    display: flex;
    align-items: center;
    margin-bottom: 25px; }
    .property-details__right__price__number {
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      color: #053F93; }
    .property-details__right__price__details {
      color: #053F93;
      opacity: 0.5;
      font-size: 14px;
      line-height: 14px;
      margin-left: 9px; }
  .property-details__right__surface {
    display: flex;
    width: 100%;
    border-top: 1px solid #ECE9E9;
    border-bottom: 1px solid #ECE9E9;
    padding-top: 22px;
    padding-bottom: 28px;
    margin-bottom: 22px; }
    @media (max-width: 575.98px) {
      .property-details__right__surface {
        justify-content: space-around; } }
    .property-details__right__surface__left {
      margin-right: 120px; }
      @media (max-width: 575.98px) {
        .property-details__right__surface__left {
          margin-right: 0px; } }
    .property-details__right__surface__title {
      text-transform: uppercase;
      color: #053F93;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 16px;
      opacity: 0.5; }
    .property-details__right__surface__num {
      color: #053F93; }
  .property-details__right__desc {
    margin-bottom: 21px; }
    .property-details__right__desc__title {
      text-transform: uppercase;
      color: #053F93;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 16px;
      opacity: 0.5; }
    .property-details__right__desc__text {
      color: #002252;
      font-size: 16px;
      line-height: 24px; }
      .property-details__right__desc__text p:last-child {
        margin-bottom: 0; }
  .property-details__right__tools {
    display: flex;
    align-items: center; }
    @media (max-width: 767.98px) {
      .property-details__right__tools {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start; } }
    .property-details__right__tools a {
      margin-right: 73px; }
      @media (max-width: 767.98px) {
        .property-details__right__tools a {
          margin-right: 0;
          margin-bottom: 15px; } }
      .property-details__right__tools a:last-child {
        margin-right: 0; }
        @media (max-width: 767.98px) {
          .property-details__right__tools a:last-child {
            margin-bottom: 0; } }

.property-details-exception {
  margin-bottom: 120px; }
  @media (max-width: 1319.98px) {
    .property-details-exception {
      margin-bottom: 60px; } }
  .property-details-exception__banner {
    background-size: cover;
    background-position: center;
    height: 558px;
    position: relative; }
    @media (max-width: 767.98px) {
      .property-details-exception__banner {
        height: 450px; } }
    .property-details-exception__banner .container {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    .property-details-exception__banner__tools {
      position: absolute;
      top: 20px;
      left: 12px; }
      .property-details-exception__banner__tools .breadcrumb {
        margin-bottom: 30px; }
        @media (max-width: 767.98px) {
          .property-details-exception__banner__tools .breadcrumb {
            margin-bottom: 10px; } }
      .property-details-exception__banner__tools__back {
        color: #FFFFFF;
        display: inline-flex;
        margin-top: 20px;
        margin-bottom: 50px;
        align-items: center; }
        .property-details-exception__banner__tools__back svg {
          margin-right: 25px; }
        .property-details-exception__banner__tools__back:hover {
          color: #FFFFFF; }
          .property-details-exception__banner__tools__back:hover span {
            text-decoration: underline; }
  .property-details-exception__inner {
    background-color: #FFFFFF;
    margin-top: -180px;
    position: relative;
    padding-top: 38px;
    min-height: 190px; }
    .property-details-exception__inner__cta {
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: -12px;
      transform: translateY(-100%);
      color: #002252;
      text-transform: uppercase;
      background-color: #FFFFFF;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      line-height: 23px;
      padding: 9px; }
      .property-details-exception__inner__cta svg {
        margin-right: 8px; }
    .property-details-exception__inner__top {
      text-align: center; }
      @media (max-width: 1139.98px) {
        .property-details-exception__inner__top {
          padding: 0 12px; } }
      .property-details-exception__inner__top__title {
        color: #053F93;
        margin-bottom: 8px; }
      .property-details-exception__inner__top__number {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: #053F93;
        margin-bottom: 13px; }
      .property-details-exception__inner__top__introduction {
        color: #002252;
        opacity: 0.8;
        font-size: 16px;
        line-height: 24px; }
        .property-details-exception__inner__top__introduction p:last-child {
          margin-bottom: 0; }
    .property-details-exception__inner__bottom {
      display: flex;
      align-items: flex-start;
      margin-top: 86px;
      flex-wrap: wrap; }
      .property-details-exception__inner__bottom__left {
        width: 75%;
        margin-right: 15px; }
        @media (max-width: 1139.98px) {
          .property-details-exception__inner__bottom__left {
            width: 100%;
            margin-right: 0;
            order: 1; } }
        .property-details-exception__inner__bottom__left__title {
          text-transform: uppercase;
          color: #053F93;
          font-size: 16px;
          line-height: 23px;
          margin-bottom: 12px;
          opacity: 0.5; }
        .property-details-exception__inner__bottom__left__content {
          color: #002252;
          opacity: 0.8;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 49px;
          max-width: 893px; }
        .property-details-exception__inner__bottom__left__misc {
          display: flex;
          flex-flow: column;
          align-items: flex-start; }
          .property-details-exception__inner__bottom__left__misc a {
            margin-bottom: 32px; }
            .property-details-exception__inner__bottom__left__misc a:last-child {
              margin-bottom: 0; }
      .property-details-exception__inner__bottom__right {
        padding-left: 56px;
        border-left: 1px solid #ECE9E9; }
        @media (max-width: 1319.98px) {
          .property-details-exception__inner__bottom__right {
            padding-left: 15px; } }
        @media (max-width: 1139.98px) {
          .property-details-exception__inner__bottom__right {
            width: 100%;
            margin-right: 0;
            padding-left: 0;
            border-left: 0;
            order: 0;
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; } }
        .property-details-exception__inner__bottom__right__item {
          margin-bottom: 31px; }
          @media (max-width: 767.98px) {
            .property-details-exception__inner__bottom__right__item {
              width: 100%; } }
          .property-details-exception__inner__bottom__right__item__title {
            text-transform: uppercase;
            color: #053F93;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 16px;
            opacity: 0.5; }
          .property-details-exception__inner__bottom__right__item__text {
            margin-bottom: 0;
            color: #053F93; }
          .property-details-exception__inner__bottom__right__item:last-child {
            margin-bottom: 0; }

.property-details-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #FFFFFF;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateX(-15px);
  transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1); }
  .property-details-gallery.is-shown {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transform: translateX(0); }
  .property-details-gallery__close {
    position: absolute;
    width: 30px;
    height: 30px;
    color: #053F93;
    top: 15px;
    right: 15px;
    cursor: pointer; }
  .property-details-gallery__inner {
    flex-flow: column;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 800px;
    height: calc(100vh - 20px); }
    .property-details-gallery__inner__slide {
      text-align: center; }
      .property-details-gallery__inner__slide img {
        max-width: 100%;
        height: auto;
        margin-bottom: 15px; }
      .property-details-gallery__inner__slide__legend {
        color: #053F93;
        font-size: 16px; }
    .property-details-gallery__inner__tools {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px; }
      .property-details-gallery__inner__tools .swiper-pagination-bullets {
        margin: 0 20px; }
        .property-details-gallery__inner__tools .swiper-pagination-bullets.color-blue-light .swiper-pagination-bullet {
          background-color: #41B5EF; }
        .property-details-gallery__inner__tools .swiper-pagination-bullets .swiper-pagination-bullet {
          width: 16px;
          height: 16px;
          margin: 0 8px;
          background-color: #FF3330; }

.bloc-contact {
  background-size: cover;
  background-position: center;
  padding-top: 153px; }
  .bloc-contact__inner {
    background-color: #FFFFFF;
    padding: 47px 58px 37px 58px;
    width: 100%;
    max-width: 686px; }
  .bloc-contact__title {
    color: #053F93;
    text-transform: uppercase;
    margin-bottom: 31px; }
  .bloc-contact__text {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #002252;
    margin-bottom: 35px; }
    .bloc-contact__text p:last-child {
      margin-bottom: 0; }

.related-properties {
  margin: 120px 0; }
  @media (max-width: 1319.98px) {
    .related-properties {
      margin: 60px 0; } }
  @media (max-width: 1139.98px) {
    .related-properties .col-item {
      margin-bottom: 24px; } }
  .related-properties__title {
    color: #053F93;
    margin-bottom: 42px;
    text-transform: uppercase; }

.card-property {
  display: block; }
  .card-property:hover .card-property__img img {
    transform: scale(1.1); }
  .card-property.is-red:hover .card-property__details__title {
    color: #FF3330; }
  .card-property.is-blue-light:hover .card-property__details__title {
    color: #41B5EF; }
  .card-property__img {
    position: relative;
    margin-bottom: 24px;
    overflow: hidden; }
    .card-property__img img {
      width: 100%;
      height: auto;
      transform-origin: center;
      transition: all 250ms; }
    .card-property__img__sold {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-radius: 3px;
      padding: 3px 11px; }
  .card-property__details__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #053F93;
    margin-bottom: 16px;
    transition: all 250ms; }
  .card-property__details__misc {
    display: flex;
    align-items: center;
    color: #002252;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px; }
    .card-property__details__misc .sep {
      width: 1px;
      height: 18px;
      margin: 0 14px;
      background-color: #FF3330; }
    .card-property__details__misc__surface, .card-property__details__misc__price {
      opacity: 0.5; }

.slider-properties {
  margin: 120px 0;
  overflow: hidden; }
  @media (max-width: 1319.98px) {
    .slider-properties {
      margin: 60px 0; } }
  .slider-properties .swiper-container {
    overflow: visible; }
  .slider-properties__top {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .slider-properties__title {
    color: #053F93;
    text-transform: uppercase; }
  .slider-properties__arrows {
    display: flex;
    align-items: center; }
    .slider-properties__arrows .arrow-button:first-child {
      margin-right: 24px; }

.flexible-text-cta__btn {
  margin-top: 42px; }

.flexible-image-large {
  text-align: center; }
  .flexible-image-large img {
    max-width: 100%;
    height: auto; }

.flexible-video__inner {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; }
  .flexible-video__inner iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    z-index: 0; }
  .flexible-video__inner__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .flexible-video__inner__cover.is-hidden {
      display: none; }
    .flexible-video__inner__cover__play {
      color: #FFFFFF;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 124px;
      height: 124px;
      border-radius: 50%;
      border: 1px solid #FFFFFF;
      cursor: pointer; }

.banner-title-content {
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 425px; }
  @media (max-width: 1319.98px) {
    .banner-title-content {
      min-height: 0; } }
  .banner-title-content:before {
    content: '';
    background-color: #000000;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .banner-title-content .container {
    padding-top: 112px;
    padding-bottom: 88px;
    position: relative;
    color: #FFFFFF; }
    .banner-title-content .container .breadcrumb {
      position: absolute;
      top: 21px;
      left: 12px; }
  .banner-title-content__title {
    margin-bottom: 20px;
    text-transform: uppercase; }
  .banner-title-content__content {
    font-size: 16px;
    line-height: 24px; }
    .banner-title-content__content p:last-child {
      margin-bottom: 0; }

.news-list {
  padding-top: 80px;
  padding-bottom: 120px; }
  @media (max-width: 1319.98px) {
    .news-list {
      padding-top: 40px;
      padding-bottom: 60px; } }
  .news-list .col-item {
    margin-bottom: 24px; }
  .news-list .pagination {
    margin-top: 64px; }
    @media (max-width: 1139.98px) {
      .news-list .pagination {
        margin-top: 10px; } }

.card-news {
  overflow: hidden; }
  .card-news__date {
    color: #002252;
    opacity: 0.5;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    margin-bottom: 6px; }
  .card-news__title {
    font-weight: 600;
    color: #053F93;
    font-size: 20px;
    line-height: 28px; }
  .card-news__link {
    display: block;
    overflow: hidden;
    margin-bottom: 20px; }
    .card-news__link img {
      width: 100%;
      height: auto;
      transform-origin: center;
      transition: all 250ms; }
  .card-news:hover img {
    transform: scale(1.2); }

.bloc-qsn {
  padding-top: 140px;
  padding-bottom: 140px;
  margin-bottom: 120px;
  position: relative; }
  @media (max-width: 1319.98px) {
    .bloc-qsn {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 60px; } }
  .bloc-qsn:before {
    content: "";
    width: 53%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #F5F5F5;
    width: 50%; }
    @media (max-width: 1319.98px) {
      .bloc-qsn:before {
        display: none; } }
  .bloc-qsn__title {
    color: #053F93;
    margin-bottom: 42px; }
  .bloc-qsn__content {
    font-weight: 300; }
  @media (max-width: 1319.98px) {
    .bloc-qsn .col-left {
      margin-bottom: 24px; } }
  .bloc-qsn .col-right {
    text-align: center; }
    .bloc-qsn .col-right img {
      max-width: 100%;
      height: auto; }

.bloc-keyfigures {
  background-color: #053F93;
  color: #FFFFFF;
  padding: 65px 0;
  padding-bottom: 25px; }
  @media (max-width: 1139.98px) {
    .bloc-keyfigures .col-left {
      margin-bottom: 24px; } }
  .bloc-keyfigures__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -35px;
    overflow: hidden; }
    @media (max-width: 1139.98px) {
      .bloc-keyfigures__list {
        margin: 0; } }
    .bloc-keyfigures__list__item {
      flex: 0 0 50%;
      padding: 0 35px;
      margin-bottom: 40px; }
      @media (max-width: 1139.98px) {
        .bloc-keyfigures__list__item {
          padding: 0px;
          flex: 0 0 100%; } }
      .bloc-keyfigures__list__item__title {
        font-weight: 600;
        font-size: 64px;
        line-height: 55px;
        margin-bottom: 26px; }
        @media (max-width: 1319.98px) {
          .bloc-keyfigures__list__item__title {
            font-size: 34px;
            line-height: 28px; } }
      .bloc-keyfigures__list__item__text {
        font-weight: 300;
        font-size: 14px;
        opacity: 0.8;
        line-height: 24px; }

.bloc-values {
  margin: 120px 0; }
  @media (max-width: 1319.98px) {
    .bloc-values {
      margin: 60px 0; } }
  .bloc-values__title {
    color: #053F93; }
  .bloc-values .row-top {
    margin-bottom: 60px; }
    @media (max-width: 1319.98px) {
      .bloc-values .row-top {
        margin-bottom: 30px; } }
  @media (max-width: 1319.98px) {
    .bloc-values .col-item {
      margin-bottom: 24px; }
      .bloc-values .col-item:last-child {
        margin-bottom: 0; } }
  .bloc-values__item__picto {
    min-height: 62px;
    margin-bottom: 30px; }
  .bloc-values__item__title {
    font-weight: 600;
    color: #FF3330;
    margin-bottom: 20px;
    font-size: 23px; }
    @media (max-width: 1319.98px) {
      .bloc-values__item__title {
        font-size: 20px; } }
  .bloc-values__item__text {
    color: rgba(0, 34, 82, 0.8);
    font-weight: 300; }

.bloc-steps {
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  margin-top: 120px;
  margin-bottom: 17px; }
  @media (max-width: 1319.98px) {
    .bloc-steps {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 60px; } }
  .bloc-steps:before {
    content: "";
    width: 53%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #F5F5F5;
    width: 50%; }
    @media (max-width: 1319.98px) {
      .bloc-steps:before {
        display: none; } }
  .bloc-steps .row {
    align-items: center; }
  .bloc-steps .col-left img {
    max-width: 100%;
    height: auto; }
  @media (max-width: 1319.98px) {
    .bloc-steps .col-left {
      margin-bottom: 24px; } }
  .bloc-steps__title {
    margin-bottom: 40px;
    color: #053F93; }
  .bloc-steps__text {
    color: rgba(0, 34, 82, 0.8);
    font-weight: 300;
    margin-bottom: 30px; }
  .bloc-steps__list li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 16px; }
    .bloc-steps__list li:last-child {
      margin-bottom: 0; }
  .bloc-steps__list svg {
    color: #002252;
    position: absolute;
    top: 0;
    left: 0; }
  .bloc-steps__list span {
    color: #002252;
    opacity: 0.8; }

.bloc-references {
  margin: 120px 0; }
  @media (max-width: 1319.98px) {
    .bloc-references {
      margin: 60px 0; } }
  .bloc-references .row-top {
    margin-bottom: 64px; }
    @media (max-width: 1319.98px) {
      .bloc-references .row-top {
        margin-bottom: 34px; } }
  .bloc-references__title {
    color: #053F93;
    margin-bottom: 0; }
  .bloc-references .col-item {
    margin-bottom: 24px; }
  @media (max-width: 1319.98px) {
    .bloc-references .col-title {
      margin-bottom: 24px; } }

.bloc-careers {
  padding-top: 140px;
  padding-bottom: 140px;
  position: relative; }
  @media (max-width: 1319.98px) {
    .bloc-careers {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .bloc-careers:before {
    content: "";
    width: 53%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #F5F5F5;
    width: 50%; }
    @media (max-width: 1319.98px) {
      .bloc-careers:before {
        display: none; } }
  .bloc-careers__title {
    color: #053F93;
    margin-bottom: 42px; }
  .bloc-careers__content {
    font-weight: 300; }
  @media (max-width: 1319.98px) {
    .bloc-careers .col-left {
      margin-bottom: 24px; } }
  .bloc-careers .col-right {
    text-align: center; }
    .bloc-careers .col-right img {
      max-width: 100%;
      height: auto; }
  .bloc-careers__btn {
    margin-top: 40px; }
    @media (max-width: 1319.98px) {
      .bloc-careers__btn {
        margin-top: 20px; } }

.card-references {
  display: block; }
  .card-references.is-link:hover .card-references__img img {
    transform: scale(1.1); }
  .card-references__img {
    margin-bottom: 30px;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden; }
    .card-references__img img {
      width: 100%;
      height: auto;
      transform-origin: center;
      transition: all 250ms; }
  .card-references__title {
    color: #053F93;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px; }
  .card-references__text {
    color: rgba(5, 63, 147, 0.8);
    font-weight: 300;
    font-size: 16px;
    line-height: 24px; }

.filters {
  margin-top: 40px;
  margin-bottom: 80px; }
  @media (max-width: 1319.98px) {
    .filters {
      margin-top: 20px;
      margin-bottom: 20px; } }
  .filters select {
    width: 100%; }
  @media (max-width: 1319.98px) {
    .filters .col-item {
      margin-bottom: 24px; } }
  .filters .container {
    position: relative; }
  .filters .row {
    transition: all 250ms cubic-bezier(0.19, 1, 0.22, 1); }
  .filters__overlay {
    color: #053F93;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none; }
    .filters__overlay svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%); }
  .filters.is-loading .row {
    filter: blur(5px);
    pointer-events: none; }
  .filters.is-loading .filters__overlay {
    display: block; }

.properties-list {
  margin-bottom: 120px; }
  @media (max-width: 1319.98px) {
    .properties-list {
      margin-bottom: 30px; } }
  .properties-list .col-item {
    margin-bottom: 40px; }
  .properties-list .pagination {
    margin-top: 34px; }

.bloc-encart {
  display: flex;
  flex-wrap: wrap; }
  .bloc-encart__left {
    width: 53.33%; }
    .bloc-encart__left__inner {
      height: 663px; }
      @media (max-width: 1139.98px) {
        .bloc-encart__left__inner {
          height: 300px; } }
    @media (max-width: 1139.98px) {
      .bloc-encart__left {
        width: 100%; } }
  .bloc-encart__right {
    width: 46.67%;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1139.98px) {
      .bloc-encart__right {
        width: 100%; } }
    .bloc-encart__right__top, .bloc-encart__right__bottom {
      height: 50%;
      width: 100%;
      background-size: 100% 100%;
      background-position: center;
      transition: all 250ms; }
      .bloc-encart__right__top:hover, .bloc-encart__right__bottom:hover {
        background-size: 110% 110%; }
      @media (max-width: 1139.98px) {
        .bloc-encart__right__top, .bloc-encart__right__bottom {
          height: 300px; } }
  .bloc-encart__item {
    position: relative;
    overflow: hidden; }
    .bloc-encart__item:hover .bloc-encart__item__bg {
      transform: scale(1.2); }
    .bloc-encart__item__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: all 250ms;
      transform-origin: center; }
    .bloc-encart__item__text {
      position: absolute;
      color: #FFFFFF;
      left: 35px;
      bottom: 35px;
      text-transform: uppercase; }
      @media (max-width: 1139.98px) {
        .bloc-encart__item__text {
          bottom: auto;
          top: 35px; } }
      .bloc-encart__item__text__suphead {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 8px; }
      .bloc-encart__item__text__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 0; }
    .bloc-encart__item__link {
      width: 50px;
      height: 50px;
      right: 35px;
      bottom: 35px;
      position: absolute;
      color: #FFFFFF;
      border: 1px solid #FF3330;
      border-radius: 50%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1); }
      .bloc-encart__item__link:hover {
        color: #FFFFFF;
        background-color: #FF3330; }
      .bloc-encart__item__link.is-blue-light {
        border-color: #41B5EF; }
        .bloc-encart__item__link.is-blue-light:hover {
          background-color: #41B5EF; }
      .bloc-encart__item__link svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }

.slider-properties-big {
  margin: 120px 0;
  overflow: hidden;
  padding-bottom: 10px; }
  @media (max-width: 1319.98px) {
    .slider-properties-big {
      margin: 60px 0; } }
  .slider-properties-big__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px; }
  .slider-properties-big__title {
    margin-bottom: 0; }
  .slider-properties-big__arrows {
    display: flex;
    align-items: center; }
    .slider-properties-big__arrows .arrow-button:first-child {
      margin-right: 24px; }
  .slider-properties-big .swiper-container {
    overflow: visible; }
  .slider-properties-big .swiper-wrapper {
    flex-wrap: nowrap; }
  .slider-properties-big__scrollbar {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 582px;
    position: relative; }
    .slider-properties-big__scrollbar:before {
      content: "";
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #002252;
      height: 1px; }
    .slider-properties-big__scrollbar .swiper-scrollbar-drag {
      background-color: #053F93;
      height: 5px;
      border-radius: 0px;
      cursor: grab; }

.breadcrumb {
  margin-top: 20px;
  margin-bottom: 40px; }
  @media (max-width: 1139.98px) {
    .breadcrumb {
      font-size: 12px; } }
  .breadcrumb a, .breadcrumb span {
    color: #053F93;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap; }
  .breadcrumb a {
    text-decoration: underline; }
  .breadcrumb .separator {
    width: 8px;
    height: 14px;
    background-image: url("../img/icon/chevron-blue.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 14px; }
    @media (max-width: 1139.98px) {
      .breadcrumb .separator {
        margin: 0 5px;
        width: 6px;
        height: 10px; } }
  .breadcrumb.is-white a, .breadcrumb.is-white span {
    color: #FFFFFF; }
  .breadcrumb.is-white .separator {
    background-image: url("../img/icon/chevron-white.svg"); }

/*
|--------------------
|     PAGE HOME
|--------------------
*/
/*
|--------------------
|      NEWS
|--------------------
*/
.page-article__back {
  color: #053F93;
  display: inline-flex;
  margin-top: 20px;
  margin-bottom: 50px;
  align-items: center; }
  .page-article__back svg {
    margin-right: 25px; }
  .page-article__back:hover {
    color: #053F93; }
    .page-article__back:hover span {
      text-decoration: underline; }

.page-article__date {
  color: #002252;
  opacity: 0.5;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px; }

.page-article__title {
  color: #053F93;
  margin-bottom: 14px; }

.page-article__intro {
  font-size: 18px;
  margin-bottom: 42px; }

.page-article .bloc-contact {
  margin-top: 80px; }

.load-more-container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .load-more-container .loader-container {
    display: none; }

/*
|--------------------
|      SINGLE
|--------------------
*/
.post-date {
  font-size: 12px;
  color: rgba(151, 151, 151, 0.6);
  text-transform: uppercase;
  font-weight: 400; }

.post-excerpt {
  font-size: 22px;
  color: rgba(20, 20, 20, 0.6);
  font-weight: 400; }

/*
|--------------------
|       Contact
|--------------------
*/
.page-contact {
  padding-top: 80px;
  padding-bottom: 60px; }
  @media (max-width: 1319.98px) {
    .page-contact {
      padding-top: 40px;
      padding-bottom: 30px; } }
  @media (max-width: 1319.98px) {
    .page-contact .col-left {
      margin-bottom: 24px; } }
  .page-contact__left__title {
    color: #053F93;
    margin-bottom: 30px; }
  .page-contact__left__list__item {
    padding-left: 55px;
    position: relative;
    margin-bottom: 38px; }
    .page-contact__left__list__item:last-child {
      margin-bottom: 0; }
    .page-contact__left__list__item__icon {
      position: absolute;
      top: 0;
      left: 0; }
    .page-contact__left__list__item__content a {
      color: rgba(0, 34, 82, 0.8); }
      .page-contact__left__list__item__content a:hover {
        text-decoration: underline; }
    .page-contact__left__list__item__content__title {
      font-weight: 700;
      line-height: 26px;
      color: rgba(0, 34, 82, 0.8); }
    .page-contact__left__list__item__content__text {
      color: rgba(0, 34, 82, 0.8); }
  .page-contact__center {
    position: relative; }
    .page-contact__center .gform_anchor {
      position: absolute;
      top: -150px; }
    .page-contact__center__title {
      color: #053F93;
      margin-bottom: 30px; }
    .page-contact__center__text {
      color: rgba(0, 34, 82, 0.8);
      margin-bottom: 20px; }

/*
|--------------------
|       404
|--------------------
*/
#page-404 {
  /*
    |
    | Section contact
    |------------------
    */ }
  #page-404 .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    color: #053F93; }
    #page-404 .section-content a {
      display: block;
      color: #053F93; }
    #page-404 .section-content .item-title {
      font-size: 8vw;
      font-weight: 300;
      line-height: 1; }

.page-cms__title {
  color: #053F93;
  text-transform: uppercase;
  margin-bottom: 48px; }

.page-cms__intro {
  margin-bottom: 40px; }

.expertise-nav {
  background-color: #FFFFFF;
  position: sticky;
  top: 113px;
  z-index: 98;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
  overflow-x: auto; }
  @media (max-width: 1139.98px) {
    .expertise-nav {
      top: 70px; } }
  .expertise-nav__inner ul {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .expertise-nav__inner ul li {
      padding: 25px 15px;
      cursor: pointer;
      font-size: 18px;
      line-height: 25px;
      color: #053F93;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
      position: relative;
      white-space: nowrap; }
      .expertise-nav__inner ul li:after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: #FF3330;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0; }
      .expertise-nav__inner ul li.is-active {
        color: #FF3330; }
        .expertise-nav__inner ul li.is-active:after {
          opacity: 1; }

.expertise-list__wrap {
  margin: 140px 0;
  position: relative; }
  @media (max-width: 1319.98px) {
    .expertise-list__wrap {
      margin: 0px 0; } }
  .expertise-list__wrap.is-first {
    margin-top: 0; }
  .expertise-list__wrap__anchor {
    position: absolute;
    top: -188px;
    height: 100%;
    visibility: hidden;
    pointer-events: none; }
    @media (max-width: 1319.98px) {
      .expertise-list__wrap__anchor {
        top: -98px;
        height: 100%; } }
  .expertise-list__wrap__title {
    margin-bottom: 40px; }
    @media (max-width: 1319.98px) {
      .expertise-list__wrap__title {
        margin-bottom: 40px; } }
  .expertise-list__wrap__content {
    color: rgba(5, 63, 147, 0.8);
    font-weight: 300; }
  .expertise-list__wrap__btn {
    margin-top: 40px; }
    @media (max-width: 1319.98px) {
      .expertise-list__wrap__btn {
        margin-top: 40px; } }
  @media (max-width: 1319.98px) {
    .expertise-list__wrap .col-text {
      order: 0;
      margin-bottom: 24px; } }
  .expertise-list__wrap .col-img img {
    max-width: 100%;
    height: auto; }
  @media (max-width: 1319.98px) {
    .expertise-list__wrap .col-img {
      order: 1; } }
  .expertise-list__wrap.reverse .expertise-list__top:before {
    right: auto;
    left: 0; }

.expertise-list__top {
  padding: 140px 0px;
  position: relative; }
  @media (max-width: 1319.98px) {
    .expertise-list__top {
      padding: 70px 0px;
      padding-bottom: 0; } }
  .expertise-list__top:before {
    content: "";
    width: 53%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #F5F5F5; }
    @media (max-width: 1319.98px) {
      .expertise-list__top:before {
        display: none; } }
  .expertise-list__top .row {
    align-items: center; }

.expertise-list__bottom {
  margin-top: 80px;
  overflow: hidden; }
  @media (max-width: 1319.98px) {
    .expertise-list__bottom {
      margin-top: 40px; } }
  @media (max-width: 1319.98px) {
    .expertise-list__bottom .row {
      touch-action: pan-y;
      flex-wrap: nowrap; } }

.page-search__top {
  padding-top: 38px;
  padding-bottom: 38px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.45); }
  .page-search__top__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.page-search__title {
  color: #053F93;
  text-transform: uppercase; }

.page-search__count {
  font-size: 18px;
  line-height: 25px;
  color: #053F93; }

.page-search__bottom__row .col-item {
  margin-bottom: 24px; }

.page-search__bottom__row:first-child {
  margin-top: 40px;
  margin-bottom: 80px; }

.page-search__bottom__row:last-child {
  margin-bottom: 120px; }

.page-search__bottom__row__title {
  color: #053F93;
  text-transform: uppercase;
  margin-bottom: 40px; }

/*
|--------------------
|      BUTTONS
|--------------------
*/
.arrow-button {
  position: relative;
  width: 50px;
  height: 50px;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #FF3330;
  border-radius: 50%;
  transition: all 250ms; }
  .arrow-button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  .arrow-button--down svg {
    transform: translateX(-50%) translateY(-50%) rotate(90deg); }
  .arrow-button--reverse svg {
    transform: translateX(-50%) translateY(-50%) rotate(-180deg); }
  .arrow-button--border-blue-light {
    border-color: #41B5EF; }
  .arrow-button--small {
    width: 36px;
    height: 36px; }
    .arrow-button--small svg {
      width: 15px;
      height: auto; }
  .arrow-button.c-blue-light:hover {
    background-color: #41B5EF;
    color: #FFFFFF; }
  .arrow-button.c-red:hover {
    background-color: #FF3330;
    color: #FFFFFF; }

/*
|--------------------
|  Smooth Scrollbar
|--------------------
*/
/*
|
| Scroll area
|--------------
*/
.scroll-area .scrollbar-track.scrollbar-track-y {
  width: 4px !important; }
  .scroll-area .scrollbar-track.scrollbar-track-y .scrollbar-thumb {
    width: 100% !important;
    background: #000000 !important; }

/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/
/*
|
| Reset form elements
|-------------------------
*/
.styled-select, select {
  background-color: #FFFFFF;
  background-image: url("../img/icon/bottom-blue.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: calc(100% - 30px) center;
  padding: 18px 50px 18px 23px;
  color: #002252;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid #053F93;
  cursor: pointer; }

.styled-input, input, textarea, .gform_wrapper.gform_wrapper.gravity-theme select, .gform_wrapper.gform_wrapper.gravity-theme textarea, .gform_wrapper.gform_wrapper.gravity-theme input[type="text"], .gform_wrapper.gform_wrapper.gravity-theme input[type="email"], .gform_wrapper.gform_wrapper.gravity-theme input[type="tel"], .gform_wrapper.gform_wrapper.gravity-theme input[type="number"] {
  padding: 18px 23px 18px 23px;
  color: #002252;
  font-size: 16px;
  line-height: 26px;
  border: 1px solid rgba(0, 34, 82, 0.5); }
  .styled-input::-webkit-input-placeholder, input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme select::-webkit-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme textarea::-webkit-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="text"]::-webkit-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="email"]::-webkit-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="tel"]::-webkit-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="number"]::-webkit-input-placeholder {
    color: rgba(0, 34, 82, 0.5); }
  .styled-input:-moz-placeholder, input:-moz-placeholder, textarea:-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme select:-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme textarea:-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input:-moz-placeholder[type="text"], .gform_wrapper.gform_wrapper.gravity-theme input:-moz-placeholder[type="email"], .gform_wrapper.gform_wrapper.gravity-theme input:-moz-placeholder[type="tel"], .gform_wrapper.gform_wrapper.gravity-theme input:-moz-placeholder[type="number"] {
    color: rgba(0, 34, 82, 0.5); }
  .styled-input::-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme select::-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme textarea::-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="text"]::-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="email"]::-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="tel"]::-moz-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input[type="number"]::-moz-placeholder {
    color: rgba(0, 34, 82, 0.5); }
  .styled-input:-ms-input-placeholder, input:-ms-input-placeholder, textarea:-ms-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme select:-ms-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme textarea:-ms-input-placeholder, .gform_wrapper.gform_wrapper.gravity-theme input:-ms-input-placeholder[type="text"], .gform_wrapper.gform_wrapper.gravity-theme input:-ms-input-placeholder[type="email"], .gform_wrapper.gform_wrapper.gravity-theme input:-ms-input-placeholder[type="tel"], .gform_wrapper.gform_wrapper.gravity-theme input:-ms-input-placeholder[type="number"] {
    color: rgba(0, 34, 82, 0.5); }

input, textarea, select {
  -webkit-appearance: none;
  border-radius: 0; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

.gform_wrapper.gform_wrapper.gravity-theme .gform_validation_errors {
  margin: 20px 0;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  background-color: rgba(255, 51, 48, 0.2);
  padding: 6px 15px; }
  .gform_wrapper.gform_wrapper.gravity-theme .gform_validation_errors .gform-icon.gform-icon--close {
    display: none; }
  .gform_wrapper.gform_wrapper.gravity-theme .gform_validation_errors h2 {
    color: #FF3330;
    font-size: 16px;
    line-height: 26px; }

.gform_wrapper.gform_wrapper.gravity-theme .ginput_recaptcha {
  display: flex;
  justify-content: flex-end; }

.gform_wrapper.gform_wrapper.gravity-theme .gform_footer {
  text-align: right; }

.gform_wrapper.gform_wrapper.gravity-theme input[type="checkbox"] {
  width: 26px;
  height: 26px;
  margin-right: 20px; }

.gform_wrapper.gform_wrapper.gravity-theme input[type="submit"] {
  background-color: #FF3330;
  border: 1px solid #FF3330;
  color: #FFFFFF;
  padding: 16px 56px 16px 20px;
  background-image: url("../img/icon/arrow-white.svg");
  background-size: 15px;
  background-position: calc(100% - 14px) center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 250ms; }
  .gform_wrapper.gform_wrapper.gravity-theme input[type="submit"]:hover {
    background-image: url("../img/icon/arrow-red.svg");
    background-color: #FFFFFF;
    color: #FF3330; }

.gform_wrapper.gform_wrapper.gravity-theme .gchoice {
  margin-bottom: 10px; }
  .gform_wrapper.gform_wrapper.gravity-theme .gchoice:last-child {
    margin-bottom: 0; }

.gform_wrapper.gform_wrapper.gravity-theme .ginput_container_consent, .gform_wrapper.gform_wrapper.gravity-theme .gchoice {
  position: relative;
  display: flex;
  align-items: flex-start; }
  .gform_wrapper.gform_wrapper.gravity-theme .ginput_container_consent input, .gform_wrapper.gform_wrapper.gravity-theme .gchoice input {
    flex-shrink: 0;
    position: relative;
    top: 0px;
    cursor: pointer; }
  .gform_wrapper.gform_wrapper.gravity-theme .ginput_container_consent label, .gform_wrapper.gform_wrapper.gravity-theme .gchoice label {
    cursor: pointer;
    position: relative;
    color: #002252;
    font-size: 16px;
    line-height: 26px; }
    .gform_wrapper.gform_wrapper.gravity-theme .ginput_container_consent label:before, .gform_wrapper.gform_wrapper.gravity-theme .gchoice label:before {
      content: "✓";
      position: absolute;
      left: -40px;
      top: 0px;
      color: #053F93;
      display: none; }
  .gform_wrapper.gform_wrapper.gravity-theme .ginput_container_consent input:checked + label:before, .gform_wrapper.gform_wrapper.gravity-theme .gchoice input:checked + label:before {
    display: block; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield_label {
  font-weight: 500;
  color: #002252;
  text-transform: uppercase; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield_required_text {
  display: none; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield .gfield_validation_message {
  display: none; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield .gfield_consent_label a {
  text-decoration: underline; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield.gfield_error .gfield_label, .gform_wrapper.gform_wrapper.gravity-theme .gfield.gfield_error .gfield_consent_label {
  color: #FF3330; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield.gfield_error input, .gform_wrapper.gform_wrapper.gravity-theme .gfield.gfield_error textarea {
  color: #FF3330;
  border-color: #FF3330; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield.has-val .gfield_label, .gform_wrapper.gform_wrapper.gravity-theme .gfield.has-val .gfield_consent_label {
  color: #053F93; }

.gform_wrapper.gform_wrapper.gravity-theme .gfield.has-val input, .gform_wrapper.gform_wrapper.gravity-theme .gfield.has-val textarea {
  color: #053F93;
  border-color: #053F93; }

.validation_error {
  margin-bottom: 30px;
  color: #BB0B0B !important; }

#gform_ajax_spinner_1 {
  margin-left: 10px;
  display: inline-block;
  width: 50px;
  height: 50px; }

/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  display: none; }
  .page-loader.active {
    display: flex; }
  .page-loader .item-content {
    width: 100%;
    color: #141414;
    text-align: center; }
  .page-loader .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto; }
    .page-loader .item-loadbar .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: #141414;
      animation: loadbar 1.2s cubic-bezier(0.92, 0, 0.09, 1);
      transform-origin: left top; }

@keyframes loadbar {
  from {
    transform: scaleX(0); }
  to {
    transform: scaleX(0.7); } }

[data-splittext] {
  opacity: 0;
  overflow: hidden; }
  [data-splittext].split-ready {
    opacity: 1; }

[data-kira-item="splitline"] .item-line {
  overflow: hidden; }

[data-kira-item^="fadeIn"] {
  opacity: 0; }

[data-kira-item^="fadeInLeft.stagger"],
[data-kira-item^="fadeInUp.stagger"] {
  opacity: 1; }
  [data-kira-item^="fadeInLeft.stagger"] [data-stagger-item],
  [data-kira-item^="fadeInUp.stagger"] [data-stagger-item] {
    opacity: 0; }

.item-char {
  will-change: auto; }
