.flexible-video{
  &__inner{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      z-index: 0;
    }
    &__cover{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: 1;
      &.is-hidden{
        display: none;
      }
      &__play{
        color: $white;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
        width: 124px;
        height: 124px;
        border-radius: 50%;
        border:1px solid $white;
        cursor: pointer;
      }
    }
  }
}