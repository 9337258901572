.card-references{
  display: block;
  &.is-link{
    &:hover{
      .card-references__img{
        img{
          transform: scale(1.1);
        }
      }
    }
  }
  &__img{
    margin-bottom: 30px;
    transition: all 500ms $easeSmooth;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
      transform-origin: center;
      transition: all 250ms;
    }
  }
  &__title{
    color: $blue;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  &__text{
    color: rgba($blue,0.8);
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
}