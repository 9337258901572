.bloc-steps{
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  margin-top: 120px;
  margin-bottom: 17px;
  @include media-breakpoint-down(lg){
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 60px;
  }
  &:before{
    content:"";
    width: 53%;
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    background-color: $grey-alt;
    @include media-breakpoint-down(xl){
      width: 50%;
    }
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  .row{
    align-items: center;
  }
  .col-left{
    img{
      max-width: 100%;
      height: auto;
    }
    @include media-breakpoint-down(lg){
      margin-bottom: $grid-gutter-width;
    }
  }
  &__title{
    margin-bottom: 40px;
    color:$blue;
  }
  &__text{
    color:rgba($blue-dark, 0.8);
    font-weight: 300;
    margin-bottom: 30px;
  }
  &__list{
    li{
      padding-left: 40px;
      position:relative;
      margin-bottom: 16px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    svg{
      color: $blue-dark;
      position: absolute;
      top:0;
      left:0;
    }
    span{
      color: $blue-dark;
      opacity: 0.8;
    }
  }
}