.related-properties{
  margin: 120px 0;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
  }
  .col-item{
    @include media-breakpoint-down(md){
      margin-bottom: $grid-gutter-width;
    }
  }
  &__title{
    color: $blue;
    margin-bottom: 42px;
    text-transform: uppercase;
  }
}