.bloc-keyfigures{
  background-color: $blue;
  color:$white;
  padding: 65px 0;
  padding-bottom: 25px;
  .col-left{
    @include media-breakpoint-down(md){
      margin-bottom: $grid-gutter-width;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    margin:0 -35px;
    overflow: hidden;
    @include media-breakpoint-down(md){
      margin: 0;
    }
    &__item{
      flex:0 0 50%;
      padding: 0 35px;
      margin-bottom: 40px;
      @include media-breakpoint-down(md){
        padding: 0px;
        flex:0 0 100%;
      }
      &__title{
        font-weight: 600;
        font-size: 64px;
        line-height: 55px;
        margin-bottom: 26px;
        @include media-breakpoint-down(lg){
          font-size: 34px;
          line-height: 28px;
        }
      }
      &__text{
        font-weight: 300;
        font-size: 14px;
        opacity: 0.8;
        line-height: 24px;
      }
    }
  }
}