.expertise-nav{
  background-color: $white;
  position: sticky;
  top:$headerHeight;
  z-index: 98;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.18);
  overflow-x: auto;
  @include media-breakpoint-down(md){
    top: $headerHeightSm;
  }
  &__inner{
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(md){
      }
      li{
        padding: 25px 15px;
        cursor: pointer;
        font-size: 18px;
        line-height: 25px;
        color: $blue;
        transition: all 500ms $easeSmooth;
        position: relative;
        white-space: nowrap;
        &:after{
          content: "";
          width: 100%;
          height: 4px;
          background-color: $red;
          position: absolute;
          bottom: 0;
          left:0;
          opacity: 0;
        }
        &.is-active{
          color:$red;
          &:after{
            opacity: 1;
          }
        }
      }
    }
  }
}
.expertise-list{
  &__wrap{
    margin: 140px 0;
    position: relative;
    @include media-breakpoint-down(lg){
      margin: 0px 0;
    }
    &.is-first{
      margin-top: 0;
    }
    &__anchor{
      position: absolute;
      top:-188px;
      height: 100%;
      visibility: hidden;
      pointer-events: none;
      @include media-breakpoint-down(lg){
        top:-98px;
        height: 100%;
      }
    }

    &__title{
      margin-bottom: 40px;
      @include media-breakpoint-down(lg){
        margin-bottom: 40px;
      }
    }
    &__content{
      color: rgba($blue, 0.8);
      font-weight: 300;
    }
    &__btn{
      margin-top: 40px;
      @include media-breakpoint-down(lg){
        margin-top: 40px;
      }
    }
    .col-text{
      @include media-breakpoint-down(lg){
        order: 0;
        margin-bottom: $grid-gutter-width;
      }
    }
    .col-img{
      img{
        max-width: 100%;
        height: auto;
      }
      @include media-breakpoint-down(lg){
        order: 1;
      }
    }
    &.reverse{
      .expertise-list__top{
        &:before{
          right:auto;
          left:0;
        }
      }
    }
  }
  &__top{
    padding: 140px 0px;
    position: relative;
    @include media-breakpoint-down(lg){
      padding: 70px 0px;
      padding-bottom: 0;
    }
    &:before{
      content:"";
      width: 53%;
      position: absolute;
      top:0;
      right:0;
      height: 100%;
      background-color: $grey-alt;
      @include media-breakpoint-down(lg){
        display: none;
      }
    }
    .row{
      align-items: center;
    }
  }
  &__bottom{
    margin-top: 80px;
    overflow: hidden;
    @include media-breakpoint-down(lg){
      margin-top: 40px;
    }
    .row{
      @include media-breakpoint-down(lg){
        touch-action: pan-y;
        flex-wrap: nowrap;
      }
    }
  }
}