.card-property{
  display: block;
  &:hover &{
    &__img{
      img{
        transform: scale(1.1);
      }
    }
  }
  &.is-red{
    &:hover{
      .card-property__details__title{
        color: $red;
      }
    }
  }
  &.is-blue-light{
    &:hover{
      .card-property__details__title{
        color: $blue-light;
      }
    }
  }
  &__img{
    position: relative;
    margin-bottom: 24px;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
      transform-origin: center;
      transition: all 250ms;
    }
    &__sold{
      position: absolute;
      top:16px;
      left:16px;
      color:$white;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-radius: 3px;
      padding: 3px 11px;
    }
  }
  &__details{
    &__title{
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: $blue;
      margin-bottom: 16px;
      transition: all 250ms;
    }
    &__misc{
      display: flex;
      align-items: center;
      color: $blue-dark;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      .sep{
        width: 1px;
        height: 18px;
        margin: 0 14px;
        background-color: $red;
      }
      &__surface, &__price{
        opacity: 0.5;
      }
    }
  }
}