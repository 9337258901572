.page-search{
  &__top{
    padding-top: 38px;
    padding-bottom: 38px;
    border-bottom: 1px solid rgba($grey, 0.45);
    &__inner{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__title{
    color: $blue;
    @extend .title-2;
    text-transform: uppercase;
  }
  &__count{
    font-size: 18px;
    line-height: 25px;
    color: $blue;
  }
  &__bottom{
    &__row{
      .col-item{
        margin-bottom: $grid-gutter-width;
      }
      &:first-child{
        margin-top: 40px;
        margin-bottom: 80px;
      }
      &:last-child{
        margin-bottom: 120px;
      }
      &__title{
        color: $blue;
        text-transform: uppercase;
        margin-bottom: 40px;
      }
    }
  }
}