.slider-properties{
  margin: 120px 0;
  overflow: hidden;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
  }
  .swiper-container{
    overflow: visible;
  }
  &__top{
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title{
    color:$blue;
    text-transform: uppercase;
  }
  &__arrows{
    display: flex;
    align-items: center;
    .arrow-button{
      &:first-child{
        margin-right: 24px;
      }
    }
  }
}