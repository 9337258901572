.filters{
  margin-top: 40px;
  margin-bottom: 80px;
  @include media-breakpoint-down(lg){
    margin-top: 20px;
    margin-bottom: 20px;
  }
  select{
    width: 100%;
  }
  .col-item{
    @include media-breakpoint-down(lg){
      margin-bottom: $grid-gutter-width;
    }
  }
  .container{
    position: relative;
  }
  .row{
    transition: all 250ms $easeSmooth;
  }
  &__overlay{
    color: $blue;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: none;
    svg{
      position: absolute;
      top:50%;
      left:50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  &.is-loading{
    .row{
      filter:blur(5px);
      pointer-events: none;
    }
    .filters__overlay{
      display: block;
    }
  }
}