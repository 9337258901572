%Poppins{
  font-family: 'Poppins', sans-serif;
}

$poppins:'Poppins', sans-serif;


.title-1{
  @extend %Poppins;
  font-size: 48px;
  line-height: 55px;
  font-weight: 600;
  @include media-breakpoint-down(md){
    font-size: 38px;
    line-height: 45px;
  }
}
.title-2{
  @extend %Poppins;
  font-size: 32px;
  line-height: 45px;
  font-weight: 600;
  @include media-breakpoint-down(md){
    font-size: 22px;
    line-height: 35px;
  }
}
.title-3{
  font-size: 24px;
  line-height: 33px;
  @include media-breakpoint-down(md){
    font-size: 18px;
    line-height: 27px;
  }
}

.text-light{
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  p{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

@for $i from 1 through 3 {
  h#{$i} {
    @extend .title-#{$i};
  }
}

.bar-after{
  position: relative;
  padding-bottom: 18px;
  &:after{
    content:"";
    background-color: $red;
    width: 88px;
    height: 11px;
    position: absolute;
    bottom:0;
    left:0;
  }
  &--large{
    &:after{
      width: 165px;
    }
  }
  &--center{
    &:after{
      left:50%;
      transform: translateX(-50%);
    }
  }
  &--space-large{
    padding-bottom: 27px;
  }
  &--blue-light{
    &:after{
      background-color: $blue-light;
    }
  }
}

.link-btn{
  background-color: $red;
  color: $white;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  padding: 16px 20px;
  line-height: 23px;
  text-transform: uppercase;
  transition: all 500ms $easeSmooth;
  border:1px solid $red;
  svg{
    margin-left: 27px;
    max-width: 15px;
    height: auto;
  }
  &:hover{
    color: $red;
    background-color: $white;
  }
}

.link-styled{
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color:$blue;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  transition: all 500ms $easeSmooth;
  &:hover{
    color:red;
    text-decoration: none;
  }
  &:before{
    content:"";
    width: 22px;
    height: 1px;
    background-color: $red;
    margin-right: 12px;
  }
}

.title-subhead{
  color:$blue;
  text-transform: uppercase;
  line-height: 0;
  display: inline-flex;
  flex-flow: column;
  &__main{
    @extend .title-2;
    line-height: 40px;
    margin-bottom: 10px;
  }
  &__sub{
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    &:before{
      content:"";
      background-color: $red;
      width: 85px;
      height: 10px;
      margin-right: 4px;
    }
  }
  &--blue-light &{
    &__sub{
      &:before{
        background-color: $blue-light;
      }
    }
  }
}