/*
|--------------------
|      NEWS
|--------------------
*/
.page-article{
    &__back{
        color: $blue;
        display: inline-flex;
        margin-top: 20px;
        margin-bottom: 50px;
        align-items: center;
        svg{
            margin-right: 25px;
        }
        &:hover{
            color: $blue;
            span{
                text-decoration: underline;
            }
        }
    }
    &__date{
        color: $blue-dark;
        opacity: 0.5;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
    }
    &__title{
        color: $blue;
        margin-bottom: 14px;
        @extend .title-2;
    }
    &__intro{
        font-size: 18px;
        margin-bottom: 42px;
    }
    .bloc-contact{
        margin-top: 80px;
    }
}
.load-more-container {

    min-height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    .loader-container {
        display: none;
    }
}

