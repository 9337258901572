.slider-properties-big{
  margin: 120px 0;
  overflow: hidden;
  padding-bottom: 10px;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
  }
  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__title{
    margin-bottom: 0;
  }
  &__arrows{
    display: flex;
    align-items: center;
    .arrow-button{
      &:first-child{
        margin-right: 24px;
      }
    }
  }
  .swiper-container{
    overflow: visible;
  }
  .swiper-wrapper{
    flex-wrap: nowrap;
  }
  &__scrollbar{
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 582px;
    position: relative;
    &:before{
      content:"";
      width: 100%;
      position: absolute;
      top:50%;
      left:0;
      transform: translateY(-50%);
      background-color: $blue-dark;
      height: 1px;
    }
    .swiper-scrollbar-drag{
      background-color: $blue;
      height: 5px;
      border-radius: 0px;
      cursor: grab;
    }
  }
}