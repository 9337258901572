.banner-home{
  height: calc(100vh - #{$headerHeight});
  background-size: cover;
  background-position: center;
  position: relative;
  @include media-breakpoint-down(md){
    height: calc(100vh - #{$headerHeightSm});
  }
  &:before{
    content:"";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(134,134,134,0.00) 36%, rgba($black, 0.5) 90%);
  }
  &__inner{
    position: absolute;
    padding:0 $grid-gutter-width / 2;
    left:0;
    bottom: 27px;
    width: 100%;
    text-align: center;
    color:$white;
    @include media-breakpoint-down(sm){
      bottom: 127px;
    }
  }
  &__title{
    text-transform: uppercase;
    margin-bottom: 42px;
  }
}