/*
|--------------------
|       Contact
|--------------------
*/
.page-contact{
    padding-top: 80px;
    padding-bottom: 60px;
    @include media-breakpoint-down(lg){
        padding-top: 40px;
        padding-bottom: 30px;
    }
    .col-left{
        @include media-breakpoint-down(lg){
            margin-bottom: $grid-gutter-width;
        }
    }
    &__left{
        &__title{
            color: $blue;
            margin-bottom: 30px;
        }
        &__list{
            &__item{
                padding-left: 55px;
                position: relative;
                margin-bottom: 38px;
                &:last-child{
                    margin-bottom: 0;
                }
                &__icon{
                    position: absolute;
                    top:0;
                    left:0;
                }
                &__content{
                    a{
                        color:rgba($blue-dark, 0.8);
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                    &__title{
                        font-weight: 700;
                        line-height:26px;
                        color:rgba($blue-dark, 0.8);
                    }
                    &__text{
                        color:rgba($blue-dark, 0.8);
                    }
                }
            }
        }
    }
    &__center{
        position: relative;
        .gform_anchor{
            position: absolute;
            top:-150px;
        }
        &__title{
            color: $blue;
            margin-bottom: 30px;
        }
        &__text{
            color: rgba($blue-dark, 0.8);
            margin-bottom: 20px;
        }
    }
}