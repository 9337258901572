.bloc-qsn{
  padding-top: 140px;
  padding-bottom: 140px;
  margin-bottom: 120px;
  position: relative;
  @include media-breakpoint-down(lg){
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 60px;
  }
  &:before{
    content:"";
    width: 53%;
    position: absolute;
    top:0;
    right:0;
    height: 100%;
    background-color: $grey-alt;
    @include media-breakpoint-down(xl){
      width: 50%;
    }
    @include media-breakpoint-down(lg){
      display: none;
    }
  }
  &__title{
    color:$blue;
    margin-bottom: 42px;
  }
  &__content{
    font-weight: 300;
  }
  .col-left{
    @include media-breakpoint-down(lg){
      margin-bottom: $grid-gutter-width;
    }
  }
  .col-right{
    text-align: center;
    img{
      max-width: 100%;
      height: auto;
    }
  }
}