.card-news{
  overflow: hidden;
  &__date{
    color: $blue-dark;
    opacity: 0.5;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  &__title{
    font-weight: 600;
    color: $blue;
    font-size: 20px;
    line-height: 28px;
  }
  &__link{
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
    img{
      width: 100%;
      height: auto;
      transform-origin: center;
      transition: all 250ms;
    }
  }
  &:hover{
    img{
      transform: scale(1.2);
    }
  }
}