.bloc-values{
  margin: 120px 0;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
  }
  &__title{
    color: $blue;
  }
  .row-top{
    margin-bottom: 60px;
    @include media-breakpoint-down(lg){
      margin-bottom: 30px;
    }
  }
  .col-item{
    @include media-breakpoint-down(lg){
      margin-bottom:$grid-gutter-width;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  &__item{
    &__picto{
      min-height: 62px;
      margin-bottom: 30px;
    }
    &__title{
      @extend .title-3;
      font-weight: 600;
      color: $red;
      margin-bottom: 20px;
      font-size: 23px;
      @include media-breakpoint-down(lg){
        font-size: 20px;
      }
    }
    &__text{
      color: rgba($blue-dark, 0.8);
      font-weight: 300;
    }
  }
}