.bloc-encart{
  display: flex;
  flex-wrap: wrap;
  &__left{
    width: 53.33%;
    &__inner{
      height: 663px;
      @include media-breakpoint-down(md){
        height: 300px;
      }
    }
    @include media-breakpoint-down(md){
      width: 100%;
    }
  }
  &__right{
    width: 46.67%;
    display: flex;
    flex-wrap: wrap;
    @include media-breakpoint-down(md){
      width: 100%;
    }
    &__top, &__bottom{
      height: 50%;
      width: 100%;
      background-size: 100% 100%;
      background-position: center;
      transition: all 250ms;
      &:hover{
        background-size: 110% 110%;
      }
      @include media-breakpoint-down(md){
        height: 300px;
      }
    }
  }
  &__item{
    position: relative;
    overflow: hidden;
    &:hover &{
      &__bg{
        transform: scale(1.2);
      }
    }
    &__bg{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: all 250ms;
      transform-origin: center;
    }
    &__text{
      position: absolute;
      color: $white;
      left:35px;
      bottom: 35px;
      text-transform: uppercase;
      @include media-breakpoint-down(md){
        bottom: auto;
        top:35px;
      }
      &__suphead{
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 8px;
      }
      &__title{
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 0;
      }
    }
    &__link{
      width: 50px;
      height: 50px;
      right: 35px;
      bottom: 35px;
      position: absolute;
      color: $white;
      border:1px solid $red;
      border-radius: 50%;
      transition: all 500ms $easeSmooth;
      &:hover{
        color: $white;
        background-color: $red;
      }
      &.is-blue-light{
        border-color: $blue-light;
        &:hover{
          background-color: $blue-light;
        }
      }
      svg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}