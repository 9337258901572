/*
|--------------------
|      FOOTER
|--------------------
*/
.footer{
  &__top{
    padding-top: 72px;
    padding-bottom: 37px;
    @include media-breakpoint-down(md){
      padding-top: 32px;
      padding-bottom: 15px;
      border-top: 1px solid $red;
    }
  }
  &__bottom{
    border-top: 1px solid $red;
    padding-top: 18px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 20px;
    color:$blue;
    a{
      color:$blue;
    }
    &__left{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include media-breakpoint-down(sm){
        text-align: center;
        justify-content: center;
        width: 100%;
      }
      .sep{
        width: 1px;
        height: 20px;
        background-color: $red;
        margin: 0 15px;
        @include media-breakpoint-down(sm){
          display: none;
        }
      }
      a, #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings{
        color:$blue;
        font-size: 14px;
        line-height: 20px;
        display: inline-flex;
        align-items: center;
        position: relative;
        border:0;
        transition: none;
        padding: 0;
        font-weight: 300;
        @include media-breakpoint-down(sm){
          width: 100%;
          margin-top: 15px;
          justify-content: center;
        }
        &:after{
          content:"";
          position: absolute;
          bottom:-5px;
          left:50%;
          height: 1px;
          background-color: $red;
          transform: translateX(-50%);
          width: 100%;
          opacity: 0;
          transition: all 500ms $easeSmooth;
        }
        &:hover{
          background-color: transparent;
          color:$blue;
          &:after{
            opacity: 1;
          }
        }
      }
    }
    &__right{
      @include media-breakpoint-down(md){
        width: 100%;
        margin-top: 10px;
      }
      @include media-breakpoint-down(sm){
        text-align: center;
      }
      a{
        position: relative;
        display: inline-flex;
        &:after{
          content:"";
          position: absolute;
          bottom:-5px;
          left:50%;
          height: 1px;
          background-color: $red;
          transform: translateX(-50%);
          width: 100%;
        }
      }
    }
  }
  &__logo{
    img{
      max-width: 100%;
      min-width: 250px;
      height: auto;
    }
  }
  .col-logo{
    @include media-breakpoint-down(sm){
      margin-bottom: 30px;
    }
  }
  &__menu{
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
    @include media-breakpoint-down(xs){
      flex-wrap: wrap;
    }
    &__left, &__center, &__right{
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-down(xs){
        width: 100%;
      }
    }
    a{
      margin-bottom: 28px;
      color: $blue;
      position: relative;
      &:after{
        content:"";
        position: absolute;
        bottom:-5px;
        left:50%;
        height: 1px;
        background-color: $red;
        transform: translateX(-50%);
        width: 100%;
        opacity: 0;
        transition: all 500ms $easeSmooth;
      }
      &:hover{
        &:after{
          opacity: 1;
        }
      }
    }
  }
  &__social{
    display: inline-flex;
    align-items: center;
    svg{
      margin-right: 13px;
    }
  }
}