/*
|--------------------
|      BUTTONS
|--------------------
*/
.arrow-button{
  position: relative;
  width: 50px;
  height: 50px;
  appearance: none;
  cursor: pointer;
  background-color: transparent;
  border:1px solid $red;
  border-radius: 50%;
  transition: all 250ms;
  svg{
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
  }
  &--down{
    svg{
      transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
  }
  &--reverse{
    svg{
      transform: translateX(-50%) translateY(-50%) rotate(-180deg);
    }
  }
  &--border-blue-light{
    border-color: $blue-light;
  }
  &--small{
    width: 36px;
    height: 36px;
    svg{
      width: 15px;
      height: auto;
    }
  }
  &.c-blue-light{
    &:hover{
      background-color: $blue-light;
      color: $white;
    }
  }
  &.c-red{
    &:hover{
      background-color: $red;
      color: $white;
    }
  }
}