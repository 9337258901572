.cards-list-home{
  margin: 130px 0;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
  }
  &__card{
    display: flex;
    flex-flow: column;
    width: 100%;
    border:1px solid rgba($blue,0.2);
    padding: 66px 20px 40px 20px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    &__picto{
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      height: 70px;
      img{
        max-height: 100%;
      }
    }
    &__title{
      text-transform: uppercase;
      color:$red;
      margin-bottom: 15px;
    }
    &__text{
      margin-bottom: 48px;
    }
    .link-styled{
      margin-top: auto;
    }
  }
  .col-item{
    margin-bottom: $grid-gutter-width;
  }
}