/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/



/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card{

    .custom-card-link {
        display: block;
    }

    /*
    * Card img
    */
    .card-img-container {
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        .card-img {
            position: absolute;

            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            transition: transform 2s $easeOutExpo;
            will-change: transform;
        }
    }

    /*
    * Card content
    */
    .card-content {

        .date {
            color: rgba($very-dark-grey, 0.6);
        }
    }

    /*
    * Hover state
    */
    &:hover {

        .card-img-container {
    
            .card-img {
                transform: scale(1.05);
            }
        }
    }
    
    
}


/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
    display: none;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 0;

    .item-close {
        font-size: 24px;
        cursor: pointer;
    }


    @include media-breakpoint-down(sm) {
        padding: 20px 0;
        font-size: 12px;
    }

    @include media-breakpoint-down(xs) {
        padding: 10px 0 20px 0;

        .item-close {
            font-size: 18px;
        }
    }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
    li {
        display: inline-block;

        a {
            transition: all 0.3s ease-out;
            padding: 0 8px;

            &:hover {
                color: $grey;
            }
        }

        .current {
            padding: 0 8px;
            font-weight: 800;
            color: $grey;
        }
    }
}


/*
|
| CMS
|------
*/
.cms{

    td{
        border:1px solid$blue;
    }
    span{
        display: inline;
    }

    h1, h2, h3, h4, h5, h6{
        margin-bottom: 30px;
    }

    h1{
        @extend .title-xxl;
    }
    h2 {
        @extend .title-xl;
    }
    h3 {
        @extend .title-lg;
    }
    h4 {
        @extend .title-md;
    }
    h5 {
        @extend .title-sm;
    }
    h6 {
        @extend .title-xs;
    }

    img{
        display: block;
        width: 100%;
        height: auto;
    }

    strong{
        font-weight: 800;
    }

    p{
        margin-bottom: 20px;
    }

    &.cms-no-break{
        p{
            margin-bottom: 0;
        }
    }

    blockquote {
        position: relative;
        
        width: 80%;
        
        margin-right: 0;
        margin-left: auto;
        padding: 130px 0;

        font-size: 30px;
        line-height: 1.5em;
        font-weight: 300;

        @include media-breakpoint-down(md){
            margin: auto;
        }

        &:before {
            content: "";

            position: absolute;
            
            background-image: url('../img/icon/quote-left.svg');
            
            width: 50px;
            height: 50px;
            

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(-60px) translateY(-30px);
        }

        &:after {
            content: "";

            position: absolute;
            right: 0;
            
            background-image: url('../img/icon/quote-right.svg');
            
            width: 50px;
            height: 50px;
            

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;

            transform: translateX(0) translateY(-50px);

            @include media-breakpoint-down(md){
                transform: translateX(60px) translateY(-50px);
            }
        }
    }
}

.rte{
    color: rgba($blue, 0.8);
    font-size: 16px;
    line-height: 26px;
    .section{
        padding: 0 !important;
    }
    td{
        border:1px solid $blue;
        padding: 5px;
    }
    h2{
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        text-transform: uppercase;
    }
    h3{
        font-weight: 500;
        font-size: 22px;
        line-height: 24px;
        text-transform: uppercase;
    }
    h4{
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        text-transform: uppercase;
    }
    h5{
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-transform: uppercase;
    }
    p, ul{
        margin-top: 1rem;
        margin-bottom: 1rem;
        &:first-child{
            margin-top: 0;
        }
    }
    > p, > table{
        &:last-child{
            margin-bottom: 0;
        }
    }
    table{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    ul{
        padding-left: 1rem;
        li{
            list-style-type: disc;
        }
    }
}
.section-flexible{
    margin: 40px 0;
}

/*
|
| Page offset
|--------------
*/
.page-offset {
    padding-top: $headerHeight;

    @include media-breakpoint-down(md) {
        padding-top: $headerHeightSm;
    }
}


/*
|
| Custom Loader
|---------------
*/
.custom-loader {
    width: 100px;
    display: inline-block;

    &.cl-center {
        margin-left: auto;
        margin-right: auto;
    }

    svg {
        display: inline-block;
        width: 100%;
        height: auto;
        fill: $black;

        circle {
            stroke: $black;
        }
    }

    &.ajax-loader {
        display: none;
    }
}

.pagination{
    &__inner{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    li{
        margin: 0 5px;
        a,span{
            color: $blue;
            font-size: 16px;
            line-height: 16px;
            position: relative;
            &:after{
                content: "";
                position: absolute;
                left:50%;
                bottom:-5px;
                min-width: 18px;
                width: calc(100% + 10px);
                transform: translateX(-50%);
                background-color: $red;
                height: 1px;
                display: none;
            }
            &:hover{
                &:after{
                    display: block;
                }
            }
        }
        span{
            &:after{
                display: block;
            }
        }
    }
}