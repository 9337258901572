.banner-title-content{
  position: relative;
  background-size: cover;
  background-position: center;
  min-height: 425px;
  @include media-breakpoint-down(lg){
    min-height: 0;
  }
  &:before{
    content:'';
    background-color: $black;
    opacity: 0.2;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
  .container{
    padding-top: 112px;
    padding-bottom: 88px;
    position: relative;
    color:$white;
    .breadcrumb{
      position: absolute;
      top:21px;
      left:$grid-gutter-width / 2;
    }
  }
  &__title{
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  &__content{
    font-size: 16px;
    line-height: 24px;
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}