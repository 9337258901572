.intro-home{
  margin-top: 120px;
  margin-bottom: 120px;
  @include media-breakpoint-down(lg){
    margin-top: 60px;
    margin-bottom: 60px;
  }
  &__title{
    text-transform: uppercase;
  }
  &__text{
    margin-bottom: 42px;
  }
  .col-left{
    @include media-breakpoint-down(lg){
      margin-bottom: 24px;
    }
  }
}