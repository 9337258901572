/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
.styled-select{
    background-color: $white;
    background-image: url('../img/icon/bottom-blue.svg');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: calc(100% - 30px) center;
    padding: 18px 50px 18px 23px;
    color:$blue-dark;
    font-size: 16px;
    line-height: 26px;
    border:1px solid $blue;
    cursor: pointer;
}
.styled-input{
    padding: 18px 23px 18px 23px;
    color:$blue-dark;
    font-size: 16px;
    line-height: 26px;
    border:1px solid rgba($blue-dark, 0.5);
    @include placeholder{
        color: rgba($blue-dark, 0.5);
    }
}

input, textarea, select{
    -webkit-appearance: none;
    border-radius: 0;

    &:focus{
        outline: none;
    }
}
input{
    @extend .styled-input;
}
textarea{
    @extend .styled-input;
}

select{
    @extend .styled-select;
}
.gform_wrapper.gform_wrapper.gravity-theme{
    .gform_validation_errors{
        margin: 20px 0;
        border-radius: 0;
        border:0;
        box-shadow: none;
        background-color: rgba($red, 0.2);
        padding: 6px 15px;
        .gform-icon.gform-icon--close{
            display: none;
        }
        h2{
            color: $red;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .ginput_recaptcha{
        display: flex;
        justify-content: flex-end;
    }
    .gform_footer{
        text-align: right;
    }
    select{
        @extend .styled-input;
    }
    textarea{
        @extend .styled-input;
    }
    input[type="text"],input[type="email"],input[type="tel"],input[type="number"]{
        @extend .styled-input;
    }
    input[type="checkbox"]{
        width: 26px;
        height: 26px;
        margin-right: 20px;
    }
    input[type="submit"]{
        background-color: $red;
        border:1px solid $red;
        color: $white;
        padding: 16px 56px 16px 20px;
        background-image: url("../img/icon/arrow-white.svg");
        background-size: 15px;
        background-position: calc(100% - 14px) center;
        background-repeat: no-repeat;
        cursor: pointer;
        transition: all 250ms;
        &:hover{
            background-image: url("../img/icon/arrow-red.svg");
            background-color: $white;
            color:$red;
        }
    }
    .gchoice{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .ginput_container_consent, .gchoice{
        position: relative;
        display: flex;
        align-items: flex-start;
        input{
            flex-shrink: 0;
            position: relative;
            top:0px;
            cursor: pointer;
        }
        label{
            cursor: pointer;
            position: relative;
            color: $blue-dark;
            font-size: 16px;
            line-height: 26px;
            &:before{
                content:"✓";
                position: absolute;
                left: -40px;
                top: 0px;
                color: $blue;
                display: none;
            }
        }
        input:checked + label{
            &:before{
                display: block;
            }
        }
    }
    .gfield_label{
        font-weight: 500;
        color:$blue-dark;
        text-transform: uppercase;
    }
    .gfield_required_text{
         display: none;
     }
    .gfield{
        .gfield_validation_message{
            display: none;
        }
        .gfield_consent_label{
            a{
                text-decoration: underline;
            }
        }
        &.gfield_error{
            .gfield_label, .gfield_consent_label{
                color: $red;
            }
            input, textarea{
                color:$red;
                border-color: $red;
            }
        }
        &.has-val{
            .gfield_label, .gfield_consent_label{
                color: $blue;
            }
            input, textarea{
                color:$blue;
                border-color: $blue;
            }
        }
    }
}


.validation_error{
    margin-bottom: 30px;
    color: #BB0B0B!important;
}

#gform_ajax_spinner_1 {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}