.bloc-references{
  margin: 120px 0;
  @include media-breakpoint-down(lg){
    margin: 60px 0;
  }
  .row-top{
    margin-bottom: 64px;
    @include media-breakpoint-down(lg){
      margin-bottom: 34px;
    }
  }
  &__title{
    color:$blue;
    margin-bottom: 0;
  }
  .col-item{
    margin-bottom: $grid-gutter-width;
  }
  .col-title{
    @include media-breakpoint-down(lg){
      margin-bottom: $grid-gutter-width;
    }
  }
}