.breadcrumb{
  margin-top: 20px;
  margin-bottom: 40px;
  @include media-breakpoint-down(md){
    font-size: 12px;
  }
  a,span{
    color: $blue;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }
  a{
    text-decoration: underline;
  }
  .separator{
    width: 8px;
    height: 14px;
    background-image: url("../img/icon/chevron-blue.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 14px;
    @include media-breakpoint-down(md){
      margin: 0 5px;
      width: 6px;
      height: 10px;
    }
  }
  &.is-white{
    a,span{
      color: $white;
    }
    .separator{
      background-image: url("../img/icon/chevron-white.svg");
    }
  }
}