.page-cms{
  &__title{
    color: $blue;
    @extend .title-1;
    text-transform: uppercase;
    margin-bottom: 48px;
  }
  &__intro{
    margin-bottom: 40px;
  }
}