/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu{
}

#header{

        
}
.header{
  border-bottom: 1px solid $light-grey;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 99;
  .container{
    @include media-breakpoint-down(md){
      max-width: 100%;
    }
  }
  &__wrap{
    background-color: $white;
    position: relative;
    z-index: 1;
  }
  &__search{
    position: absolute;
    top:100%;
    left:0;
    width: 100%;
    z-index: 0;
    background-color: $white;
    border-bottom: 1px solid $light-grey;
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    transition: all 500ms $easeSmooth;
    &.is-open{
      opacity: 1;
      transform: translateY(0%);
      pointer-events: all;
    }
    .searchform{
      max-width: 970px;
      margin: 0 auto;
      position: relative;
      padding: 20px 0;
      input[type="text"]{
        font-size: 20px;
        line-height: 55px;
        color:$blue;
        font-weight: 300;
        appearance:none;
        border:0;
        border-bottom: 1px solid $blue;
        width: 100%;
        @include placeholder{
          font-style: italic;
          font-weight: 300;
          font-size: 20px;
          line-height: 55px;
          color:$blue;
        }
      }
      .arrow-button{
        position: absolute;
        right:0;
        top:50%;
        transform: translateY(-50%);
        transition: all 250ms;
        &:hover{
          background-color: $red;
          color:$white;
        }
      }
    }
  }
  &__inner{
    height: $headerHeight;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md){
      height: $headerHeightSm;
    }
  }
  &__logo{
    width: 220px;
    margin-right: 60px;
    img{
      width: 100%;
      height: auto;
    }
    @include media-breakpoint-down(lg){
      width: 120px;
      margin-right: 30px;
    }
  }
  &__nav{
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    &__item{
      position: relative;
      margin-right: 40px;
      color:$blue;
      @include media-breakpoint-down(lg){
        margin-right: 20px;
      }
      @include media-breakpoint-down(md){
        display: none;
      }
      &:last-child{
        margin-right: 0;
      }
      &:after{
        position: absolute;
        content:"";
        width: 100%;
        height: 50px;
        bottom:-45px;
        left: 0;
      }
      &__sub{
        position: absolute;
        top: 53px;
        left: 50%;
        background-color: $white;
        padding: 25px;
        white-space: nowrap;
        transform: translateX(-50%);
        min-width: 257px;
        display: none;
        &__item{
          margin-bottom: 15px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      &:hover &{
        &__sub{
          display: block;
        }
      }
      &:hover{
        svg{
          transform: rotate(-180deg);
        }
      }
      &.current-menu-item{
        a,span{
          &:after{
            opacity: 1;
          }
        }
      }
      a,span{
        font-size: 16px;
        color: $blue;
        line-height: 23px;
        position: relative;
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        svg{
          margin-left: 10px;
          transition: all 500ms $easeSmooth;
        }
        &:after{
          content:"";
          width: calc(100% + 10px);
          height: 1px;
          position: absolute;
          left:50%;
          bottom: -5px;
          transform: translateX(-50%);
          background-color: $red;
          opacity: 0;
          transition: all 500ms $easeSmooth;
        }
        &:hover{
          &:after{
            opacity: 1;
          }
        }
      }
      &.hover-blue{
        a,span{
          &:after{
            background-color: $blue-light;
          }
        }
      }
    }
  }
  &__tools{
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    &__burger{
      display:none;
      position: relative;
      width: 24px;
      height: 24px;
      svg{
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .close{
        display: none;
      }
      &.is-open{
        .close{
          display: block;
        }
        .open{
          display: none;
        }
      }
      @include media-breakpoint-down(md){
        display: block;
      }
    }
    &__social{
      @include media-breakpoint-down(md){
        display: none;
      }
    }
    a,span{
      color:$blue;
      transition: all 500ms $easeSmooth;
      margin-right: 40px;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &:hover{
        color: $red;
      }
    }
  }
}

/*
|
| Menu
|-------
*/
.mobile-menu{
  position: fixed;
  top:$headerHeightSm;
  width: 100%;
  height: calc(100vh - #{$headerHeightSm});
  overflow-y: auto;
  background-color: $white;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-15px);
  transition: all 500ms $easeSmooth;
  &.is-shown{
    @include media-breakpoint-down(md){
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateX(0px);
    }
  }
  @include media-breakpoint-down(md){
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__menu{
    padding: 0 $grid-gutter-width / 2;
    margin: auto;
    @include media-breakpoint-down(sm){
      margin: 0;
      margin-top: 50px;
    }
    &__item{
      margin-bottom: 30px;
      text-align: center;
      font-size: 24px;
      line-height: 24px;
      svg{
        transition: all 500ms $easeSmooth;
      }
      @include media-breakpoint-down(sm){
        margin-bottom: 25px;
        text-align: left;
      }
      &:last-child{
        margin-bottom: 0;
      }
      &.is-open{
        svg{
          transform: rotate(-180deg);
        }
      }
      &.is-open &{
        &__wrap{
          max-height: 200px;
        }
      }
      &__wrap{
        max-height: 0;
        overflow: hidden;
        transition: all 500ms $easeSmooth;
        &__sub{
          margin-top: 15px;
          &__item{
            margin-bottom: 15px;
          }
        }
      }
    }
    a,span{
      cursor: pointer;
      color:$blue;

    }
  }
  &__social{
    align-self: center;
    margin-bottom: 40px;
    margin-top: auto;
    color: $blue;
    transition: all 500ms $easeSmooth;
    svg{
      width: 36px;
      height: auto;
    }
    @include media-breakpoint-down(sm){
      svg{
        width: 19px;
      }
    }
    &:hover{
      color:$red;
    }
  }
}